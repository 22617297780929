function generateSeededRandomNumber(seed: number): number {
  const randomValue = Math.sin(seed++) * 10000;
  return randomValue - Math.floor(randomValue);
}

export function getRandomElementsFromArray<T>({
  array,
  count,
  seed,
}: {
  array: T[];
  count: number;
  seed: number;
}): T[] {
  const result: T[] = [];
  const arrayCopy = [...array];
  while (result.length < count && arrayCopy.length > 0) {
    const index = Math.floor(
      generateSeededRandomNumber(seed) * arrayCopy.length
    );
    result.push(arrayCopy.splice(index, 1)[0]);
    seed++;
  }
  return result;
}
