import React, { ReactNode } from "react";

import { Box } from "../generic/Box";
import { Text } from "../generic/Text";
import { COLORS } from "../../constants/colors";

interface ISettingsItemProps {
  label: string;
  icon: ReactNode;
}

export const SettingsHeader = ({ label, icon }: ISettingsItemProps) => {
  return (
    <Box
      px={15}
      py={15}
      pt={25}
      alignItems="center"
      flexDirection="row"
      w="100%"
    >
      {icon}
      <Text size={14} ml={15} fontWeight="medium" color={COLORS.text[800]}>
        {label}
      </Text>
    </Box>
  );
};
