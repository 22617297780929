export const COLORS = {
  // gradientPrimary: "#286b98ee",
  // gradientSecondary: "#1cbaa888",

  // primary: "#98e657",
  // primary: "#7f00ff",
  // primary: "#191970",
  // primary: "#800000",
  // primary: "#006400",
  // primary: "#8B8000",
  // primary: "#708090",
  // primary: "#36454F",
  // primary: "#800020",
  // primary: "#4B0082",
  // primary: "#6E4C1E",
  // primary: "#000080",

  // primary: "#4D75A2",
  // primary: "#B33734",
  // primary: "#2E8B57",
  // primary: "#9C7B40",
  // primary: "#597D96",
  // primary: "#465B68",
  // primary: "#9B304C",

  // primary: "#FF4500", // OrangeRed
  // primary: "#7FFF00", // Chartreuse
  // primary: "#00FFFF", // Aqua
  // primary: "#FF69B4", // HotPink
  // primary: "#ADFF2F", // GreenYellow
  // primary: "#20B2AA", // LightSeaGreen
  // primary: "#00BFFF", // DeepSkyBlue
  // primary: "#FA8072", // Salmon

  // gradientPrimary: "#001e36",
  // gradientSecondary: "#359c60",
  // gradientPrimary: "#fe4890",
  // gradientSecondary: "#ac0072",
  // background: "#12171a",
  // card: "#00b8d1",
  // primary: "#fe4890",
  // navigation: "#000000",
  // navigation: "#03181f",

  //   gradientSecondary: "#f00f00",

  // gradientPrimary: "#1D3A75",
  // gradientSecondary: "#C1A6D4",
  // primary: "#3770ff",

  // gradientPrimary: "#502c58",
  // gradientSecondary: "#ff4365",

  // gradientPrimary: "#231f4c",
  // gradientSecondary: "#491354",
  // primary: "#00cee1",
  // background: "#8c7ae6",
  // background: "##2c2c54",
  navigation: "#000202",

  // background: "#16163b",
  // navigation: "#16163b",
  // primary: "#ffffff",
  main: {
    // 400: "#221c54",

    400: "#3234337a",
    500: "#3234337a",

    600: "#002329",

    // 500: "#221c54",
  },
  // gradientPrimary: "#03181f",
  // gradientSecondary: "#00101C",
  // background: "rgb(0,16,26)",
  // background: "#0a0e15",
  // card: "#02242E",

  // gradientPrimary: "#1D3A75",

  // gradientPrimary: "#3c0066",
  primary: "#ff507f",
  primaryDark: "#f10041",
  primary2: "#00a2e3",
  primary2Dark: "#0075a3",
  primary3: "#FFD536",
  primary4: "#00ca85",
  primary5: "#7f00ff",

  drawing: [
    "#ff507f",
    "#0077cc",
    "#ffe600",
    "#00c75a",
    "#aa00ff",
    "#00ffee",
    "#f27013",
  ],
  //
  debugBox: "rgba(255,0,0,.5)",
  white: "rgba(255,255,255,1)",
  background: "#111010",

  text: {
    500: "rgba(255,255,255,.5)",
    600: "rgba(255,255,255,.6)",
    700: "rgba(255,255,255,.7)",
    800: "rgba(255,255,255,.8)",
    900: "rgba(255,255,255,.9)",
  },
  // card: "#3c0066",
  // card: "#29004d",
  // primary:
};
