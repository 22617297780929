import { useFonts } from "expo-font";
import * as ScreenOrientation from "expo-screen-orientation";
import React from "react";

import { Providers } from "./Providers";
import { AppNavigator } from "./navigators/AppNavigator";
import { Platform } from "react-native";
import { GradientBackground } from "./components/GradientBackground";
import { HomeScreen } from "./screens/HomeScreen";
import * as Notifications from "expo-notifications";

if (Platform.OS !== "web") {
  ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
}

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: false,
  }),
});

export default function App() {
  return (
    <>
      <Providers>
        <AppNavigator />
      </Providers>
    </>
  );
}
