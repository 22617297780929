import { registerRootComponent } from "expo";

import { LoadSkiaWeb } from "@shopify/react-native-skia/lib/module/web";
LoadSkiaWeb({
  locateFile: (file) => {
    return `/static/js/${file}`;
  },
}).then(async () => {
  const App = require("./src/App");

  registerRootComponent(App.default);
});
