import { QueryClient } from "react-query";

import { storage } from "./storage";

export async function writeQuery(queryKey: string[] | string, data: any) {
  const storageData = await storage.get("queries");
  const cacheData = await storage.get("cacheTimeout");

  const newStorageData = {
    ...storageData,
    [JSON.stringify(queryKey)]: data,
  };

  await storage.set("queries", newStorageData);

  const newCacheData = {
    ...cacheData,
    [JSON.stringify(queryKey)]: new Date().toString(),
  };

  await storage.set("cacheTimeout", newCacheData);
}

export async function clearQuery(queryKey: string[] | string) {
  const storageData = await storage.get("queries");
  const cacheData = await storage.get("cacheTimeout");

  const newStorageData = {
    ...storageData,
    [JSON.stringify(queryKey)]: undefined,
  };

  await storage.set("queries", newStorageData);

  const newCacheData = {
    ...cacheData,
    [JSON.stringify(queryKey)]: undefined,
  };

  await storage.set("cacheTimeout", newCacheData);
}

export async function clearStorage() {
  await storage.remove("queries");
  await storage.remove("cacheTimeout");
}

export async function readQueries(queryClient: QueryClient) {
  const storageData = await storage.get("queries");
  if (storageData !== null) {
    for (const queryKey in storageData) {
      const data = storageData[queryKey];
      let key = queryKey;
      try {
        key = JSON.parse(key);
      } catch (e) {
        console.error(e);
      }

      await queryClient.setQueryData(key, data);
      await queryClient.invalidateQueries([key]);
    }
  }
}

export async function readQuery(key: string) {
  const storageData = await storage.get("queries");
  if (storageData !== null) {
    if (storageData[JSON.stringify(key)])
      return storageData[JSON.stringify(key)];
  }
}
