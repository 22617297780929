import AsyncStorage from "@react-native-async-storage/async-storage";

function convertKey(key: string) {
  return `manolis-${key}`;
}

export const storage = {
  get: async (
    key: string,
    defaultValue: null | { [data: string]: string } = null
  ): Promise<null | any> => {
    let value: { [data: string]: string } | null = defaultValue;
    try {
      const storedValue = await AsyncStorage.getItem(
        JSON.stringify(convertKey(key))
      );

      if (!storedValue) return {};
      return JSON.parse(storedValue ?? "");
    } catch (e) {
      console.error(e);
      value = defaultValue;
    }

    return value ?? defaultValue;
  },

  async set(key: string, value: any) {
    return await AsyncStorage.setItem(
      JSON.stringify(convertKey(key)),
      JSON.stringify(value)
    );
  },

  async remove(key: string) {
    return await AsyncStorage.removeItem(convertKey(key));
  },

  async clear() {
    return await AsyncStorage.clear();
  },

  async getStoredQuery(key: string): Promise<any> {
    const queriesJson = await AsyncStorage.getItem(convertKey("queries"));
    const queries = JSON.parse(queriesJson ?? "");
    return queries[key];
  },
};
