import { LayoutChangeEvent, Platform } from "react-native";
import { Box } from "../generic/Box";
import { COLORS } from "../../constants/colors";
import { useCallback, useEffect, useState } from "react";
import { Letter } from "./Letter";
import { Feather } from "@expo/vector-icons";
import { GamePrompt } from "./GamePrompt";
import { shuffleWord } from "../../util/word";
import { WORD_LIST } from "../../constants/word-list";
import { GameButton } from "./GameButton";
import { useKeyboardEvents } from "../../hooks/useKeyboardEvents";

import { sample } from "lodash";

interface IGameProps {
  onGamePass?: () => void;
  word: string;
}

const AFFIRMATIONS = [
  "Good job!",
  "Well done!",
  "Great work!",
  "Nice effort!",
  "Awesome!",
  "Genius!",
  "Fantastic!",
  "Excellent!",
  "Bravo!",
  "Nailed it!",
  "Spot on!",
  "Impressive!",
  "Way to go!",
  "That's correct!",
  "Keep it up!",
];

export const Game = ({ onGamePass, word }: IGameProps) => {
  const [prevWord, setPrevWord] = useState<string>("");
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [prompt, setPromptState] = useState<string>("");
  const [promptSuccess, setPromptSuccess] = useState<boolean>(false);

  const [activeLetters, setActiveLetters] = useState<number[]>(
    Array.from(Array(word.length))
  );

  const setPrompt = useCallback((prompt: string) => {
    setPromptState(prompt);
    setPromptSuccess(false);
  }, []);

  useEffect(() => {
    if (prevWord) {
      setPromptState(sample(AFFIRMATIONS));
      setPromptSuccess(true);
    }

    if (prevWord !== word) {
      setPrevWord(word);
    }
    // don't add prev wor dhere!
  }, [word]);

  useEffect(() => {
    setActiveLetters(Array.from(Array(word.length)));

    setIsFinished(false);
    setAvailableLetters(
      shuffleWord(word)
        .split("")
        .map((letter, index) => ({
          letter,
          originalIndex: index,
        }))
    );
  }, [word]);

  const [availableLetters, setAvailableLetters] = useState<
    {
      letter: string;
      originalIndex: number;
    }[]
  >();

  const clearPrompt = useCallback(() => {
    if (prompt) {
      setPrompt("");
    }
  }, [prompt, setPrompt]);

  const checkIfFinished = useCallback(
    (letters: number[]) => {
      const everyLetterHasValue = letters.every((a) => a !== undefined);
      if (!everyLetterHasValue) return;

      const word = activeLetters
        .map((a) => availableLetters.find((b) => b.originalIndex === a)?.letter)
        .join("");

      const isWordInList = WORD_LIST.has(word);

      if (!isWordInList) {
        setTimeout(() => {
          setPrompt("Not on word list :(");
        }, 300);

        return;
      }

      setIsFinished(true);
    },
    [activeLetters, availableLetters, setPrompt]
  );

  const onLetterPress = useCallback(
    (index: number) => {
      let foundIndex: number | undefined;
      clearPrompt();

      for (const letterIndex in activeLetters) {
        const letter = activeLetters[letterIndex];
        if (letter === undefined) {
          foundIndex = +letterIndex;
          break;
        }
      }

      activeLetters[foundIndex] = index;
      setActiveLetters([...activeLetters]);

      checkIfFinished(activeLetters);
    },
    [activeLetters, checkIfFinished, setActiveLetters, clearPrompt]
  );

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isFinished) {
      timeout = setTimeout(() => {
        onGamePass();
      }, 300);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isFinished, onGamePass]);

  const onRemoveLetter = useCallback(
    (index: number) => {
      const newLetters = activeLetters.map((letter) => {
        if (letter === index) {
          return undefined;
        }

        return letter;
      });

      setActiveLetters([...newLetters]);
      clearPrompt();
    },
    [activeLetters, clearPrompt, setActiveLetters]
  );

  const onClear = useCallback(() => {
    setActiveLetters(Array.from(Array(word.length)));
  }, [word]);

  const onDelete = useCallback(() => {
    const newLetters = [...activeLetters];

    for (let i = newLetters.length - 1; i >= 0; i--) {
      if (newLetters[i] !== undefined) {
        newLetters[i] = undefined;
        break;
      }
    }

    setActiveLetters(newLetters);
    clearPrompt();
  }, [activeLetters, clearPrompt]);

  const onShuffle = useCallback(() => {
    const shuffledLetters = [...availableLetters]
      .map((letter) => ({ letter, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ letter }) => letter);

    setAvailableLetters(shuffledLetters);
  }, [availableLetters]);

  const [letterWidth, setLetterWidth] = useState<number>(0);

  const [containerWidth, setContainerWidth] = useState<number>(0);

  const onLayout = useCallback((e: LayoutChangeEvent) => {
    // setLetterWidth(e.nativeEvent.layout.width / word.length);
    setContainerWidth(e.nativeEvent.layout.width);
  }, []);

  useEffect(() => {
    if (containerWidth === 0) return;

    setLetterWidth(containerWidth / word.length);
  }, [containerWidth, word]);

  const onKeyboardEvent = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Backspace") {
        onDelete();
        return;
      }

      const letters = availableLetters.filter(
        (letter) => !activeLetters.includes(letter.originalIndex)
      );

      const index = letters.findIndex(
        (letter) =>
          `${letter.letter}`.toLowerCase() === `${event.key}`.toLowerCase()
      );

      if (index === -1) {
        return;
      }

      onLetterPress(letters[index].originalIndex);
    },
    [onDelete, availableLetters, activeLetters, onLetterPress]
  );

  useKeyboardEvents(onKeyboardEvent);

  const isWeb = Platform.OS === "web";
  const LETTER_HEIGHT = !isWeb ? 70 : 80;
  return (
    <>
      <Box alignItems="center" flex={isWeb ? undefined : 1} w="100%">
        <Box
          justifyContent="center"
          alignItems="center"
          w="100%"
          h={LETTER_HEIGHT}
        >
          <Box
            br={8}
            px="2%"
            gap={5}
            w="100%"
            h="100%"
            flexDirection="row"
            bg={`${COLORS.primary2}11`}
          >
            <Box w="100%" onLayout={onLayout} zIndex={9999}>
              {letterWidth > 0 ? (
                <>
                  {availableLetters.map((letterObj, index) => {
                    const letter = letterObj.letter;
                    const isAdded = activeLetters.includes(
                      letterObj.originalIndex
                    );

                    const currentIndex = activeLetters.findIndex(
                      (a) => a === letterObj.originalIndex
                    );

                    return (
                      <Letter
                        width={letterWidth}
                        x={(!isAdded ? index : currentIndex) * letterWidth}
                        letter={letter}
                        key={`${letterObj.originalIndex}`}
                        isInitial={index <= 3}
                        isAdded={isAdded}
                        onPress={() => {
                          if (isAdded) {
                            onRemoveLetter(letterObj.originalIndex);
                            return;
                          }

                          return onLetterPress(letterObj.originalIndex);
                        }}
                      />
                    );
                  })}
                </>
              ) : null}
              <Box
                justifyContent="center"
                alignItems="center"
                w="100%"
                position="absolute"
                h="100%"
                pointerEvents="none"
              >
                <GamePrompt
                  prompt={prompt}
                  onFinish={clearPrompt}
                  color={
                    !promptSuccess ? COLORS.primaryDark : COLORS.primary2Dark
                  }
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box w="100%" mt={LETTER_HEIGHT * 1.3}>
          <Box
            justifyContent="center"
            alignItems="center"
            gap={10}
            w="100%"
            flexDirection="row"
          >
            <GameButton
              label="Clear"
              color={COLORS.primary}
              icon={
                <Box pr={5}>
                  <Feather name="trash" size={16} color={`${COLORS.primary}`} />
                </Box>
              }
              onPress={onClear}
            />
            <GameButton
              color={COLORS.primary2}
              label="Delete"
              icon={
                <Box pr={5}>
                  <Feather
                    name="chevron-left"
                    size={16}
                    color={`${COLORS.primary2}`}
                  />
                </Box>
              }
              onPress={onDelete}
            />
            <GameButton
              label="Shuffle"
              color={COLORS.primary3}
              icon={
                <Box pr={5}>
                  <Feather
                    name="shuffle"
                    size={16}
                    color={`${COLORS.primary3}`}
                  />
                </Box>
              }
              onPress={onShuffle}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
