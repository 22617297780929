import React, { ReactNode, useMemo } from "react";

import { Box } from "./Box";
import { Loading } from "./Loading";
import { Text } from "./Text";
import { IPressableProps, Pressable } from "./Pressable";
import { COLORS } from "../../constants/colors";

type ButtonStyle = "solid" | "outline" | "whiteOutline";

export interface IButtonProps extends Omit<IPressableProps, "style"> {
  onPress?: () => void;
  style?: ButtonStyle;
  isLoading?: boolean;
  isDisabled?: boolean;
  isLoadingInverted?: boolean;
  textSize?: number;
  label: ReactNode;
  type?: string;
  textColor?: string;
  icon?: ReactNode;
}

const STYLE_MAP: Record<
  ButtonStyle,
  {
    textColor: string;
    borderColor?: string;
    type?: string;
    backgroundColor: string;
    borderWidth: number;
  }
> = {
  solid: {
    textColor: COLORS.text[900],
    borderWidth: 3,
    borderColor: "transparent",
    backgroundColor: COLORS.primary,
  },
  outline: {
    textColor: COLORS.primary2,
    borderColor: COLORS.primary2,
    backgroundColor: "transparent",
    borderWidth: 3,
  },

  whiteOutline: {
    textColor: COLORS.text[800],
    borderColor: COLORS.text[800],
    backgroundColor: "transparent`",
    borderWidth: 3,
  },
};

export const Button = ({
  label,
  icon,
  isDisabled,
  isLoadingInverted,
  textSize = 14,
  type,
  textColor,
  isLoading,
  style = "outline",
  onPress,
  ...props
}: IButtonProps) => {
  const styles = useMemo(() => {
    return STYLE_MAP[style];
  }, [style]);

  return (
    <Pressable
      py={!icon ? 10 : 12}
      px={!icon ? 30 : 10}
      br={12}
      borderWidth={styles.borderWidth}
      onPress={onPress}
      hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
      isDisabled={isLoading || isDisabled}
      enableAnimation={true}
      bg={styles.backgroundColor}
      alignItems="center"
      borderColor={styles.borderColor}
      justifyContent="space-between"
      {...props}
    >
      <Box flexDirection="row" opacity={isLoading ? 0 : 1} alignItems="center">
        <Box pl={icon ? 10 : 0} pr={icon ? 10 : 0}>
          <Text
            color={textColor ? textColor : styles.textColor}
            fontWeight="medium"
            size={textSize}
            mb={-1}
          >
            {label}
          </Text>
        </Box>
        {icon}
      </Box>

      {isLoading ? (
        <Box
          alignItems="center"
          justifyContent="center"
          w="100%"
          top={0}
          bottom={0}
          position="absolute"
        >
          <Loading
            h={50}
            w={100}
            type={type}
            opacity={1}
            isInverted={isLoadingInverted}
          />
        </Box>
      ) : null}
    </Pressable>
  );
};
