import React from "react";
import { Alert, Share } from "react-native";
import { Box } from "../components/generic/Box";
import { Image } from "../components/generic/Image";
import { Text } from "../components/generic/Text";
import { COLORS } from "../constants/colors";
import { FadeInUp, FadeOutDown } from "react-native-reanimated";
import { TimeCounter } from "../components/generic/TimeCounter";
import { useCurrentGame } from "../hooks/useCurrentGame";
import { GAME_SETTINGS } from "../constants/settings";
import { isEmpty } from "lodash";
import { GameButton } from "../components/game/GameButton";

import { useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { Container } from "../components/Container";
import { isWeb } from "../util/platform";
import { ShareBanner } from "../components/ShareBanner";
import { DownloadIosButton } from "../components/DownloadIosButton";

import { Pressable } from "../components/generic/Pressable";
import { StreakBlob } from "../components/StreakBlob";

export const EndScreen = ({
  onLongPress,
  onContinue,
}: {
  onLongPress: () => void;
  onContinue: () => void;
}) => {
  const { data: currentGame } = useCurrentGame();
  const isSuccess =
    currentGame?.correctWords?.length >= GAME_SETTINGS.AMOUNT_OF_WORDS;

  const [showContinueButton, setShowContinueButton] = useState<boolean>(false);

  useEffect(() => {
    if (!currentGame) return;

    if (
      currentGame.date !== dayjs().format("YYYY-MM-DD") ||
      isEmpty(currentGame?.correctWords)
    ) {
      setShowContinueButton(true);
      return;
    } else {
      setShowContinueButton(false);
    }

    const secondsToMidnight = dayjs().endOf("day").diff(dayjs(), "seconds");
    const timeout = setTimeout(() => {
      setShowContinueButton(true);
    }, secondsToMidnight * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [currentGame]);

  const onShare = useCallback(() => {
    const message = `I survived ${currentGame?.correctWords.length}/${
      GAME_SETTINGS.AMOUNT_OF_WORDS
    } words on 21 words! Think you can beat me? https://21words.app/`;

    if (isWeb) {
      navigator.clipboard.writeText(message).then(
        function () {
          alert("Copied to clipboard!");
        },
        function () {
          alert("Could not copy to clipboard!");
        }
      );
      return;
    }

    Share.share({
      message: `I survived ${currentGame?.correctWords.length}/${
        GAME_SETTINGS.AMOUNT_OF_WORDS
      } words on 21 words! Think you can beat me? https://apps.apple.com/gb/app/21-words/id6504304763`,
    });
  }, [currentGame]);

  const nextGame = useMemo(() => {
    return dayjs(currentGame?.date).add(1, "day").format("YYYY-MM-DD");
  }, []);

  return (
    <>
      <Box flex={1} justifyContent="center" alignItems="center" w="100%">
        <Box
          flex={isWeb ? undefined : 1}
          w="100%"
          justifyContent="center"
          alignItems="center"
        >
          {isWeb ? (
            <Box justifyContent="center" alignItems="center" mb={80}>
              <Image
                showSpinner={false}
                isLoadingOverlay={false}
                w={200}
                h={80}
                resizeMode="contain"
                source={require("../../assets/21-logo.png")}
              />
            </Box>
          ) : null}
          <Box>
            <Container>
              <Box
                h={"100%"}
                py={isWeb ? 30 : 20}
                w={"100%"}
                justifyContent="space-between"
                alignItems="center"
              >
                <Box p={4} center={true} py={0} pb={0}></Box>
                {!isWeb ? (
                  <Box justifyContent="center" alignItems="center">
                    <Image
                      showSpinner={false}
                      isLoadingOverlay={false}
                      w={250}
                      h={100}
                      resizeMode="contain"
                      source={require("../../assets/21-logo.png")}
                    />
                  </Box>
                ) : null}
                <Box
                  px={60}
                  justifyContent="center"
                  alignItems="center"
                  w="100%"
                >
                  <Text
                    fontWeight="extrabold"
                    color={COLORS.primary}
                    align="center"
                    mb={5}
                    size={34}
                  >
                    {isSuccess
                      ? "Well done!"
                      : currentGame?.failingWord?.toUpperCase() ?? "Game over!"}
                  </Text>
                  <Text color="rgba(255,255,255,.8)" align="center" size={18}>
                    You survived {currentGame?.correctWords?.length ?? "0"}/
                    {GAME_SETTINGS.AMOUNT_OF_WORDS} words.{" "}
                  </Text>
                  <Box
                    justifyContent="center"
                    mt={5}
                    // entering={FadeInDown.delay(400).duration(500)}
                    alignItems="center"
                    flexDirection="row"
                    opacity={showContinueButton ? 0 : 1}
                    gap={4}
                  >
                    <Text size={14} color={"rgba(255,255,255,.6)"}>
                      New game in
                    </Text>
                    <Text
                      size={14}
                      fontWeight="bold"
                      color={"rgba(255,255,255,.6)"}
                    >
                      <TimeCounter time={nextGame} />
                    </Text>
                  </Box>
                </Box>
                <Box
                  w="100%"
                  px={40}
                  mt={isWeb ? 40 : 0}
                  exiting={FadeOutDown.duration(500)}
                >
                  <ShareBanner onShare={onShare} />

                  <Box
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    mt={isWeb ? 20 : 10}
                    pb={isWeb ? 10 : 0}
                    // h={60}
                    // opacity={showContinueButton ? 1 : 0.8}
                  >
                    <GameButton
                      // isDisabled={!showContinueButton}
                      label={
                        <>
                          {showContinueButton
                            ? "Continue"
                            : "Want to try again?"}
                        </>
                      }
                      onPress={onContinue}
                      br={100}
                      flex={isWeb ? 1 : 0}
                      px={20}
                      color={COLORS.primary3}
                    />
                  </Box>
                </Box>
                <Box p={4} center={true} py={0} pb={0}></Box>
              </Box>
            </Container>
          </Box>
          <DownloadIosButton />
        </Box>
      </Box>
      <StreakBlob />
    </>
  );
};
