import React, { useEffect } from "react";
import { StyleSheet } from "react-native";
import Animated, {
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withTiming,
} from "react-native-reanimated";
import { Pressable } from "./Pressable";
import { COLORS } from "../../constants/colors";

interface ISwitchProps {
  onPress: (value: boolean) => void;
  activeTrackColor?: string;
  inActiveTrackColor?: string;
  isDisabled?: boolean;
  thumbColor?: string;
  value: boolean;
}

export const Switch = ({
  onPress,
  isDisabled,
  activeTrackColor = COLORS.primary2,
  inActiveTrackColor = COLORS.main[500],
  thumbColor = "rgb(225,225,225)",
  value,
}: ISwitchProps) => {
  const switchTranslate = useSharedValue(0);

  useEffect(() => {
    switchTranslate.value = value ? 22 : 0;
  }, [value]);

  const interpolateBackgroundColor = useAnimatedStyle(() => ({
    backgroundColor: withTiming(
      interpolateColor(
        switchTranslate.value,
        [0, 22],
        [inActiveTrackColor, activeTrackColor]
      ),
      { duration: 200 }
    ),
  }));

  const circleStyle = useAnimatedStyle(() => ({
    transform: [
      {
        translateX: withSpring(switchTranslate.value, {
          mass: 1,
          damping: 15,
          stiffness: 120,
          overshootClamping: true,
          restSpeedThreshold: 0.001,
          restDisplacementThreshold: 0.001,
        }),
      },
    ],
  }));

  return (
    <Pressable
      onPress={onPress}
      isDisabled={isDisabled}
      hitSlop={{ top: 10, left: 15, right: 15, bottom: 10 }}
    >
      <Animated.View
        style={[styles.containerStyle, interpolateBackgroundColor]}
      >
        <Animated.View
          style={[
            styles.circleStyle,
            { backgroundColor: thumbColor },
            circleStyle,
            styles.shadowValue,
          ]}
        />
      </Animated.View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  circleStyle: {
    width: 24,
    height: 24,
    borderRadius: 24,
  },
  containerStyle: {
    width: 50,
    paddingVertical: 2,
    paddingHorizontal: 2,
    borderRadius: 36.5,
  },
  shadowValue: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,

    elevation: 4,
  },
});
