import { memo, useEffect, useState } from "react";
import { FadeInDown, FadeOutUp } from "react-native-reanimated";
import { Box } from "../generic/Box";
import { COLORS } from "../../constants/colors";
import { GameButton } from "./GameButton";
import { Feather } from "@expo/vector-icons";
import { isWeb } from "../../util/platform";

interface IGamePromptProps {
  onFinish?: () => void;
  prompt: string;
  color?: string;
}

export const GamePrompt = memo(
  ({ onFinish, color = COLORS.primaryDark, prompt }: IGamePromptProps) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
      if (prompt) {
        setIsVisible(true);
        const timeout = setTimeout(() => {
          setIsVisible(false);
          onFinish?.();
        }, 1500);

        return () => {
          clearTimeout(timeout);
        };
      }

      if (!prompt && isVisible) setIsVisible(false);
    }, [prompt]);

    return (
      <Box
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        h={45}
        w="100%"
      >
        {isVisible && prompt ? (
          <Box
            flexDirection="row"
            // w="100%"

            justifyContent="center"
            alignItems="center"
            exiting={FadeOutUp.duration(300)}
            entering={FadeInDown.duration(300)}
          >
            <GameButton
              pointerEvents="none"
              flex={isWeb ? 1 : 0}
              label={prompt}
              textColor="white"
              bg={`${color}ff`}
              py={10}
              // icon={
              //   <Box pr={5}>
              //     <Feather name="meh" size={18} color={`${COLORS.white}`} />
              //   </Box>
              // }
              color={COLORS.primary}
            />
          </Box>
        ) : null}
      </Box>
    );
  }
);
