import React, { memo, useCallback, useMemo, useState } from "react";
import {
  FadeIn,
  FadeOut,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { getStyleProps } from "../../util/style";
import { IBoxProps, Box } from "./Box";
import { Skeleton } from "./Skeleton";
import { Loading } from "./Loading";
import { Image as RnImage } from "react-native";

interface IImageProps extends IBoxProps {
  uri?: string;
  source?: any;
  resizeMode?: "cover" | "contain";
  fadeDuration?: number;
  justFade?: boolean;
  isLoadingOverlay?: boolean;
  showSpinner?: boolean;
  onLoad?: () => void;
}

export const Image = memo(
  ({
    children,
    uri,
    fadeDuration = 800,
    source,
    justFade,
    isLoadingOverlay,
    onLoad,
    showSpinner = true,
    ...props
  }: IImageProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(!isLoadingOverlay);
    const opacity = useSharedValue(0);

    const style = useMemo(() => {
      return getStyleProps(props);
    }, [props]);

    const imageSource = useMemo(() => {
      return uri ? { uri } : source;
    }, [source, uri]);

    const onLoadEnd = useCallback(() => {
      if (onLoad) onLoad();
      setIsLoading(false);

      opacity.value = 1;
    }, [onLoad]);

    const imageStyle = useAnimatedStyle(
      () => ({
        opacity: withTiming(opacity.value, {
          duration: 600,
        }),
      }),
      [isLoading]
    );

    return (
      <>
        <Box>
          {!justFade && (isLoadingOverlay || (!showSpinner && isLoading)) ? (
            <>
              <Skeleton
                entering={FadeIn.duration(1000)}
                exiting={FadeOut.duration(1000)}
                style={style}
                {...props}
              />
            </>
          ) : null}
          {!isLoadingOverlay ? (
            <Box style={imageStyle} w="100%" h="100%" isAnimated={true}>
              <RnImage
                // @ts-ignore - Uses view styles
                style={{
                  ...style,
                  position: !showSpinner && isLoading ? "absolute" : undefined,
                }}
                resizeMode="cover"
                source={imageSource}
                onLoadEnd={onLoadEnd}
                {...props}
              />
            </Box>
          ) : null}

          {!justFade && isLoading && !isLoadingOverlay && showSpinner ? (
            <Box
              w="100%"
              h="100%"
              position="absolute"
              opacity={0.5}
              alignItems="center"
              justifyContent="center"
            >
              {/* @ts-ignore */}
              <Loading h={style.width ?? 30} />
            </Box>
          ) : null}
        </Box>
      </>
    );
  }
);
