import { Dimensions, useWindowDimensions } from "react-native";
import { isWeb } from "../util/platform";

const { width: screenWidth, height: screenHeight } = Dimensions.get("screen");

export const useScreenDimensions = () => {
  const { width, height } = useWindowDimensions();

  if (isWeb) {
    return { width, height };
  }

  return { width: screenWidth, height: screenHeight };
};
