import LottieView from "lottie-react-native";
import { StyleSheet } from "react-native";
import React, { useMemo } from "react";
import { FadeInUp, FadeOutUp } from "react-native-reanimated";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { Box } from "./generic/Box";
import { Text } from "./generic/Text";
import { COLORS } from "../constants/colors";
import { useGameStreak } from "../hooks/useGameStreak";
import dayjs from "dayjs";

export const StreakBlob = () => {
  const insets = useSafeAreaInsets();

  const { data: streak } = useGameStreak();

  const shouldShow = useMemo(() => {
    if (!streak?.streak?.streak) return false;

    // let's check the date
    const lastDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");
    if (dayjs(lastDate).isAfter(dayjs(streak?.streak?.lastDate))) return false;

    return true;
  }, [streak]);

  if (!shouldShow) return null;

  return (
    <Box
      w={100}
      h={100}
      position="absolute"
      center={true}
      top={insets?.top ?? 20}
      justifyContent="center"
      right={5}
      entering={FadeInUp.delay(300).duration(700)}
      exiting={FadeOutUp.delay(300).duration(700)}
    >
      <LottieView
        resizeMode="contain"
        autoPlay={true}
        style={styles.lottie}
        speed={1}
        loop={true}
        source={require("../../assets/animations/fire-1.json")}
      />
      <Box mb={-7}>
        <Text
          size={20}
          color={COLORS.primary3}
          fontWeight="bold"
          align="center"
        >
          {streak?.streak?.streak}
        </Text>
      </Box>
      <Text size={12} color={COLORS.primary3} opacity={1} align="center">
        streak
      </Text>
    </Box>
  );
};

const styles = StyleSheet.create({
  lottie: {
    width: 100,
    height: 100,
    left: "0%",
    top: 0,
    marginTop: "-10%",
    opacity: 0.4,
    position: "absolute",
  },
});
