import {
  Canvas,
  RoundedRect,
  vec,
  RadialGradient,
} from "@shopify/react-native-skia";
import { Dimensions, useWindowDimensions, View } from "react-native";
import { COLORS } from "../constants/colors";
import { memo } from "react";
import Animated, {
  Easing,
  useAnimatedStyle,
  withRepeat,
  withSequence,
  withTiming,
} from "react-native-reanimated";
import { Box } from "./generic/Box";
import { isWeb } from "../util/platform";

interface IGradientBackgroundProps {
  stage?: number;
}

export const GradientBackground = memo(() => {
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  // const { width, height } = { width: 1920, height: 1080 };
  const SIZE = windowWidth > windowHeight ? windowWidth * 2 : windowHeight * 2;
  const { width, height } =
    windowWidth < windowHeight
      ? { width: windowWidth, height: windowHeight }
      : { width: windowHeight, height: windowWidth };

  const animatedImage = useAnimatedStyle(() => {
    return {
      width: SIZE,
      height: SIZE,
      justifyContent: "center",
      alignItems: "center",
      transform: [
        {
          rotate: withRepeat(
            withSequence(
              withTiming("0deg", { duration: 0, easing: Easing.linear }),
              withTiming("360deg", {
                duration: isWeb ? width * 30 : 10000,
                easing: Easing.linear,
              })
            ),
            -1
          ),
        },
      ],
    };
  });

  return (
    <Box
      position={isWeb ? "fixed" : "absolute"}
      justifyContent="center"
      alignItems="center"
      w={windowWidth}
      h={windowHeight}
      // top={SIZE / 2}
    >
      <>
        <Animated.View style={animatedImage}>
          <Canvas
            style={{
              width: SIZE,
              height: SIZE,
              opacity: 0.8,
            }}
            pointerEvents="none"
          >
            <RoundedRect r={0} x={0} y={0} width={SIZE} height={SIZE}>
              <RadialGradient
                c={vec(height / 2, height * 1)}
                r={height}
                colors={[`${COLORS.primary}dd`, "transparent"]}
              />
            </RoundedRect>

            <RoundedRect r={0} x={0} y={0} width={SIZE} height={SIZE}>
              <RadialGradient
                c={vec(height * 1.5, height * 1.6)}
                r={height}
                colors={[`${COLORS.primary2}aa`, "transparent"]}
              />
            </RoundedRect>

            <RoundedRect r={0} x={0} y={0} width={SIZE} height={SIZE}>
              <RadialGradient
                c={vec(height, height * 1.7)}
                r={height}
                colors={[`${COLORS.primary2}33`, "transparent"]}
              />
            </RoundedRect>
          </Canvas>
        </Animated.View>

        <Canvas
          style={{
            width: SIZE,
            height: SIZE,
            opacity: 0.5,
            position: "absolute",
          }}
          pointerEvents="none"
        >
          <RoundedRect r={0} x={0} y={0} width={SIZE} height={SIZE}>
            <RadialGradient
              c={vec(height, height * 0.8)}
              r={700}
              colors={[`${COLORS.primary2}33`, "transparent"]}
            />
          </RoundedRect>
        </Canvas>
      </>
    </Box>
  );
});
