import React, { ReactNode } from "react";

import { Box } from "../generic/Box";
import { Text } from "../generic/Text";
import { IPressableProps, Pressable } from "../generic/Pressable";
import { COLORS } from "../../constants/colors";

interface ISettingsItemProps extends IPressableProps {
  label: string;
  icon: ReactNode;
  enableAnimation?: boolean;
  onPress?: () => void;
  children?: ReactNode;
}

export const SettingsItem = ({
  children,
  label,
  icon,
  enableAnimation = true,
  onPress,

  ...props
}: ISettingsItemProps) => {
  return (
    <Box elevation={1} mb={5}>
      <Pressable
        br={5}
        onPress={onPress}
        enableAnimation={enableAnimation}
        translationScale={0.99}
        translationHeight={1}
        flexDirection="row"
        bg={`${COLORS.primary2}22`}
        {...props}
      >
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          w="100%"
          pr={20}
          minHeight={70}
        >
          <Box flexDirection="row" alignItems="center">
            <Box
              mr={0}
              py={18 + 5}
              px={15 + 5}
              minWidth={20}
              alignItems="center"
              justifyContent="center"
            >
              {icon}
            </Box>
            <Text size={13} fontWeight="regular" color={COLORS.text[800]}>
              {label}
            </Text>
          </Box>
          {children ? children : null}
        </Box>
      </Pressable>
    </Box>
  );
};
