import { useCallback, useEffect, useRef, useState } from "react";
import { Game } from "../components/game/Game";
import { Image } from "../components/generic/Image";
import { Box } from "../components/generic/Box";
import { Stat } from "../components/Stat";
import { TimeCounter } from "../components/generic/TimeCounter";
import dayjs from "dayjs";
import { GAME_SETTINGS } from "../constants/settings";
import { COLORS } from "../constants/colors";
import { getSecondsDuration } from "../util/time";
import { IGameComplete } from "../types/IGameComplete";
import { getWordsForDate } from "../util/word";
import { BannerAdvert } from "../components/ads/BannerAdvert";
import { Container } from "../components/Container";
import { isWeb } from "../util/platform";

interface IGameScreen {
  onComplete: ({ correctWords, failingWord, state }: IGameComplete) => void;
}

export const GameScreen = ({ onComplete }: IGameScreen) => {
  const endTimeoutRef = useRef<any>(null);
  const [words, setWords] = useState<string[]>();
  const [currentWord, setCurrentWord] = useState<string>();
  const [survivedCount, setSurvivedCount] = useState<number>(0);
  const [timeEnd, setTimeEnd] = useState<string>(
    dayjs().add(GAME_SETTINGS.SECONDS, "seconds").format()
  );

  const onGameFail = useCallback(() => {
    onComplete({
      state: "fail",
      failingWord: words?.[survivedCount + 1],
      correctWords: words?.slice(0, survivedCount + 1),
    });
  }, [onComplete, words, survivedCount]);

  const newGame = useCallback(
    (newWords?: string[]) => {
      const useWords = newWords ?? words;

      const word = useWords[survivedCount + (currentWord ? 1 : 0)];
      if (!word) {
        onComplete({ correctWords: words, failingWord: "", state: "complete" });
        return;
      }

      setCurrentWord(word);
      setTimeEnd(dayjs().add(GAME_SETTINGS.SECONDS, "seconds").format());

      if (endTimeoutRef.current) {
        clearTimeout(endTimeoutRef.current);
      }

      endTimeoutRef.current = setTimeout(() => {
        onGameFail();
      }, GAME_SETTINGS.SECONDS * 1000);
    },
    [currentWord, onComplete, onGameFail, survivedCount, words]
  );

  const onGamePass = useCallback(() => {
    setSurvivedCount((last) => last + 1);
    newGame();
  }, [newGame]);

  useEffect(() => {
    const words = getWordsForDate(dayjs().startOf("day").toDate());
    setWords(words);

    newGame(words);
  }, []);

  return (
    <>
      <Box flex={1} justifyContent="center" alignItems="center" w="100%">
        <Box
          flex={isWeb ? undefined : 1}
          w="100%"
          justifyContent="center"
          alignItems="center"
        >
          {isWeb ? (
            <>
              <Box justifyContent="center" alignItems="center" mb={50} pt={30}>
                <Image
                  showSpinner={false}
                  isLoadingOverlay={false}
                  w={200}
                  h={70}
                  resizeMode="contain"
                  source={require("../../assets/21-logo.png")}
                />
              </Box>
            </>
          ) : null}
          <Container>
            <Box
              flex={1}
              alignItems="center"
              justifyContent="space-between"
              py={isWeb ? 45 : 0}
              px={30}
            >
              {!isWeb ? (
                <>
                  <Box flex={1} />
                  <Box justifyContent="center" alignItems="center" mb={"20%"}>
                    <Image
                      showSpinner={false}
                      isLoadingOverlay={false}
                      w={200}
                      h={70}
                      resizeMode="contain"
                      source={require("../../assets/21-logo.png")}
                    />
                  </Box>
                </>
              ) : null}
              <Box gap={5} w="100%">
                <Box
                  flexDirection="row"
                  gap={5}
                  justifyContent="center"
                  pb={5}
                  w="100%"
                >
                  <Stat
                    label="seconds"
                    value={
                      <TimeCounter
                        getText={getSecondsDuration}
                        time={timeEnd}
                      />
                    }
                  ></Stat>
                  <Stat
                    label="words"
                    color={COLORS.primary2}
                    value={`${survivedCount}`}
                  ></Stat>
                </Box>

                {currentWord ? (
                  <Game word={currentWord} onGamePass={onGamePass}></Game>
                ) : null}
              </Box>
              <Box flex={1} />
            </Box>
          </Container>
          <Box w="100%" justifyContent="center" alignItems="center">
            <Box w="100%" p={10} maxWidth={500}>
              <BannerAdvert />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
