import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Box } from "../generic/Box";
import { Feather, MaterialCommunityIcons } from "@expo/vector-icons";
import { COLORS } from "../../constants/colors";
import { Pressable } from "../generic/Pressable";
import { FadeIn, FadeOut } from "react-native-reanimated";
import { isWeb } from "../../util/platform";

interface IFloatingSettingsProps {
  onPress?: () => void;
}

export const FloatingSettings = ({ onPress }: IFloatingSettingsProps) => {
  const insets = useSafeAreaInsets();

  if (isWeb) return null;

  return (
    <Pressable
      top={insets.top}
      p={30}
      px={32}
      position="absolute"
      onPress={onPress}
      entering={FadeIn.delay(500).duration(400)}
      exiting={FadeOut.duration(400)}
      enableAnimation={true}
    >
      <Box opacity={0.7}>
        <Feather name="settings" size={22} color={COLORS.white} />
      </Box>
    </Pressable>
  );
};
