import React, { ReactNode, memo, useMemo } from "react";
import { Text as RnText } from "react-native";

import { Box, IBoxProps } from "./Box";
import { Skeleton } from "./Skeleton";

type FontStyle = "normal" | "italic";
type FontWeight =
  | "light"
  | "regular"
  | "medium"
  | "bold"
  | "realbold"
  | "extrabold";
type FontAlign = "left" | "center" | "right";

interface ITextProps extends IBoxProps {
  children: ReactNode;
  color?: string;
  family?: string;
  size?: number;
  isLoading?: boolean;
  numberOfLines?: number;

  fontWeight?: FontWeight;
  fontStyle?: FontStyle;
  lineHeight?: number;
  renderTextWhenLoading?: boolean;
  align?: FontAlign;
  enableLayoutProps?: boolean;
}

const WEIGHT_MAP: Record<FontWeight, string> = {
  light: "Poppins_300Light",
  regular: "Poppins_400Regular",
  medium: "Poppins_500Medium",
  bold: "Poppins_600SemiBold",
  realbold: "Poppins_700Bold",
  extrabold: "Poppins_800ExtraBold",
};

const Text_ = ({
  color,
  family,
  children,
  isLoading,
  enableLayoutProps = true,
  size = 14,
  align = "left",
  fontStyle = "normal",
  renderTextWhenLoading,
  numberOfLines,
  lineHeight,
  fontWeight = "medium",
  ...props
}: ITextProps) => {
  const textStyle = useMemo(() => {
    return {
      color,
      fontSize: size,
      textAlign: align,
      lineHeight: lineHeight ? lineHeight : size * 1.5,
      opacity: isLoading ? 0 : 1,
      verticalAlign: "middle",
      fontFamily:
        family ??
        `${WEIGHT_MAP[fontWeight]}${fontStyle === "italic" ? "_Italic" : ""}`,
    };
  }, [size, color, fontWeight, lineHeight, fontStyle, align, isLoading]);

  const content = (
    <>
      <RnText
        allowFontScaling={false}
        // @ts-ignore
        style={textStyle}
        numberOfLines={numberOfLines}
      >
        {isLoading ? `${renderTextWhenLoading ? children : " "}` : children}
      </RnText>
      {isLoading ? (
        <Skeleton
          h={textStyle.lineHeight * 0.8}
          mt={textStyle.lineHeight * 0.1}
          // top="10%"
          w="100%"
          br={50}
          isLoading={isLoading}
          position="absolute"
        />
      ) : null}
    </>
  );

  if (enableLayoutProps) {
    return <Box {...props}>{content}</Box>;
  }

  return content;
};

export const Text = memo(Text_);
