import { Feather } from "@expo/vector-icons";
import { COLORS } from "../constants/colors";
import { Box } from "./generic/Box";
import { Pressable } from "./generic/Pressable";
import { Text } from "./generic/Text";

interface IShareBannerProps {
  onShare?: () => void;
}

export const ShareBanner = ({ onShare }: IShareBannerProps) => {
  return (
    <Pressable
      p={20}
      py={20}
      br={15}
      w="100%"
      onPress={onShare}
      alignItems="center"
      flexDirection="row"
      enableAnimation={true}
      bg={`${COLORS.primary3}33`}
      justifyContent="space-between"
    >
      <Box w="80%">
        <Box flexDirection="row">
          <Text
            color={COLORS.primary3}
            size={20}
            mb={2}
            fontWeight="bold"
            align="left"
          >
            Thanks for playing!
          </Text>
        </Box>
        <Text
          color={COLORS.text[800]}
          size={14}
          fontWeight="medium"
          lineHeight={23}
        >
          If you like 21 words, please share it with your friends!
        </Text>
      </Box>
      <Box>
        <Feather name="chevron-right" color={COLORS.primary3} size={30} />
      </Box>
    </Pressable>
  );
};
