
var CanvasKitInit = (() => {
  var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;
  if (typeof __filename !== 'undefined') _scriptDir = _scriptDir || __filename;
  return (
function(moduleArg = {}) {

var w=moduleArg,aa,ba;w.ready=new Promise((a,b)=>{aa=a;ba=b});
(function(a){a.Td=a.Td||[];a.Td.push(function(){a.MakeSWCanvasSurface=function(b){var d=b,f="undefined"!==typeof OffscreenCanvas&&d instanceof OffscreenCanvas;if(!("undefined"!==typeof HTMLCanvasElement&&d instanceof HTMLCanvasElement||f||(d=document.getElementById(b),d)))throw"Canvas with id "+b+" was not found";if(b=a.MakeSurface(d.width,d.height))b.Md=d;return b};a.MakeCanvasSurface||(a.MakeCanvasSurface=a.MakeSWCanvasSurface);a.MakeSurface=function(b,d){var f={width:b,height:d,colorType:a.ColorType.RGBA_8888,
alphaType:a.AlphaType.Unpremul,colorSpace:a.ColorSpace.SRGB},h=b*d*4,m=a._malloc(h);if(f=a.Surface._makeRasterDirect(f,m,4*b))f.Md=null,f.If=b,f.Ef=d,f.Gf=h,f.cf=m,f.getCanvas().clear(a.TRANSPARENT);return f};a.MakeRasterDirectSurface=function(b,d,f){return a.Surface._makeRasterDirect(b,d.byteOffset,f)};a.Surface.prototype.flush=function(b){a.Nd(this.Ld);this._flush();if(this.Md){var d=new Uint8ClampedArray(a.HEAPU8.buffer,this.cf,this.Gf);d=new ImageData(d,this.If,this.Ef);b?this.Md.getContext("2d").putImageData(d,
0,0,b[0],b[1],b[2]-b[0],b[3]-b[1]):this.Md.getContext("2d").putImageData(d,0,0)}};a.Surface.prototype.dispose=function(){this.cf&&a._free(this.cf);this.delete()};a.Nd=a.Nd||function(){};a.Ue=a.Ue||function(){return null}})})(w);
(function(a){a.Td=a.Td||[];a.Td.push(function(){function b(n,p,v){return n&&n.hasOwnProperty(p)?n[p]:v}function d(n){var p=ha(ia);ia[p]=n;return p}function f(n){return n.naturalHeight||n.videoHeight||n.displayHeight||n.height}function h(n){return n.naturalWidth||n.videoWidth||n.displayWidth||n.width}function m(n,p,v,E){n.bindTexture(n.TEXTURE_2D,p);E||v.alphaType!==a.AlphaType.Premul||n.pixelStorei(n.UNPACK_PREMULTIPLY_ALPHA_WEBGL,!0);return p}function u(n,p,v){v||p.alphaType!==a.AlphaType.Premul||
n.pixelStorei(n.UNPACK_PREMULTIPLY_ALPHA_WEBGL,!1);n.bindTexture(n.TEXTURE_2D,null)}a.GetWebGLContext=function(n,p){if(!n)throw"null canvas passed into makeWebGLContext";var v={alpha:b(p,"alpha",1),depth:b(p,"depth",1),stencil:b(p,"stencil",8),antialias:b(p,"antialias",0),premultipliedAlpha:b(p,"premultipliedAlpha",1),preserveDrawingBuffer:b(p,"preserveDrawingBuffer",0),preferLowPowerToHighPerformance:b(p,"preferLowPowerToHighPerformance",0),failIfMajorPerformanceCaveat:b(p,"failIfMajorPerformanceCaveat",
0),enableExtensionsByDefault:b(p,"enableExtensionsByDefault",1),explicitSwapControl:b(p,"explicitSwapControl",0),renderViaOffscreenBackBuffer:b(p,"renderViaOffscreenBackBuffer",0)};v.majorVersion=p&&p.majorVersion?p.majorVersion:"undefined"!==typeof WebGL2RenderingContext?2:1;if(v.explicitSwapControl)throw"explicitSwapControl is not supported";n=ja(n,v);if(!n)return 0;la(n);A.ge.getExtension("WEBGL_debug_renderer_info");return n};a.deleteContext=function(n){A===oa[n]&&(A=null);"object"==typeof JSEvents&&
JSEvents.yg(oa[n].ge.canvas);oa[n]&&oa[n].ge.canvas&&(oa[n].ge.canvas.Af=void 0);oa[n]=null};a._setTextureCleanup({deleteTexture:function(n,p){var v=ia[p];v&&oa[n].ge.deleteTexture(v);ia[p]=null}});a.MakeWebGLContext=function(n){if(!this.Nd(n))return null;var p=this._MakeGrContext();if(!p)return null;p.Ld=n;var v=p.delete.bind(p);p["delete"]=function(){a.Nd(this.Ld);v()}.bind(p);return A.gf=p};a.MakeGrContext=a.MakeWebGLContext;a.GrDirectContext.prototype.getResourceCacheLimitBytes=function(){a.Nd(this.Ld);
this._getResourceCacheLimitBytes()};a.GrDirectContext.prototype.getResourceCacheUsageBytes=function(){a.Nd(this.Ld);this._getResourceCacheUsageBytes()};a.GrDirectContext.prototype.releaseResourcesAndAbandonContext=function(){a.Nd(this.Ld);this._releaseResourcesAndAbandonContext()};a.GrDirectContext.prototype.setResourceCacheLimitBytes=function(n){a.Nd(this.Ld);this._setResourceCacheLimitBytes(n)};a.MakeOnScreenGLSurface=function(n,p,v,E,H,L){if(!this.Nd(n.Ld))return null;p=void 0===H||void 0===L?
this._MakeOnScreenGLSurface(n,p,v,E):this._MakeOnScreenGLSurface(n,p,v,E,H,L);if(!p)return null;p.Ld=n.Ld;return p};a.MakeRenderTarget=function(){var n=arguments[0];if(!this.Nd(n.Ld))return null;if(3===arguments.length){var p=this._MakeRenderTargetWH(n,arguments[1],arguments[2]);if(!p)return null}else if(2===arguments.length){if(p=this._MakeRenderTargetII(n,arguments[1]),!p)return null}else return null;p.Ld=n.Ld;return p};a.MakeWebGLCanvasSurface=function(n,p,v){p=p||null;var E=n,H="undefined"!==
typeof OffscreenCanvas&&E instanceof OffscreenCanvas;if(!("undefined"!==typeof HTMLCanvasElement&&E instanceof HTMLCanvasElement||H||(E=document.getElementById(n),E)))throw"Canvas with id "+n+" was not found";n=this.GetWebGLContext(E,v);if(!n||0>n)throw"failed to create webgl context: err "+n;n=this.MakeWebGLContext(n);p=this.MakeOnScreenGLSurface(n,E.width,E.height,p);return p?p:(p=E.cloneNode(!0),E.parentNode.replaceChild(p,E),p.classList.add("ck-replaced"),a.MakeSWCanvasSurface(p))};a.MakeCanvasSurface=
a.MakeWebGLCanvasSurface;a.Surface.prototype.makeImageFromTexture=function(n,p){a.Nd(this.Ld);n=d(n);if(p=this._makeImageFromTexture(this.Ld,n,p))p.Me=n;return p};a.Surface.prototype.makeImageFromTextureSource=function(n,p,v){p||(p={height:f(n),width:h(n),colorType:a.ColorType.RGBA_8888,alphaType:v?a.AlphaType.Premul:a.AlphaType.Unpremul});p.colorSpace||(p.colorSpace=a.ColorSpace.SRGB);a.Nd(this.Ld);var E=A.ge;v=m(E,E.createTexture(),p,v);2===A.version?E.texImage2D(E.TEXTURE_2D,0,E.RGBA,p.width,p.height,
0,E.RGBA,E.UNSIGNED_BYTE,n):E.texImage2D(E.TEXTURE_2D,0,E.RGBA,E.RGBA,E.UNSIGNED_BYTE,n);u(E,p);this._resetContext();return this.makeImageFromTexture(v,p)};a.Surface.prototype.updateTextureFromSource=function(n,p,v){if(n.Me){a.Nd(this.Ld);var E=n.getImageInfo(),H=A.ge,L=m(H,ia[n.Me],E,v);2===A.version?H.texImage2D(H.TEXTURE_2D,0,H.RGBA,h(p),f(p),0,H.RGBA,H.UNSIGNED_BYTE,p):H.texImage2D(H.TEXTURE_2D,0,H.RGBA,H.RGBA,H.UNSIGNED_BYTE,p);u(H,E,v);this._resetContext();ia[n.Me]=null;n.Me=d(L);E.colorSpace=
n.getColorSpace();p=this._makeImageFromTexture(this.Ld,n.Me,E);v=n.Kd.Sd;H=n.Kd.Zd;n.Kd.Sd=p.Kd.Sd;n.Kd.Zd=p.Kd.Zd;p.Kd.Sd=v;p.Kd.Zd=H;p.delete();E.colorSpace.delete()}};a.MakeLazyImageFromTextureSource=function(n,p,v){p||(p={height:f(n),width:h(n),colorType:a.ColorType.RGBA_8888,alphaType:v?a.AlphaType.Premul:a.AlphaType.Unpremul});p.colorSpace||(p.colorSpace=a.ColorSpace.SRGB);var E={makeTexture:function(){var H=A,L=H.ge,y=m(L,L.createTexture(),p,v);2===H.version?L.texImage2D(L.TEXTURE_2D,0,L.RGBA,
p.width,p.height,0,L.RGBA,L.UNSIGNED_BYTE,n):L.texImage2D(L.TEXTURE_2D,0,L.RGBA,L.RGBA,L.UNSIGNED_BYTE,n);u(L,p,v);return d(y)},freeSrc:function(){}};"VideoFrame"===n.constructor.name&&(E.freeSrc=function(){n.close()});return a.Image._makeFromGenerator(p,E)};a.Nd=function(n){return n?la(n):!1};a.Ue=function(){return A&&A.gf&&!A.gf.isDeleted()?A.gf:null}})})(w);
(function(a){function b(e,c,g,l,t){for(var x=0;x<e.length;x++)c[x*g+(x*t+l+g)%g]=e[x];return c}function d(e){for(var c=e*e,g=Array(c);c--;)g[c]=0===c%(e+1)?1:0;return g}function f(e){return e?e.constructor===Float32Array&&4===e.length:!1}function h(e){return(n(255*e[3])<<24|n(255*e[0])<<16|n(255*e[1])<<8|n(255*e[2])<<0)>>>0}function m(e){if(e&&e._ck)return e;if(e instanceof Float32Array){for(var c=Math.floor(e.length/4),g=new Uint32Array(c),l=0;l<c;l++)g[l]=h(e.slice(4*l,4*(l+1)));return g}if(e instanceof
Uint32Array)return e;if(e instanceof Array&&e[0]instanceof Float32Array)return e.map(h)}function u(e){if(void 0===e)return 1;var c=parseFloat(e);return e&&-1!==e.indexOf("%")?c/100:c}function n(e){return Math.round(Math.max(0,Math.min(e||0,255)))}function p(e,c){c&&c._ck||a._free(e)}function v(e,c,g){if(!e||!e.length)return V;if(e&&e._ck)return e.byteOffset;var l=a[c].BYTES_PER_ELEMENT;g||(g=a._malloc(e.length*l));a[c].set(e,g/l);return g}function E(e){var c={ce:V,count:e.length,colorType:a.ColorType.RGBA_F32};
if(e instanceof Float32Array)c.ce=v(e,"HEAPF32"),c.count=e.length/4;else if(e instanceof Uint32Array)c.ce=v(e,"HEAPU32"),c.colorType=a.ColorType.RGBA_8888;else if(e instanceof Array){if(e&&e.length){for(var g=a._malloc(16*e.length),l=0,t=g/4,x=0;x<e.length;x++)for(var B=0;4>B;B++)a.HEAPF32[t+l]=e[x][B],l++;e=g}else e=V;c.ce=e}else throw"Invalid argument to copyFlexibleColorArray, Not a color array "+typeof e;return c}function H(e){if(!e)return V;var c=Wb.toTypedArray();if(e.length){if(6===e.length||
9===e.length)return v(e,"HEAPF32",Na),6===e.length&&a.HEAPF32.set(wd,6+Na/4),Na;if(16===e.length)return c[0]=e[0],c[1]=e[1],c[2]=e[3],c[3]=e[4],c[4]=e[5],c[5]=e[7],c[6]=e[12],c[7]=e[13],c[8]=e[15],Na;throw"invalid matrix size";}if(void 0===e.m11)throw"invalid matrix argument";c[0]=e.m11;c[1]=e.m21;c[2]=e.m41;c[3]=e.m12;c[4]=e.m22;c[5]=e.m42;c[6]=e.m14;c[7]=e.m24;c[8]=e.m44;return Na}function L(e){if(!e)return V;var c=Xb.toTypedArray();if(e.length){if(16!==e.length&&6!==e.length&&9!==e.length)throw"invalid matrix size";
if(16===e.length)return v(e,"HEAPF32",$a);c.fill(0);c[0]=e[0];c[1]=e[1];c[3]=e[2];c[4]=e[3];c[5]=e[4];c[7]=e[5];c[10]=1;c[12]=e[6];c[13]=e[7];c[15]=e[8];6===e.length&&(c[12]=0,c[13]=0,c[15]=1);return $a}if(void 0===e.m11)throw"invalid matrix argument";c[0]=e.m11;c[1]=e.m21;c[2]=e.m31;c[3]=e.m41;c[4]=e.m12;c[5]=e.m22;c[6]=e.m32;c[7]=e.m42;c[8]=e.m13;c[9]=e.m23;c[10]=e.m33;c[11]=e.m43;c[12]=e.m14;c[13]=e.m24;c[14]=e.m34;c[15]=e.m44;return $a}function y(e,c){return v(e,"HEAPF32",c||Ia)}function N(e,
c,g,l){var t=Yb.toTypedArray();t[0]=e;t[1]=c;t[2]=g;t[3]=l;return Ia}function T(e){for(var c=new Float32Array(4),g=0;4>g;g++)c[g]=a.HEAPF32[e/4+g];return c}function R(e,c){return v(e,"HEAPF32",c||ca)}function ta(e,c){return v(e,"HEAPF32",c||Zb)}function ma(){for(var e=0,c=0;c<arguments.length-1;c+=2)e+=arguments[c]*arguments[c+1];return e}function hb(e,c,g){for(var l=Array(e.length),t=0;t<g;t++)for(var x=0;x<g;x++){for(var B=0,J=0;J<g;J++)B+=e[g*t+J]*c[g*J+x];l[t*g+x]=B}return l}function ib(e,c){for(var g=
hb(c[0],c[1],e),l=2;l<c.length;)g=hb(g,c[l],e),l++;return g}a.Color=function(e,c,g,l){void 0===l&&(l=1);return a.Color4f(n(e)/255,n(c)/255,n(g)/255,l)};a.ColorAsInt=function(e,c,g,l){void 0===l&&(l=255);return(n(l)<<24|n(e)<<16|n(c)<<8|n(g)<<0&268435455)>>>0};a.Color4f=function(e,c,g,l){void 0===l&&(l=1);return Float32Array.of(e,c,g,l)};Object.defineProperty(a,"TRANSPARENT",{get:function(){return a.Color4f(0,0,0,0)}});Object.defineProperty(a,"BLACK",{get:function(){return a.Color4f(0,0,0,1)}});Object.defineProperty(a,
"WHITE",{get:function(){return a.Color4f(1,1,1,1)}});Object.defineProperty(a,"RED",{get:function(){return a.Color4f(1,0,0,1)}});Object.defineProperty(a,"GREEN",{get:function(){return a.Color4f(0,1,0,1)}});Object.defineProperty(a,"BLUE",{get:function(){return a.Color4f(0,0,1,1)}});Object.defineProperty(a,"YELLOW",{get:function(){return a.Color4f(1,1,0,1)}});Object.defineProperty(a,"CYAN",{get:function(){return a.Color4f(0,1,1,1)}});Object.defineProperty(a,"MAGENTA",{get:function(){return a.Color4f(1,
0,1,1)}});a.getColorComponents=function(e){return[Math.floor(255*e[0]),Math.floor(255*e[1]),Math.floor(255*e[2]),e[3]]};a.parseColorString=function(e,c){e=e.toLowerCase();if(e.startsWith("#")){c=255;switch(e.length){case 9:c=parseInt(e.slice(7,9),16);case 7:var g=parseInt(e.slice(1,3),16);var l=parseInt(e.slice(3,5),16);var t=parseInt(e.slice(5,7),16);break;case 5:c=17*parseInt(e.slice(4,5),16);case 4:g=17*parseInt(e.slice(1,2),16),l=17*parseInt(e.slice(2,3),16),t=17*parseInt(e.slice(3,4),16)}return a.Color(g,
l,t,c/255)}return e.startsWith("rgba")?(e=e.slice(5,-1),e=e.split(","),a.Color(+e[0],+e[1],+e[2],u(e[3]))):e.startsWith("rgb")?(e=e.slice(4,-1),e=e.split(","),a.Color(+e[0],+e[1],+e[2],u(e[3]))):e.startsWith("gray(")||e.startsWith("hsl")||!c||(e=c[e],void 0===e)?a.BLACK:e};a.multiplyByAlpha=function(e,c){e=e.slice();e[3]=Math.max(0,Math.min(e[3]*c,1));return e};a.Malloc=function(e,c){var g=a._malloc(c*e.BYTES_PER_ELEMENT);return{_ck:!0,length:c,byteOffset:g,re:null,subarray:function(l,t){l=this.toTypedArray().subarray(l,
t);l._ck=!0;return l},toTypedArray:function(){if(this.re&&this.re.length)return this.re;this.re=new e(a.HEAPU8.buffer,g,c);this.re._ck=!0;return this.re}}};a.Free=function(e){a._free(e.byteOffset);e.byteOffset=V;e.toTypedArray=null;e.re=null};var Na=V,Wb,$a=V,Xb,Ia=V,Yb,sa,ca=V,yc,Oa=V,$b,ab=V,zc,ac=V,zb,jb=V,Ac,Zb=V,Bc,Cc=V,wd=Float32Array.of(0,0,1),V=0;a.onRuntimeInitialized=function(){function e(c,g,l,t,x,B,J){B||(B=4*t.width,t.colorType===a.ColorType.RGBA_F16?B*=2:t.colorType===a.ColorType.RGBA_F32&&
(B*=4));var P=B*t.height;var O=x?x.byteOffset:a._malloc(P);if(J?!c._readPixels(t,O,B,g,l,J):!c._readPixels(t,O,B,g,l))return x||a._free(O),null;if(x)return x.toTypedArray();switch(t.colorType){case a.ColorType.RGBA_8888:case a.ColorType.RGBA_F16:c=(new Uint8Array(a.HEAPU8.buffer,O,P)).slice();break;case a.ColorType.RGBA_F32:c=(new Float32Array(a.HEAPU8.buffer,O,P)).slice();break;default:return null}a._free(O);return c}Yb=a.Malloc(Float32Array,4);Ia=Yb.byteOffset;Xb=a.Malloc(Float32Array,16);$a=Xb.byteOffset;
Wb=a.Malloc(Float32Array,9);Na=Wb.byteOffset;Ac=a.Malloc(Float32Array,12);Zb=Ac.byteOffset;Bc=a.Malloc(Float32Array,12);Cc=Bc.byteOffset;sa=a.Malloc(Float32Array,4);ca=sa.byteOffset;yc=a.Malloc(Float32Array,4);Oa=yc.byteOffset;$b=a.Malloc(Float32Array,3);ab=$b.byteOffset;zc=a.Malloc(Float32Array,3);ac=zc.byteOffset;zb=a.Malloc(Int32Array,4);jb=zb.byteOffset;a.ColorSpace.SRGB=a.ColorSpace._MakeSRGB();a.ColorSpace.DISPLAY_P3=a.ColorSpace._MakeDisplayP3();a.ColorSpace.ADOBE_RGB=a.ColorSpace._MakeAdobeRGB();
a.GlyphRunFlags={IsWhiteSpace:a._GlyphRunFlags_isWhiteSpace};a.Path.MakeFromCmds=function(c){var g=v(c,"HEAPF32"),l=a.Path._MakeFromCmds(g,c.length);p(g,c);return l};a.Path.MakeFromVerbsPointsWeights=function(c,g,l){var t=v(c,"HEAPU8"),x=v(g,"HEAPF32"),B=v(l,"HEAPF32"),J=a.Path._MakeFromVerbsPointsWeights(t,c.length,x,g.length,B,l&&l.length||0);p(t,c);p(x,g);p(B,l);return J};a.Path.prototype.addArc=function(c,g,l){c=R(c);this._addArc(c,g,l);return this};a.Path.prototype.addCircle=function(c,g,l,t){this._addCircle(c,
g,l,!!t);return this};a.Path.prototype.addOval=function(c,g,l){void 0===l&&(l=1);c=R(c);this._addOval(c,!!g,l);return this};a.Path.prototype.addPath=function(){var c=Array.prototype.slice.call(arguments),g=c[0],l=!1;"boolean"===typeof c[c.length-1]&&(l=c.pop());if(1===c.length)this._addPath(g,1,0,0,0,1,0,0,0,1,l);else if(2===c.length)c=c[1],this._addPath(g,c[0],c[1],c[2],c[3],c[4],c[5],c[6]||0,c[7]||0,c[8]||1,l);else if(7===c.length||10===c.length)this._addPath(g,c[1],c[2],c[3],c[4],c[5],c[6],c[7]||
0,c[8]||0,c[9]||1,l);else return null;return this};a.Path.prototype.addPoly=function(c,g){var l=v(c,"HEAPF32");this._addPoly(l,c.length/2,g);p(l,c);return this};a.Path.prototype.addRect=function(c,g){c=R(c);this._addRect(c,!!g);return this};a.Path.prototype.addRRect=function(c,g){c=ta(c);this._addRRect(c,!!g);return this};a.Path.prototype.addVerbsPointsWeights=function(c,g,l){var t=v(c,"HEAPU8"),x=v(g,"HEAPF32"),B=v(l,"HEAPF32");this._addVerbsPointsWeights(t,c.length,x,g.length,B,l&&l.length||0);
p(t,c);p(x,g);p(B,l)};a.Path.prototype.arc=function(c,g,l,t,x,B){c=a.LTRBRect(c-l,g-l,c+l,g+l);x=(x-t)/Math.PI*180-360*!!B;B=new a.Path;B.addArc(c,t/Math.PI*180,x);this.addPath(B,!0);B.delete();return this};a.Path.prototype.arcToOval=function(c,g,l,t){c=R(c);this._arcToOval(c,g,l,t);return this};a.Path.prototype.arcToRotated=function(c,g,l,t,x,B,J){this._arcToRotated(c,g,l,!!t,!!x,B,J);return this};a.Path.prototype.arcToTangent=function(c,g,l,t,x){this._arcToTangent(c,g,l,t,x);return this};a.Path.prototype.close=
function(){this._close();return this};a.Path.prototype.conicTo=function(c,g,l,t,x){this._conicTo(c,g,l,t,x);return this};a.Path.prototype.computeTightBounds=function(c){this._computeTightBounds(ca);var g=sa.toTypedArray();return c?(c.set(g),c):g.slice()};a.Path.prototype.cubicTo=function(c,g,l,t,x,B){this._cubicTo(c,g,l,t,x,B);return this};a.Path.prototype.dash=function(c,g,l){return this._dash(c,g,l)?this:null};a.Path.prototype.getBounds=function(c){this._getBounds(ca);var g=sa.toTypedArray();return c?
(c.set(g),c):g.slice()};a.Path.prototype.lineTo=function(c,g){this._lineTo(c,g);return this};a.Path.prototype.moveTo=function(c,g){this._moveTo(c,g);return this};a.Path.prototype.offset=function(c,g){this._transform(1,0,c,0,1,g,0,0,1);return this};a.Path.prototype.quadTo=function(c,g,l,t){this._quadTo(c,g,l,t);return this};a.Path.prototype.rArcTo=function(c,g,l,t,x,B,J){this._rArcTo(c,g,l,t,x,B,J);return this};a.Path.prototype.rConicTo=function(c,g,l,t,x){this._rConicTo(c,g,l,t,x);return this};a.Path.prototype.rCubicTo=
function(c,g,l,t,x,B){this._rCubicTo(c,g,l,t,x,B);return this};a.Path.prototype.rLineTo=function(c,g){this._rLineTo(c,g);return this};a.Path.prototype.rMoveTo=function(c,g){this._rMoveTo(c,g);return this};a.Path.prototype.rQuadTo=function(c,g,l,t){this._rQuadTo(c,g,l,t);return this};a.Path.prototype.stroke=function(c){c=c||{};c.width=c.width||1;c.miter_limit=c.miter_limit||4;c.cap=c.cap||a.StrokeCap.Butt;c.join=c.join||a.StrokeJoin.Miter;c.precision=c.precision||1;return this._stroke(c)?this:null};
a.Path.prototype.transform=function(){if(1===arguments.length){var c=arguments[0];this._transform(c[0],c[1],c[2],c[3],c[4],c[5],c[6]||0,c[7]||0,c[8]||1)}else if(6===arguments.length||9===arguments.length)c=arguments,this._transform(c[0],c[1],c[2],c[3],c[4],c[5],c[6]||0,c[7]||0,c[8]||1);else throw"transform expected to take 1 or 9 arguments. Got "+arguments.length;return this};a.Path.prototype.trim=function(c,g,l){return this._trim(c,g,!!l)?this:null};a.Image.prototype.encodeToBytes=function(c,g){var l=
a.Ue();c=c||a.ImageFormat.PNG;g=g||100;return l?this._encodeToBytes(c,g,l):this._encodeToBytes(c,g)};a.Image.prototype.makeShaderCubic=function(c,g,l,t,x){x=H(x);return this._makeShaderCubic(c,g,l,t,x)};a.Image.prototype.makeShaderOptions=function(c,g,l,t,x){x=H(x);return this._makeShaderOptions(c,g,l,t,x)};a.Image.prototype.readPixels=function(c,g,l,t,x){var B=a.Ue();return e(this,c,g,l,t,x,B)};a.Canvas.prototype.clear=function(c){a.Nd(this.Ld);c=y(c);this._clear(c)};a.Canvas.prototype.clipRRect=
function(c,g,l){a.Nd(this.Ld);c=ta(c);this._clipRRect(c,g,l)};a.Canvas.prototype.clipRect=function(c,g,l){a.Nd(this.Ld);c=R(c);this._clipRect(c,g,l)};a.Canvas.prototype.concat=function(c){a.Nd(this.Ld);c=L(c);this._concat(c)};a.Canvas.prototype.drawArc=function(c,g,l,t,x){a.Nd(this.Ld);c=R(c);this._drawArc(c,g,l,t,x)};a.Canvas.prototype.drawAtlas=function(c,g,l,t,x,B,J){if(c&&t&&g&&l&&g.length===l.length){a.Nd(this.Ld);x||(x=a.BlendMode.SrcOver);var P=v(g,"HEAPF32"),O=v(l,"HEAPF32"),Y=l.length/4,
W=v(m(B),"HEAPU32");if(J&&"B"in J&&"C"in J)this._drawAtlasCubic(c,O,P,W,Y,x,J.B,J.C,t);else{let r=a.FilterMode.Linear,D=a.MipmapMode.None;J&&(r=J.filter,"mipmap"in J&&(D=J.mipmap));this._drawAtlasOptions(c,O,P,W,Y,x,r,D,t)}p(P,g);p(O,l);p(W,B)}};a.Canvas.prototype.drawCircle=function(c,g,l,t){a.Nd(this.Ld);this._drawCircle(c,g,l,t)};a.Canvas.prototype.drawColor=function(c,g){a.Nd(this.Ld);c=y(c);void 0!==g?this._drawColor(c,g):this._drawColor(c)};a.Canvas.prototype.drawColorInt=function(c,g){a.Nd(this.Ld);
this._drawColorInt(c,g||a.BlendMode.SrcOver)};a.Canvas.prototype.drawColorComponents=function(c,g,l,t,x){a.Nd(this.Ld);c=N(c,g,l,t);void 0!==x?this._drawColor(c,x):this._drawColor(c)};a.Canvas.prototype.drawDRRect=function(c,g,l){a.Nd(this.Ld);c=ta(c,Zb);g=ta(g,Cc);this._drawDRRect(c,g,l)};a.Canvas.prototype.drawImage=function(c,g,l,t){a.Nd(this.Ld);this._drawImage(c,g,l,t||null)};a.Canvas.prototype.drawImageCubic=function(c,g,l,t,x,B){a.Nd(this.Ld);this._drawImageCubic(c,g,l,t,x,B||null)};a.Canvas.prototype.drawImageOptions=
function(c,g,l,t,x,B){a.Nd(this.Ld);this._drawImageOptions(c,g,l,t,x,B||null)};a.Canvas.prototype.drawImageNine=function(c,g,l,t,x){a.Nd(this.Ld);g=v(g,"HEAP32",jb);l=R(l);this._drawImageNine(c,g,l,t,x||null)};a.Canvas.prototype.drawImageRect=function(c,g,l,t,x){a.Nd(this.Ld);R(g,ca);R(l,Oa);this._drawImageRect(c,ca,Oa,t,!!x)};a.Canvas.prototype.drawImageRectCubic=function(c,g,l,t,x,B){a.Nd(this.Ld);R(g,ca);R(l,Oa);this._drawImageRectCubic(c,ca,Oa,t,x,B||null)};a.Canvas.prototype.drawImageRectOptions=
function(c,g,l,t,x,B){a.Nd(this.Ld);R(g,ca);R(l,Oa);this._drawImageRectOptions(c,ca,Oa,t,x,B||null)};a.Canvas.prototype.drawLine=function(c,g,l,t,x){a.Nd(this.Ld);this._drawLine(c,g,l,t,x)};a.Canvas.prototype.drawOval=function(c,g){a.Nd(this.Ld);c=R(c);this._drawOval(c,g)};a.Canvas.prototype.drawPaint=function(c){a.Nd(this.Ld);this._drawPaint(c)};a.Canvas.prototype.drawParagraph=function(c,g,l){a.Nd(this.Ld);this._drawParagraph(c,g,l)};a.Canvas.prototype.drawPatch=function(c,g,l,t,x){if(24>c.length)throw"Need 12 cubic points";
if(g&&4>g.length)throw"Need 4 colors";if(l&&8>l.length)throw"Need 4 shader coordinates";a.Nd(this.Ld);const B=v(c,"HEAPF32"),J=g?v(m(g),"HEAPU32"):V,P=l?v(l,"HEAPF32"):V;t||(t=a.BlendMode.Modulate);this._drawPatch(B,J,P,t,x);p(P,l);p(J,g);p(B,c)};a.Canvas.prototype.drawPath=function(c,g){a.Nd(this.Ld);this._drawPath(c,g)};a.Canvas.prototype.drawPicture=function(c){a.Nd(this.Ld);this._drawPicture(c)};a.Canvas.prototype.drawPoints=function(c,g,l){a.Nd(this.Ld);var t=v(g,"HEAPF32");this._drawPoints(c,
t,g.length/2,l);p(t,g)};a.Canvas.prototype.drawRRect=function(c,g){a.Nd(this.Ld);c=ta(c);this._drawRRect(c,g)};a.Canvas.prototype.drawRect=function(c,g){a.Nd(this.Ld);c=R(c);this._drawRect(c,g)};a.Canvas.prototype.drawRect4f=function(c,g,l,t,x){a.Nd(this.Ld);this._drawRect4f(c,g,l,t,x)};a.Canvas.prototype.drawShadow=function(c,g,l,t,x,B,J){a.Nd(this.Ld);var P=v(x,"HEAPF32"),O=v(B,"HEAPF32");g=v(g,"HEAPF32",ab);l=v(l,"HEAPF32",ac);this._drawShadow(c,g,l,t,P,O,J);p(P,x);p(O,B)};a.getShadowLocalBounds=
function(c,g,l,t,x,B,J){c=H(c);l=v(l,"HEAPF32",ab);t=v(t,"HEAPF32",ac);if(!this._getShadowLocalBounds(c,g,l,t,x,B,ca))return null;g=sa.toTypedArray();return J?(J.set(g),J):g.slice()};a.Canvas.prototype.drawTextBlob=function(c,g,l,t){a.Nd(this.Ld);this._drawTextBlob(c,g,l,t)};a.Canvas.prototype.drawVertices=function(c,g,l){a.Nd(this.Ld);this._drawVertices(c,g,l)};a.Canvas.prototype.getDeviceClipBounds=function(c){this._getDeviceClipBounds(jb);var g=zb.toTypedArray();c?c.set(g):c=g.slice();return c};
a.Canvas.prototype.getLocalToDevice=function(){this._getLocalToDevice($a);for(var c=$a,g=Array(16),l=0;16>l;l++)g[l]=a.HEAPF32[c/4+l];return g};a.Canvas.prototype.getTotalMatrix=function(){this._getTotalMatrix(Na);for(var c=Array(9),g=0;9>g;g++)c[g]=a.HEAPF32[Na/4+g];return c};a.Canvas.prototype.makeSurface=function(c){c=this._makeSurface(c);c.Ld=this.Ld;return c};a.Canvas.prototype.readPixels=function(c,g,l,t,x){a.Nd(this.Ld);return e(this,c,g,l,t,x)};a.Canvas.prototype.saveLayer=function(c,g,l,
t){g=R(g);return this._saveLayer(c||null,g,l||null,t||0)};a.Canvas.prototype.writePixels=function(c,g,l,t,x,B,J,P){if(c.byteLength%(g*l))throw"pixels length must be a multiple of the srcWidth * srcHeight";a.Nd(this.Ld);var O=c.byteLength/(g*l);B=B||a.AlphaType.Unpremul;J=J||a.ColorType.RGBA_8888;P=P||a.ColorSpace.SRGB;var Y=O*g;O=v(c,"HEAPU8");g=this._writePixels({width:g,height:l,colorType:J,alphaType:B,colorSpace:P},O,Y,t,x);p(O,c);return g};a.ColorFilter.MakeBlend=function(c,g,l){c=y(c);l=l||a.ColorSpace.SRGB;
return a.ColorFilter._MakeBlend(c,g,l)};a.ColorFilter.MakeMatrix=function(c){if(!c||20!==c.length)throw"invalid color matrix";var g=v(c,"HEAPF32"),l=a.ColorFilter._makeMatrix(g);p(g,c);return l};a.ContourMeasure.prototype.getPosTan=function(c,g){this._getPosTan(c,ca);c=sa.toTypedArray();return g?(g.set(c),g):c.slice()};a.ImageFilter.prototype.getOutputBounds=function(c,g,l){c=R(c,ca);g=H(g);this._getOutputBounds(c,g,jb);g=zb.toTypedArray();return l?(l.set(g),l):g.slice()};a.ImageFilter.MakeDropShadow=
function(c,g,l,t,x,B){x=y(x,Ia);return a.ImageFilter._MakeDropShadow(c,g,l,t,x,B)};a.ImageFilter.MakeDropShadowOnly=function(c,g,l,t,x,B){x=y(x,Ia);return a.ImageFilter._MakeDropShadowOnly(c,g,l,t,x,B)};a.ImageFilter.MakeImage=function(c,g,l,t){l=R(l,ca);t=R(t,Oa);if("B"in g&&"C"in g)return a.ImageFilter._MakeImageCubic(c,g.B,g.C,l,t);const x=g.filter;let B=a.MipmapMode.None;"mipmap"in g&&(B=g.mipmap);return a.ImageFilter._MakeImageOptions(c,x,B,l,t)};a.ImageFilter.MakeMatrixTransform=function(c,
g,l){c=H(c);if("B"in g&&"C"in g)return a.ImageFilter._MakeMatrixTransformCubic(c,g.B,g.C,l);const t=g.filter;let x=a.MipmapMode.None;"mipmap"in g&&(x=g.mipmap);return a.ImageFilter._MakeMatrixTransformOptions(c,t,x,l)};a.Paint.prototype.getColor=function(){this._getColor(Ia);return T(Ia)};a.Paint.prototype.setColor=function(c,g){g=g||null;c=y(c);this._setColor(c,g)};a.Paint.prototype.setColorComponents=function(c,g,l,t,x){x=x||null;c=N(c,g,l,t);this._setColor(c,x)};a.Path.prototype.getPoint=function(c,
g){this._getPoint(c,ca);c=sa.toTypedArray();return g?(g[0]=c[0],g[1]=c[1],g):c.slice(0,2)};a.Picture.prototype.makeShader=function(c,g,l,t,x){t=H(t);x=R(x);return this._makeShader(c,g,l,t,x)};a.Picture.prototype.cullRect=function(c){this._cullRect(ca);var g=sa.toTypedArray();return c?(c.set(g),c):g.slice()};a.PictureRecorder.prototype.beginRecording=function(c,g){c=R(c);return this._beginRecording(c,!!g)};a.Surface.prototype.getCanvas=function(){var c=this._getCanvas();c.Ld=this.Ld;return c};a.Surface.prototype.makeImageSnapshot=
function(c){a.Nd(this.Ld);c=v(c,"HEAP32",jb);return this._makeImageSnapshot(c)};a.Surface.prototype.makeSurface=function(c){a.Nd(this.Ld);c=this._makeSurface(c);c.Ld=this.Ld;return c};a.Surface.prototype.Hf=function(c,g){this.Ie||(this.Ie=this.getCanvas());return requestAnimationFrame(function(){a.Nd(this.Ld);c(this.Ie);this.flush(g)}.bind(this))};a.Surface.prototype.requestAnimationFrame||(a.Surface.prototype.requestAnimationFrame=a.Surface.prototype.Hf);a.Surface.prototype.Df=function(c,g){this.Ie||
(this.Ie=this.getCanvas());requestAnimationFrame(function(){a.Nd(this.Ld);c(this.Ie);this.flush(g);this.dispose()}.bind(this))};a.Surface.prototype.drawOnce||(a.Surface.prototype.drawOnce=a.Surface.prototype.Df);a.PathEffect.MakeDash=function(c,g){g||(g=0);if(!c.length||1===c.length%2)throw"Intervals array must have even length";var l=v(c,"HEAPF32");g=a.PathEffect._MakeDash(l,c.length,g);p(l,c);return g};a.PathEffect.MakeLine2D=function(c,g){g=H(g);return a.PathEffect._MakeLine2D(c,g)};a.PathEffect.MakePath2D=
function(c,g){c=H(c);return a.PathEffect._MakePath2D(c,g)};a.Shader.MakeColor=function(c,g){g=g||null;c=y(c);return a.Shader._MakeColor(c,g)};a.Shader.Blend=a.Shader.MakeBlend;a.Shader.Color=a.Shader.MakeColor;a.Shader.MakeLinearGradient=function(c,g,l,t,x,B,J,P){P=P||null;var O=E(l),Y=v(t,"HEAPF32");J=J||0;B=H(B);var W=sa.toTypedArray();W.set(c);W.set(g,2);c=a.Shader._MakeLinearGradient(ca,O.ce,O.colorType,Y,O.count,x,J,B,P);p(O.ce,l);t&&p(Y,t);return c};a.Shader.MakeRadialGradient=function(c,g,
l,t,x,B,J,P){P=P||null;var O=E(l),Y=v(t,"HEAPF32");J=J||0;B=H(B);c=a.Shader._MakeRadialGradient(c[0],c[1],g,O.ce,O.colorType,Y,O.count,x,J,B,P);p(O.ce,l);t&&p(Y,t);return c};a.Shader.MakeSweepGradient=function(c,g,l,t,x,B,J,P,O,Y){Y=Y||null;var W=E(l),r=v(t,"HEAPF32");J=J||0;P=P||0;O=O||360;B=H(B);c=a.Shader._MakeSweepGradient(c,g,W.ce,W.colorType,r,W.count,x,P,O,J,B,Y);p(W.ce,l);t&&p(r,t);return c};a.Shader.MakeTwoPointConicalGradient=function(c,g,l,t,x,B,J,P,O,Y){Y=Y||null;var W=E(x),r=v(B,"HEAPF32");
O=O||0;P=H(P);var D=sa.toTypedArray();D.set(c);D.set(l,2);c=a.Shader._MakeTwoPointConicalGradient(ca,g,t,W.ce,W.colorType,r,W.count,J,O,P,Y);p(W.ce,x);B&&p(r,B);return c};a.Vertices.prototype.bounds=function(c){this._bounds(ca);var g=sa.toTypedArray();return c?(c.set(g),c):g.slice()};a.Td&&a.Td.forEach(function(c){c()})};a.computeTonalColors=function(e){var c=v(e.ambient,"HEAPF32"),g=v(e.spot,"HEAPF32");this._computeTonalColors(c,g);var l={ambient:T(c),spot:T(g)};p(c,e.ambient);p(g,e.spot);return l};
a.LTRBRect=function(e,c,g,l){return Float32Array.of(e,c,g,l)};a.XYWHRect=function(e,c,g,l){return Float32Array.of(e,c,e+g,c+l)};a.LTRBiRect=function(e,c,g,l){return Int32Array.of(e,c,g,l)};a.XYWHiRect=function(e,c,g,l){return Int32Array.of(e,c,e+g,c+l)};a.RRectXY=function(e,c,g){return Float32Array.of(e[0],e[1],e[2],e[3],c,g,c,g,c,g,c,g)};a.MakeAnimatedImageFromEncoded=function(e){e=new Uint8Array(e);var c=a._malloc(e.byteLength);a.HEAPU8.set(e,c);return(e=a._decodeAnimatedImage(c,e.byteLength))?
e:null};a.MakeImageFromEncoded=function(e){e=new Uint8Array(e);var c=a._malloc(e.byteLength);a.HEAPU8.set(e,c);return(e=a._decodeImage(c,e.byteLength))?e:null};var kb=null;a.MakeImageFromCanvasImageSource=function(e){var c=e.width,g=e.height;kb||(kb=document.createElement("canvas"));kb.width=c;kb.height=g;var l=kb.getContext("2d",{willReadFrequently:!0});l.drawImage(e,0,0);e=l.getImageData(0,0,c,g);return a.MakeImage({width:c,height:g,alphaType:a.AlphaType.Unpremul,colorType:a.ColorType.RGBA_8888,
colorSpace:a.ColorSpace.SRGB},e.data,4*c)};a.MakeImage=function(e,c,g){var l=a._malloc(c.length);a.HEAPU8.set(c,l);return a._MakeImage(e,l,c.length,g)};a.MakeVertices=function(e,c,g,l,t,x){var B=t&&t.length||0,J=0;g&&g.length&&(J|=1);l&&l.length&&(J|=2);void 0===x||x||(J|=4);e=new a._VerticesBuilder(e,c.length/2,B,J);v(c,"HEAPF32",e.positions());e.texCoords()&&v(g,"HEAPF32",e.texCoords());e.colors()&&v(m(l),"HEAPU32",e.colors());e.indices()&&v(t,"HEAPU16",e.indices());return e.detach()};a.Matrix=
{};a.Matrix.identity=function(){return d(3)};a.Matrix.invert=function(e){var c=e[0]*e[4]*e[8]+e[1]*e[5]*e[6]+e[2]*e[3]*e[7]-e[2]*e[4]*e[6]-e[1]*e[3]*e[8]-e[0]*e[5]*e[7];return c?[(e[4]*e[8]-e[5]*e[7])/c,(e[2]*e[7]-e[1]*e[8])/c,(e[1]*e[5]-e[2]*e[4])/c,(e[5]*e[6]-e[3]*e[8])/c,(e[0]*e[8]-e[2]*e[6])/c,(e[2]*e[3]-e[0]*e[5])/c,(e[3]*e[7]-e[4]*e[6])/c,(e[1]*e[6]-e[0]*e[7])/c,(e[0]*e[4]-e[1]*e[3])/c]:null};a.Matrix.mapPoints=function(e,c){for(var g=0;g<c.length;g+=2){var l=c[g],t=c[g+1],x=e[6]*l+e[7]*t+e[8],
B=e[3]*l+e[4]*t+e[5];c[g]=(e[0]*l+e[1]*t+e[2])/x;c[g+1]=B/x}return c};a.Matrix.multiply=function(){return ib(3,arguments)};a.Matrix.rotated=function(e,c,g){c=c||0;g=g||0;var l=Math.sin(e);e=Math.cos(e);return[e,-l,ma(l,g,1-e,c),l,e,ma(-l,c,1-e,g),0,0,1]};a.Matrix.scaled=function(e,c,g,l){g=g||0;l=l||0;var t=b([e,c],d(3),3,0,1);return b([g-e*g,l-c*l],t,3,2,0)};a.Matrix.skewed=function(e,c,g,l){g=g||0;l=l||0;var t=b([e,c],d(3),3,1,-1);return b([-e*g,-c*l],t,3,2,0)};a.Matrix.translated=function(e,c){return b(arguments,
d(3),3,2,0)};a.Vector={};a.Vector.dot=function(e,c){return e.map(function(g,l){return g*c[l]}).reduce(function(g,l){return g+l})};a.Vector.lengthSquared=function(e){return a.Vector.dot(e,e)};a.Vector.length=function(e){return Math.sqrt(a.Vector.lengthSquared(e))};a.Vector.mulScalar=function(e,c){return e.map(function(g){return g*c})};a.Vector.add=function(e,c){return e.map(function(g,l){return g+c[l]})};a.Vector.sub=function(e,c){return e.map(function(g,l){return g-c[l]})};a.Vector.dist=function(e,
c){return a.Vector.length(a.Vector.sub(e,c))};a.Vector.normalize=function(e){return a.Vector.mulScalar(e,1/a.Vector.length(e))};a.Vector.cross=function(e,c){return[e[1]*c[2]-e[2]*c[1],e[2]*c[0]-e[0]*c[2],e[0]*c[1]-e[1]*c[0]]};a.M44={};a.M44.identity=function(){return d(4)};a.M44.translated=function(e){return b(e,d(4),4,3,0)};a.M44.scaled=function(e){return b(e,d(4),4,0,1)};a.M44.rotated=function(e,c){return a.M44.rotatedUnitSinCos(a.Vector.normalize(e),Math.sin(c),Math.cos(c))};a.M44.rotatedUnitSinCos=
function(e,c,g){var l=e[0],t=e[1];e=e[2];var x=1-g;return[x*l*l+g,x*l*t-c*e,x*l*e+c*t,0,x*l*t+c*e,x*t*t+g,x*t*e-c*l,0,x*l*e-c*t,x*t*e+c*l,x*e*e+g,0,0,0,0,1]};a.M44.lookat=function(e,c,g){c=a.Vector.normalize(a.Vector.sub(c,e));g=a.Vector.normalize(g);g=a.Vector.normalize(a.Vector.cross(c,g));var l=a.M44.identity();b(g,l,4,0,0);b(a.Vector.cross(g,c),l,4,1,0);b(a.Vector.mulScalar(c,-1),l,4,2,0);b(e,l,4,3,0);e=a.M44.invert(l);return null===e?a.M44.identity():e};a.M44.perspective=function(e,c,g){var l=
1/(c-e);g/=2;g=Math.cos(g)/Math.sin(g);return[g,0,0,0,0,g,0,0,0,0,(c+e)*l,2*c*e*l,0,0,-1,1]};a.M44.rc=function(e,c,g){return e[4*c+g]};a.M44.multiply=function(){return ib(4,arguments)};a.M44.invert=function(e){var c=e[0],g=e[4],l=e[8],t=e[12],x=e[1],B=e[5],J=e[9],P=e[13],O=e[2],Y=e[6],W=e[10],r=e[14],D=e[3],U=e[7],da=e[11];e=e[15];var ka=c*B-g*x,wa=c*J-l*x,xa=c*P-t*x,na=g*J-l*B,G=g*P-t*B,k=l*P-t*J,q=O*U-Y*D,z=O*da-W*D,C=O*e-r*D,F=Y*da-W*U,I=Y*e-r*U,M=W*e-r*da,ea=ka*M-wa*I+xa*F+na*C-G*z+k*q,fa=1/ea;
if(0===ea||Infinity===fa)return null;ka*=fa;wa*=fa;xa*=fa;na*=fa;G*=fa;k*=fa;q*=fa;z*=fa;C*=fa;F*=fa;I*=fa;M*=fa;c=[B*M-J*I+P*F,J*C-x*M-P*z,x*I-B*C+P*q,B*z-x*F-J*q,l*I-g*M-t*F,c*M-l*C+t*z,g*C-c*I-t*q,c*F-g*z+l*q,U*k-da*G+e*na,da*xa-D*k-e*wa,D*G-U*xa+e*ka,U*wa-D*na-da*ka,W*G-Y*k-r*na,O*k-W*xa+r*wa,Y*xa-O*G-r*ka,O*na-Y*wa+W*ka];return c.every(function(Ja){return!isNaN(Ja)&&Infinity!==Ja&&-Infinity!==Ja})?c:null};a.M44.transpose=function(e){return[e[0],e[4],e[8],e[12],e[1],e[5],e[9],e[13],e[2],e[6],
e[10],e[14],e[3],e[7],e[11],e[15]]};a.M44.mustInvert=function(e){e=a.M44.invert(e);if(null===e)throw"Matrix not invertible";return e};a.M44.setupCamera=function(e,c,g){var l=a.M44.lookat(g.eye,g.coa,g.up);g=a.M44.perspective(g.near,g.far,g.angle);c=[(e[2]-e[0])/2,(e[3]-e[1])/2,c];e=a.M44.multiply(a.M44.translated([(e[0]+e[2])/2,(e[1]+e[3])/2,0]),a.M44.scaled(c));return a.M44.multiply(e,g,l,a.M44.mustInvert(e))};a.ColorMatrix={};a.ColorMatrix.identity=function(){var e=new Float32Array(20);e[0]=1;e[6]=
1;e[12]=1;e[18]=1;return e};a.ColorMatrix.scaled=function(e,c,g,l){var t=new Float32Array(20);t[0]=e;t[6]=c;t[12]=g;t[18]=l;return t};var xd=[[6,7,11,12],[0,10,2,12],[0,1,5,6]];a.ColorMatrix.rotated=function(e,c,g){var l=a.ColorMatrix.identity();e=xd[e];l[e[0]]=g;l[e[1]]=c;l[e[2]]=-c;l[e[3]]=g;return l};a.ColorMatrix.postTranslate=function(e,c,g,l,t){e[4]+=c;e[9]+=g;e[14]+=l;e[19]+=t;return e};a.ColorMatrix.concat=function(e,c){for(var g=new Float32Array(20),l=0,t=0;20>t;t+=5){for(var x=0;4>x;x++)g[l++]=
e[t]*c[x]+e[t+1]*c[x+5]+e[t+2]*c[x+10]+e[t+3]*c[x+15];g[l++]=e[t]*c[4]+e[t+1]*c[9]+e[t+2]*c[14]+e[t+3]*c[19]+e[t+4]}return g};(function(e){e.Td=e.Td||[];e.Td.push(function(){function c(r){r&&(r.dir=0===r.dir?e.TextDirection.RTL:e.TextDirection.LTR);return r}function g(r){if(!r||!r.length)return[];for(var D=[],U=0;U<r.length;U+=5){var da=e.LTRBRect(r[U],r[U+1],r[U+2],r[U+3]),ka=e.TextDirection.LTR;0===r[U+4]&&(ka=e.TextDirection.RTL);D.push({rect:da,dir:ka})}e._free(r.byteOffset);return D}function l(r){r=
r||{};void 0===r.weight&&(r.weight=e.FontWeight.Normal);r.width=r.width||e.FontWidth.Normal;r.slant=r.slant||e.FontSlant.Upright;return r}function t(r){if(!r||!r.length)return V;for(var D=[],U=0;U<r.length;U++){var da=x(r[U]);D.push(da)}return v(D,"HEAPU32")}function x(r){if(P[r])return P[r];var D=pa(r)+1,U=e._malloc(D);qa(r,K,U,D);return P[r]=U}function B(r){r._colorPtr=y(r.color);r._foregroundColorPtr=V;r._backgroundColorPtr=V;r._decorationColorPtr=V;r.foregroundColor&&(r._foregroundColorPtr=y(r.foregroundColor,
O));r.backgroundColor&&(r._backgroundColorPtr=y(r.backgroundColor,Y));r.decorationColor&&(r._decorationColorPtr=y(r.decorationColor,W));Array.isArray(r.fontFamilies)&&r.fontFamilies.length?(r._fontFamiliesPtr=t(r.fontFamilies),r._fontFamiliesLen=r.fontFamilies.length):(r._fontFamiliesPtr=V,r._fontFamiliesLen=0);if(r.locale){var D=r.locale;r._localePtr=x(D);r._localeLen=pa(D)+1}else r._localePtr=V,r._localeLen=0;if(Array.isArray(r.shadows)&&r.shadows.length){D=r.shadows;var U=D.map(function(G){return G.color||
e.BLACK}),da=D.map(function(G){return G.blurRadius||0});r._shadowLen=D.length;for(var ka=e._malloc(8*D.length),wa=ka/4,xa=0;xa<D.length;xa++){var na=D[xa].offset||[0,0];e.HEAPF32[wa]=na[0];e.HEAPF32[wa+1]=na[1];wa+=2}r._shadowColorsPtr=E(U).ce;r._shadowOffsetsPtr=ka;r._shadowBlurRadiiPtr=v(da,"HEAPF32")}else r._shadowLen=0,r._shadowColorsPtr=V,r._shadowOffsetsPtr=V,r._shadowBlurRadiiPtr=V;Array.isArray(r.fontFeatures)&&r.fontFeatures.length?(D=r.fontFeatures,U=D.map(function(G){return G.name}),da=
D.map(function(G){return G.value}),r._fontFeatureLen=D.length,r._fontFeatureNamesPtr=t(U),r._fontFeatureValuesPtr=v(da,"HEAPU32")):(r._fontFeatureLen=0,r._fontFeatureNamesPtr=V,r._fontFeatureValuesPtr=V);Array.isArray(r.fontVariations)&&r.fontVariations.length?(D=r.fontVariations,U=D.map(function(G){return G.axis}),da=D.map(function(G){return G.value}),r._fontVariationLen=D.length,r._fontVariationAxesPtr=t(U),r._fontVariationValuesPtr=v(da,"HEAPF32")):(r._fontVariationLen=0,r._fontVariationAxesPtr=
V,r._fontVariationValuesPtr=V)}function J(r){e._free(r._fontFamiliesPtr);e._free(r._shadowColorsPtr);e._free(r._shadowOffsetsPtr);e._free(r._shadowBlurRadiiPtr);e._free(r._fontFeatureNamesPtr);e._free(r._fontFeatureValuesPtr);e._free(r._fontVariationAxesPtr);e._free(r._fontVariationValuesPtr)}e.Paragraph.prototype.getRectsForRange=function(r,D,U,da){r=this._getRectsForRange(r,D,U,da);return g(r)};e.Paragraph.prototype.getRectsForPlaceholders=function(){var r=this._getRectsForPlaceholders();return g(r)};
e.Paragraph.prototype.getGlyphInfoAt=function(r){return c(this._getGlyphInfoAt(r))};e.Paragraph.prototype.getClosestGlyphInfoAtCoordinate=function(r,D){return c(this._getClosestGlyphInfoAtCoordinate(r,D))};e.TypefaceFontProvider.prototype.registerFont=function(r,D){r=e.Typeface.MakeFreeTypeFaceFromData(r);if(!r)return null;D=x(D);this._registerFont(r,D)};e.ParagraphStyle=function(r){r.disableHinting=r.disableHinting||!1;if(r.ellipsis){var D=r.ellipsis;r._ellipsisPtr=x(D);r._ellipsisLen=pa(D)+1}else r._ellipsisPtr=
V,r._ellipsisLen=0;null==r.heightMultiplier&&(r.heightMultiplier=-1);r.maxLines=r.maxLines||0;r.replaceTabCharacters=r.replaceTabCharacters||!1;D=(D=r.strutStyle)||{};D.strutEnabled=D.strutEnabled||!1;D.strutEnabled&&Array.isArray(D.fontFamilies)&&D.fontFamilies.length?(D._fontFamiliesPtr=t(D.fontFamilies),D._fontFamiliesLen=D.fontFamilies.length):(D._fontFamiliesPtr=V,D._fontFamiliesLen=0);D.fontStyle=l(D.fontStyle);null==D.fontSize&&(D.fontSize=-1);null==D.heightMultiplier&&(D.heightMultiplier=
-1);D.halfLeading=D.halfLeading||!1;D.leading=D.leading||0;D.forceStrutHeight=D.forceStrutHeight||!1;r.strutStyle=D;r.textAlign=r.textAlign||e.TextAlign.Start;r.textDirection=r.textDirection||e.TextDirection.LTR;r.textHeightBehavior=r.textHeightBehavior||e.TextHeightBehavior.All;r.textStyle=e.TextStyle(r.textStyle);r.applyRoundingHack=!1!==r.applyRoundingHack;return r};e.TextStyle=function(r){r.color||(r.color=e.BLACK);r.decoration=r.decoration||0;r.decorationThickness=r.decorationThickness||0;r.decorationStyle=
r.decorationStyle||e.DecorationStyle.Solid;r.textBaseline=r.textBaseline||e.TextBaseline.Alphabetic;null==r.fontSize&&(r.fontSize=-1);r.letterSpacing=r.letterSpacing||0;r.wordSpacing=r.wordSpacing||0;null==r.heightMultiplier&&(r.heightMultiplier=-1);r.halfLeading=r.halfLeading||!1;r.fontStyle=l(r.fontStyle);return r};var P={},O=e._malloc(16),Y=e._malloc(16),W=e._malloc(16);e.ParagraphBuilder.Make=function(r,D){B(r.textStyle);D=e.ParagraphBuilder._Make(r,D);J(r.textStyle);return D};e.ParagraphBuilder.MakeFromFontProvider=
function(r,D){B(r.textStyle);D=e.ParagraphBuilder._MakeFromFontProvider(r,D);J(r.textStyle);return D};e.ParagraphBuilder.MakeFromFontCollection=function(r,D){B(r.textStyle);D=e.ParagraphBuilder._MakeFromFontCollection(r,D);J(r.textStyle);return D};e.ParagraphBuilder.ShapeText=function(r,D,U){let da=0;for(const ka of D)da+=ka.length;if(da!==r.length)throw"Accumulated block lengths must equal text.length";return e.ParagraphBuilder._ShapeText(r,D,U)};e.ParagraphBuilder.prototype.pushStyle=function(r){B(r);
this._pushStyle(r);J(r)};e.ParagraphBuilder.prototype.pushPaintStyle=function(r,D,U){B(r);this._pushPaintStyle(r,D,U);J(r)};e.ParagraphBuilder.prototype.addPlaceholder=function(r,D,U,da,ka){U=U||e.PlaceholderAlignment.Baseline;da=da||e.TextBaseline.Alphabetic;this._addPlaceholder(r||0,D||0,U,da,ka||0)};e.ParagraphBuilder.prototype.setWordsUtf8=function(r){var D=v(r,"HEAPU32");this._setWordsUtf8(D,r&&r.length||0);p(D,r)};e.ParagraphBuilder.prototype.setWordsUtf16=function(r){var D=v(r,"HEAPU32");this._setWordsUtf16(D,
r&&r.length||0);p(D,r)};e.ParagraphBuilder.prototype.setGraphemeBreaksUtf8=function(r){var D=v(r,"HEAPU32");this._setGraphemeBreaksUtf8(D,r&&r.length||0);p(D,r)};e.ParagraphBuilder.prototype.setGraphemeBreaksUtf16=function(r){var D=v(r,"HEAPU32");this._setGraphemeBreaksUtf16(D,r&&r.length||0);p(D,r)};e.ParagraphBuilder.prototype.setLineBreaksUtf8=function(r){var D=v(r,"HEAPU32");this._setLineBreaksUtf8(D,r&&r.length||0);p(D,r)};e.ParagraphBuilder.prototype.setLineBreaksUtf16=function(r){var D=v(r,
"HEAPU32");this._setLineBreaksUtf16(D,r&&r.length||0);p(D,r)}})})(w);a.MakeManagedAnimation=function(e,c,g,l,t){if(!a._MakeManagedAnimation)throw"Not compiled with MakeManagedAnimation";g||(g="");if(!c)return a._MakeManagedAnimation(e,0,V,V,V,g,l,t);for(var x=[],B=[],J=[],P=Object.keys(c||{}),O=0;O<P.length;O++){var Y=P[O],W=new Uint8Array(c[Y]),r=a._malloc(W.byteLength);a.HEAPU8.set(W,r);B.push(r);J.push(W.byteLength);W=pa(Y)+1;r=a._malloc(W);qa(Y,K,r,W);x.push(r)}c=v(x,"HEAPU32");B=v(B,"HEAPU32");
J=v(J,"HEAPU32");e=a._MakeManagedAnimation(e,P.length,c,B,J,g,l,t);a._free(c);a._free(B);a._free(J);return e};a.tg=function(e){e.text=e.text||"";e.textSize=e.textSize||0;e.minTextSize=e.minTextSize||0;e.maxTextSize=e.maxTextSize||Number.MAX_VALUE;e.strokeWidth=e.strokeWidth||0;e.lineHeight=e.lineHeight||0;e.lineShift=e.lineShift||0;e.ascent=e.ascent||0;e.maxLines=e.maxLines||0;e.horizAlign=e.horizAlign||a.TextAlign.Left;e.vertAlign=e.vertAlign||a.ug.Top;e.strokeJoin=e.strokeJoin||a.StrokeJoin.Miter;
e.direction=e.direction||a.TextDirection.LTR;e.linebreak=e.linebreak||a.LineBreakType.HardLineBreak;e.resize=e.resize||a.sg.None;e.fillColor||(e.fillColor=a.TRANSPARENT);e.strokeColor||(e.strokeColor=a.TRANSPARENT);e.boundingBox||(e.boundingBox=[0,0,0,0]);return e};(function(e){e.Td=e.Td||[];e.Td.push(function(){e.Animation.prototype.render=function(c,g){R(g,ca);this._render(c,ca)};e.Animation.prototype.size=function(c){this._size(ca);var g=sa.toTypedArray();return c?(c[0]=g[0],c[1]=g[1],c):g.slice(0,
2)};e.ManagedAnimation&&(e.ManagedAnimation.prototype.render=function(c,g){R(g,ca);this._render(c,ca)},e.ManagedAnimation.prototype.seek=function(c,g){this._seek(c,ca);c=sa.toTypedArray();return g?(g.set(c),g):c.slice()},e.ManagedAnimation.prototype.seekFrame=function(c,g){this._seekFrame(c,ca);c=sa.toTypedArray();return g?(g.set(c),g):c.slice()},e.ManagedAnimation.prototype.setColor=function(c,g){g=y(g);return this._setColor(c,g)},e.ManagedAnimation.prototype.setColorSlot=function(c,g){g=y(g);return this._setColorSlot(c,
g)},e.ManagedAnimation.prototype.getColorSlot=function(c){this._getColorSlot(c,Ia);c=T(Ia);return-1==c[0]?null:c},e.ManagedAnimation.prototype.setVec2Slot=function(c,g){v(g,"HEAPF32",ab);return this._setVec2Slot(c,ab)},e.ManagedAnimation.prototype.getVec2Slot=function(c){this._getVec2Slot(c,ab);c=$b.toTypedArray();return-1===c[2]?null:c.slice(0,2)},e.ManagedAnimation.prototype.setTextSlot=function(c,g){var l=y(g.fillColor,Ia),t=y(g.strokeColor,ca),x=R(g.boundingBox,Oa);g._fillColorPtr=l;g._strokeColorPtr=
t;g._boundingBoxPtr=x;return this._setTextSlot(c,g)},e.ManagedAnimation.prototype.setTransform=function(c,g,l,t,x,B,J){g=v([g[0],g[1],l[0],l[1],t[0],t[1],x,B,J],"HEAPF32",Na);return this._setTransform(c,g)},e.ManagedAnimation.prototype.size=function(c){this._size(ca);var g=sa.toTypedArray();return c?(c[0]=g[0],c[1]=g[1],c):g.slice(0,2)})})})(w);a.Td=a.Td||[];a.Td.push(function(){a.Path.prototype.op=function(e,c){return this._op(e,c)?this:null};a.Path.prototype.simplify=function(){return this._simplify()?
this:null}});a.Td=a.Td||[];a.Td.push(function(){a.Canvas.prototype.drawText=function(e,c,g,l,t){var x=pa(e),B=a._malloc(x+1);qa(e,K,B,x+1);this._drawSimpleText(B,x,c,g,t,l);a._free(B)};a.Canvas.prototype.drawGlyphs=function(e,c,g,l,t,x){if(!(2*e.length<=c.length))throw"Not enough positions for the array of gyphs";a.Nd(this.Ld);const B=v(e,"HEAPU16"),J=v(c,"HEAPF32");this._drawGlyphs(e.length,B,J,g,l,t,x);p(J,c);p(B,e)};a.Font.prototype.getGlyphBounds=function(e,c,g){var l=v(e,"HEAPU16"),t=a._malloc(16*
e.length);this._getGlyphWidthBounds(l,e.length,V,t,c||null);c=new Float32Array(a.HEAPU8.buffer,t,4*e.length);p(l,e);if(g)return g.set(c),a._free(t),g;e=Float32Array.from(c);a._free(t);return e};a.Font.prototype.getGlyphIDs=function(e,c,g){c||(c=e.length);var l=pa(e)+1,t=a._malloc(l);qa(e,K,t,l);e=a._malloc(2*c);c=this._getGlyphIDs(t,l-1,c,e);a._free(t);if(0>c)return a._free(e),null;t=new Uint16Array(a.HEAPU8.buffer,e,c);if(g)return g.set(t),a._free(e),g;g=Uint16Array.from(t);a._free(e);return g};
a.Font.prototype.getGlyphIntercepts=function(e,c,g,l){var t=v(e,"HEAPU16"),x=v(c,"HEAPF32");return this._getGlyphIntercepts(t,e.length,!(e&&e._ck),x,c.length,!(c&&c._ck),g,l)};a.Font.prototype.getGlyphWidths=function(e,c,g){var l=v(e,"HEAPU16"),t=a._malloc(4*e.length);this._getGlyphWidthBounds(l,e.length,t,V,c||null);c=new Float32Array(a.HEAPU8.buffer,t,e.length);p(l,e);if(g)return g.set(c),a._free(t),g;e=Float32Array.from(c);a._free(t);return e};a.FontMgr.FromData=function(){if(!arguments.length)return null;
var e=arguments;1===e.length&&Array.isArray(e[0])&&(e=arguments[0]);if(!e.length)return null;for(var c=[],g=[],l=0;l<e.length;l++){var t=new Uint8Array(e[l]),x=v(t,"HEAPU8");c.push(x);g.push(t.byteLength)}c=v(c,"HEAPU32");g=v(g,"HEAPU32");e=a.FontMgr._fromData(c,g,e.length);a._free(c);a._free(g);return e};a.Typeface.MakeFreeTypeFaceFromData=function(e){e=new Uint8Array(e);var c=v(e,"HEAPU8");return(e=a.Typeface._MakeFreeTypeFaceFromData(c,e.byteLength))?e:null};a.Typeface.prototype.getGlyphIDs=function(e,
c,g){c||(c=e.length);var l=pa(e)+1,t=a._malloc(l);qa(e,K,t,l);e=a._malloc(2*c);c=this._getGlyphIDs(t,l-1,c,e);a._free(t);if(0>c)return a._free(e),null;t=new Uint16Array(a.HEAPU8.buffer,e,c);if(g)return g.set(t),a._free(e),g;g=Uint16Array.from(t);a._free(e);return g};a.TextBlob.MakeOnPath=function(e,c,g,l){if(e&&e.length&&c&&c.countPoints()){if(1===c.countPoints())return this.MakeFromText(e,g);l||(l=0);var t=g.getGlyphIDs(e);t=g.getGlyphWidths(t);var x=[];c=new a.ContourMeasureIter(c,!1,1);for(var B=
c.next(),J=new Float32Array(4),P=0;P<e.length&&B;P++){var O=t[P];l+=O/2;if(l>B.length()){B.delete();B=c.next();if(!B){e=e.substring(0,P);break}l=O/2}B.getPosTan(l,J);var Y=J[2],W=J[3];x.push(Y,W,J[0]-O/2*Y,J[1]-O/2*W);l+=O/2}e=this.MakeFromRSXform(e,x,g);B&&B.delete();c.delete();return e}};a.TextBlob.MakeFromRSXform=function(e,c,g){var l=pa(e)+1,t=a._malloc(l);qa(e,K,t,l);e=v(c,"HEAPF32");g=a.TextBlob._MakeFromRSXform(t,l-1,e,g);a._free(t);return g?g:null};a.TextBlob.MakeFromRSXformGlyphs=function(e,
c,g){var l=v(e,"HEAPU16");c=v(c,"HEAPF32");g=a.TextBlob._MakeFromRSXformGlyphs(l,2*e.length,c,g);p(l,e);return g?g:null};a.TextBlob.MakeFromGlyphs=function(e,c){var g=v(e,"HEAPU16");c=a.TextBlob._MakeFromGlyphs(g,2*e.length,c);p(g,e);return c?c:null};a.TextBlob.MakeFromText=function(e,c){var g=pa(e)+1,l=a._malloc(g);qa(e,K,l,g);e=a.TextBlob._MakeFromText(l,g-1,c);a._free(l);return e?e:null};a.MallocGlyphIDs=function(e){return a.Malloc(Uint16Array,e)}});a.Td=a.Td||[];a.Td.push(function(){a.MakePicture=
function(e){e=new Uint8Array(e);var c=a._malloc(e.byteLength);a.HEAPU8.set(e,c);return(e=a._MakePicture(c,e.byteLength))?e:null}});a.Td=a.Td||[];a.Td.push(function(){a.RuntimeEffect.Make=function(e,c){return a.RuntimeEffect._Make(e,{onError:c||function(g){console.log("RuntimeEffect error",g)}})};a.RuntimeEffect.MakeForBlender=function(e,c){return a.RuntimeEffect._MakeForBlender(e,{onError:c||function(g){console.log("RuntimeEffect error",g)}})};a.RuntimeEffect.prototype.makeShader=function(e,c){var g=
!e._ck,l=v(e,"HEAPF32");c=H(c);return this._makeShader(l,4*e.length,g,c)};a.RuntimeEffect.prototype.makeShaderWithChildren=function(e,c,g){var l=!e._ck,t=v(e,"HEAPF32");g=H(g);for(var x=[],B=0;B<c.length;B++)x.push(c[B].Kd.Sd);c=v(x,"HEAPU32");return this._makeShaderWithChildren(t,4*e.length,l,c,x.length,g)};a.RuntimeEffect.prototype.makeBlender=function(e){var c=!e._ck,g=v(e,"HEAPF32");return this._makeBlender(g,4*e.length,c)}});(function(){function e(G){for(var k=0;k<G.length;k++)if(void 0!==G[k]&&
!Number.isFinite(G[k]))return!1;return!0}function c(G){var k=a.getColorComponents(G);G=k[0];var q=k[1],z=k[2];k=k[3];if(1===k)return G=G.toString(16).toLowerCase(),q=q.toString(16).toLowerCase(),z=z.toString(16).toLowerCase(),G=1===G.length?"0"+G:G,q=1===q.length?"0"+q:q,z=1===z.length?"0"+z:z,"#"+G+q+z;k=0===k||1===k?k:k.toFixed(8);return"rgba("+G+", "+q+", "+z+", "+k+")"}function g(G){return a.parseColorString(G,wa)}function l(G){G=xa.exec(G);if(!G)return null;var k=parseFloat(G[4]),q=16;switch(G[5]){case "em":case "rem":q=
16*k;break;case "pt":q=4*k/3;break;case "px":q=k;break;case "pc":q=16*k;break;case "in":q=96*k;break;case "cm":q=96*k/2.54;break;case "mm":q=96/25.4*k;break;case "q":q=96/25.4/4*k;break;case "%":q=16/75*k}return{style:G[1],variant:G[2],weight:G[3],sizePx:q,family:G[6].trim()}}function t(G){this.Md=G;this.Pd=new a.Paint;this.Pd.setAntiAlias(!0);this.Pd.setStrokeMiter(10);this.Pd.setStrokeCap(a.StrokeCap.Butt);this.Pd.setStrokeJoin(a.StrokeJoin.Miter);this.Se="10px monospace";this.ne=new a.Font(null,
10);this.ne.setSubpixel(!0);this.be=this.he=a.BLACK;this.we=0;this.Ke=a.TRANSPARENT;this.ye=this.xe=0;this.Le=this.je=1;this.Je=0;this.ve=[];this.Od=a.BlendMode.SrcOver;this.Pd.setStrokeWidth(this.Le);this.Pd.setBlendMode(this.Od);this.Rd=new a.Path;this.Ud=a.Matrix.identity();this.mf=[];this.Ce=[];this.me=function(){this.Rd.delete();this.Pd.delete();this.ne.delete();this.Ce.forEach(function(k){k.me()})};Object.defineProperty(this,"currentTransform",{enumerable:!0,get:function(){return{a:this.Ud[0],
c:this.Ud[1],e:this.Ud[2],b:this.Ud[3],d:this.Ud[4],f:this.Ud[5]}},set:function(k){k.a&&this.setTransform(k.a,k.b,k.c,k.d,k.e,k.f)}});Object.defineProperty(this,"fillStyle",{enumerable:!0,get:function(){return f(this.be)?c(this.be):this.be},set:function(k){"string"===typeof k?this.be=g(k):k.ue&&(this.be=k)}});Object.defineProperty(this,"font",{enumerable:!0,get:function(){return this.Se},set:function(k){var q=l(k),z=q.family;q.typeface=na[z]?na[z][(q.style||"normal")+"|"+(q.variant||"normal")+"|"+
(q.weight||"normal")]||na[z]["*"]:null;q&&(this.ne.setSize(q.sizePx),this.ne.setTypeface(q.typeface),this.Se=k)}});Object.defineProperty(this,"globalAlpha",{enumerable:!0,get:function(){return this.je},set:function(k){!isFinite(k)||0>k||1<k||(this.je=k)}});Object.defineProperty(this,"globalCompositeOperation",{enumerable:!0,get:function(){switch(this.Od){case a.BlendMode.SrcOver:return"source-over";case a.BlendMode.DstOver:return"destination-over";case a.BlendMode.Src:return"copy";case a.BlendMode.Dst:return"destination";
case a.BlendMode.Clear:return"clear";case a.BlendMode.SrcIn:return"source-in";case a.BlendMode.DstIn:return"destination-in";case a.BlendMode.SrcOut:return"source-out";case a.BlendMode.DstOut:return"destination-out";case a.BlendMode.SrcATop:return"source-atop";case a.BlendMode.DstATop:return"destination-atop";case a.BlendMode.Xor:return"xor";case a.BlendMode.Plus:return"lighter";case a.BlendMode.Multiply:return"multiply";case a.BlendMode.Screen:return"screen";case a.BlendMode.Overlay:return"overlay";
case a.BlendMode.Darken:return"darken";case a.BlendMode.Lighten:return"lighten";case a.BlendMode.ColorDodge:return"color-dodge";case a.BlendMode.ColorBurn:return"color-burn";case a.BlendMode.HardLight:return"hard-light";case a.BlendMode.SoftLight:return"soft-light";case a.BlendMode.Difference:return"difference";case a.BlendMode.Exclusion:return"exclusion";case a.BlendMode.Hue:return"hue";case a.BlendMode.Saturation:return"saturation";case a.BlendMode.Color:return"color";case a.BlendMode.Luminosity:return"luminosity"}},
set:function(k){switch(k){case "source-over":this.Od=a.BlendMode.SrcOver;break;case "destination-over":this.Od=a.BlendMode.DstOver;break;case "copy":this.Od=a.BlendMode.Src;break;case "destination":this.Od=a.BlendMode.Dst;break;case "clear":this.Od=a.BlendMode.Clear;break;case "source-in":this.Od=a.BlendMode.SrcIn;break;case "destination-in":this.Od=a.BlendMode.DstIn;break;case "source-out":this.Od=a.BlendMode.SrcOut;break;case "destination-out":this.Od=a.BlendMode.DstOut;break;case "source-atop":this.Od=
a.BlendMode.SrcATop;break;case "destination-atop":this.Od=a.BlendMode.DstATop;break;case "xor":this.Od=a.BlendMode.Xor;break;case "lighter":this.Od=a.BlendMode.Plus;break;case "plus-lighter":this.Od=a.BlendMode.Plus;break;case "plus-darker":throw"plus-darker is not supported";case "multiply":this.Od=a.BlendMode.Multiply;break;case "screen":this.Od=a.BlendMode.Screen;break;case "overlay":this.Od=a.BlendMode.Overlay;break;case "darken":this.Od=a.BlendMode.Darken;break;case "lighten":this.Od=a.BlendMode.Lighten;
break;case "color-dodge":this.Od=a.BlendMode.ColorDodge;break;case "color-burn":this.Od=a.BlendMode.ColorBurn;break;case "hard-light":this.Od=a.BlendMode.HardLight;break;case "soft-light":this.Od=a.BlendMode.SoftLight;break;case "difference":this.Od=a.BlendMode.Difference;break;case "exclusion":this.Od=a.BlendMode.Exclusion;break;case "hue":this.Od=a.BlendMode.Hue;break;case "saturation":this.Od=a.BlendMode.Saturation;break;case "color":this.Od=a.BlendMode.Color;break;case "luminosity":this.Od=a.BlendMode.Luminosity;
break;default:return}this.Pd.setBlendMode(this.Od)}});Object.defineProperty(this,"imageSmoothingEnabled",{enumerable:!0,get:function(){return!0},set:function(){}});Object.defineProperty(this,"imageSmoothingQuality",{enumerable:!0,get:function(){return"high"},set:function(){}});Object.defineProperty(this,"lineCap",{enumerable:!0,get:function(){switch(this.Pd.getStrokeCap()){case a.StrokeCap.Butt:return"butt";case a.StrokeCap.Round:return"round";case a.StrokeCap.Square:return"square"}},set:function(k){switch(k){case "butt":this.Pd.setStrokeCap(a.StrokeCap.Butt);
break;case "round":this.Pd.setStrokeCap(a.StrokeCap.Round);break;case "square":this.Pd.setStrokeCap(a.StrokeCap.Square)}}});Object.defineProperty(this,"lineDashOffset",{enumerable:!0,get:function(){return this.Je},set:function(k){isFinite(k)&&(this.Je=k)}});Object.defineProperty(this,"lineJoin",{enumerable:!0,get:function(){switch(this.Pd.getStrokeJoin()){case a.StrokeJoin.Miter:return"miter";case a.StrokeJoin.Round:return"round";case a.StrokeJoin.Bevel:return"bevel"}},set:function(k){switch(k){case "miter":this.Pd.setStrokeJoin(a.StrokeJoin.Miter);
break;case "round":this.Pd.setStrokeJoin(a.StrokeJoin.Round);break;case "bevel":this.Pd.setStrokeJoin(a.StrokeJoin.Bevel)}}});Object.defineProperty(this,"lineWidth",{enumerable:!0,get:function(){return this.Pd.getStrokeWidth()},set:function(k){0>=k||!k||(this.Le=k,this.Pd.setStrokeWidth(k))}});Object.defineProperty(this,"miterLimit",{enumerable:!0,get:function(){return this.Pd.getStrokeMiter()},set:function(k){0>=k||!k||this.Pd.setStrokeMiter(k)}});Object.defineProperty(this,"shadowBlur",{enumerable:!0,
get:function(){return this.we},set:function(k){0>k||!isFinite(k)||(this.we=k)}});Object.defineProperty(this,"shadowColor",{enumerable:!0,get:function(){return c(this.Ke)},set:function(k){this.Ke=g(k)}});Object.defineProperty(this,"shadowOffsetX",{enumerable:!0,get:function(){return this.xe},set:function(k){isFinite(k)&&(this.xe=k)}});Object.defineProperty(this,"shadowOffsetY",{enumerable:!0,get:function(){return this.ye},set:function(k){isFinite(k)&&(this.ye=k)}});Object.defineProperty(this,"strokeStyle",
{enumerable:!0,get:function(){return c(this.he)},set:function(k){"string"===typeof k?this.he=g(k):k.ue&&(this.he=k)}});this.arc=function(k,q,z,C,F,I){r(this.Rd,k,q,z,z,0,C,F,I)};this.arcTo=function(k,q,z,C,F){O(this.Rd,k,q,z,C,F)};this.beginPath=function(){this.Rd.delete();this.Rd=new a.Path};this.bezierCurveTo=function(k,q,z,C,F,I){var M=this.Rd;e([k,q,z,C,F,I])&&(M.isEmpty()&&M.moveTo(k,q),M.cubicTo(k,q,z,C,F,I))};this.clearRect=function(k,q,z,C){this.Pd.setStyle(a.PaintStyle.Fill);this.Pd.setBlendMode(a.BlendMode.Clear);
this.Md.drawRect(a.XYWHRect(k,q,z,C),this.Pd);this.Pd.setBlendMode(this.Od)};this.clip=function(k,q){"string"===typeof k?(q=k,k=this.Rd):k&&k.bf&&(k=k.Vd);k||(k=this.Rd);k=k.copy();q&&"evenodd"===q.toLowerCase()?k.setFillType(a.FillType.EvenOdd):k.setFillType(a.FillType.Winding);this.Md.clipPath(k,a.ClipOp.Intersect,!0);k.delete()};this.closePath=function(){Y(this.Rd)};this.createImageData=function(){if(1===arguments.length){var k=arguments[0];return new J(new Uint8ClampedArray(4*k.width*k.height),
k.width,k.height)}if(2===arguments.length){k=arguments[0];var q=arguments[1];return new J(new Uint8ClampedArray(4*k*q),k,q)}throw"createImageData expects 1 or 2 arguments, got "+arguments.length;};this.createLinearGradient=function(k,q,z,C){if(e(arguments)){var F=new P(k,q,z,C);this.Ce.push(F);return F}};this.createPattern=function(k,q){k=new da(k,q);this.Ce.push(k);return k};this.createRadialGradient=function(k,q,z,C,F,I){if(e(arguments)){var M=new ka(k,q,z,C,F,I);this.Ce.push(M);return M}};this.drawImage=
function(k){k instanceof B&&(k=k.uf());var q=this.Re();if(3===arguments.length||5===arguments.length)var z=a.XYWHRect(arguments[1],arguments[2],arguments[3]||k.width(),arguments[4]||k.height()),C=a.XYWHRect(0,0,k.width(),k.height());else if(9===arguments.length)z=a.XYWHRect(arguments[5],arguments[6],arguments[7],arguments[8]),C=a.XYWHRect(arguments[1],arguments[2],arguments[3],arguments[4]);else throw"invalid number of args for drawImage, need 3, 5, or 9; got "+arguments.length;this.Md.drawImageRect(k,
C,z,q,!1);q.dispose()};this.ellipse=function(k,q,z,C,F,I,M,ea){r(this.Rd,k,q,z,C,F,I,M,ea)};this.Re=function(){var k=this.Pd.copy();k.setStyle(a.PaintStyle.Fill);if(f(this.be)){var q=a.multiplyByAlpha(this.be,this.je);k.setColor(q)}else q=this.be.ue(this.Ud),k.setColor(a.Color(0,0,0,this.je)),k.setShader(q);k.dispose=function(){this.delete()};return k};this.fill=function(k,q){"string"===typeof k?(q=k,k=this.Rd):k&&k.bf&&(k=k.Vd);if("evenodd"===q)this.Rd.setFillType(a.FillType.EvenOdd);else{if("nonzero"!==
q&&q)throw"invalid fill rule";this.Rd.setFillType(a.FillType.Winding)}k||(k=this.Rd);q=this.Re();var z=this.ze(q);z&&(this.Md.save(),this.se(),this.Md.drawPath(k,z),this.Md.restore(),z.dispose());this.Md.drawPath(k,q);q.dispose()};this.fillRect=function(k,q,z,C){var F=this.Re(),I=this.ze(F);I&&(this.Md.save(),this.se(),this.Md.drawRect(a.XYWHRect(k,q,z,C),I),this.Md.restore(),I.dispose());this.Md.drawRect(a.XYWHRect(k,q,z,C),F);F.dispose()};this.fillText=function(k,q,z){var C=this.Re();k=a.TextBlob.MakeFromText(k,
this.ne);var F=this.ze(C);F&&(this.Md.save(),this.se(),this.Md.drawTextBlob(k,q,z,F),this.Md.restore(),F.dispose());this.Md.drawTextBlob(k,q,z,C);k.delete();C.dispose()};this.getImageData=function(k,q,z,C){return(k=this.Md.readPixels(k,q,{width:z,height:C,colorType:a.ColorType.RGBA_8888,alphaType:a.AlphaType.Unpremul,colorSpace:a.ColorSpace.SRGB}))?new J(new Uint8ClampedArray(k.buffer),z,C):null};this.getLineDash=function(){return this.ve.slice()};this.nf=function(k){var q=a.Matrix.invert(this.Ud);
a.Matrix.mapPoints(q,k);return k};this.isPointInPath=function(k,q,z){var C=arguments;if(3===C.length)var F=this.Rd;else if(4===C.length)F=C[0],k=C[1],q=C[2],z=C[3];else throw"invalid arg count, need 3 or 4, got "+C.length;if(!isFinite(k)||!isFinite(q))return!1;z=z||"nonzero";if("nonzero"!==z&&"evenodd"!==z)return!1;C=this.nf([k,q]);k=C[0];q=C[1];F.setFillType("nonzero"===z?a.FillType.Winding:a.FillType.EvenOdd);return F.contains(k,q)};this.isPointInStroke=function(k,q){var z=arguments;if(2===z.length)var C=
this.Rd;else if(3===z.length)C=z[0],k=z[1],q=z[2];else throw"invalid arg count, need 2 or 3, got "+z.length;if(!isFinite(k)||!isFinite(q))return!1;z=this.nf([k,q]);k=z[0];q=z[1];C=C.copy();C.setFillType(a.FillType.Winding);C.stroke({width:this.lineWidth,miter_limit:this.miterLimit,cap:this.Pd.getStrokeCap(),join:this.Pd.getStrokeJoin(),precision:.3});z=C.contains(k,q);C.delete();return z};this.lineTo=function(k,q){D(this.Rd,k,q)};this.measureText=function(k){k=this.ne.getGlyphIDs(k);k=this.ne.getGlyphWidths(k);
let q=0;for(const z of k)q+=z;return{width:q}};this.moveTo=function(k,q){var z=this.Rd;e([k,q])&&z.moveTo(k,q)};this.putImageData=function(k,q,z,C,F,I,M){if(e([q,z,C,F,I,M]))if(void 0===C)this.Md.writePixels(k.data,k.width,k.height,q,z);else if(C=C||0,F=F||0,I=I||k.width,M=M||k.height,0>I&&(C+=I,I=Math.abs(I)),0>M&&(F+=M,M=Math.abs(M)),0>C&&(I+=C,C=0),0>F&&(M+=F,F=0),!(0>=I||0>=M)){k=a.MakeImage({width:k.width,height:k.height,alphaType:a.AlphaType.Unpremul,colorType:a.ColorType.RGBA_8888,colorSpace:a.ColorSpace.SRGB},
k.data,4*k.width);var ea=a.XYWHRect(C,F,I,M);q=a.XYWHRect(q+C,z+F,I,M);z=a.Matrix.invert(this.Ud);this.Md.save();this.Md.concat(z);this.Md.drawImageRect(k,ea,q,null,!1);this.Md.restore();k.delete()}};this.quadraticCurveTo=function(k,q,z,C){var F=this.Rd;e([k,q,z,C])&&(F.isEmpty()&&F.moveTo(k,q),F.quadTo(k,q,z,C))};this.rect=function(k,q,z,C){var F=this.Rd;k=a.XYWHRect(k,q,z,C);e(k)&&F.addRect(k)};this.resetTransform=function(){this.Rd.transform(this.Ud);var k=a.Matrix.invert(this.Ud);this.Md.concat(k);
this.Ud=this.Md.getTotalMatrix()};this.restore=function(){var k=this.mf.pop();if(k){var q=a.Matrix.multiply(this.Ud,a.Matrix.invert(k.Kf));this.Rd.transform(q);this.Pd.delete();this.Pd=k.bg;this.ve=k.$f;this.Le=k.ng;this.he=k.mg;this.be=k.fs;this.xe=k.kg;this.ye=k.lg;this.we=k.sb;this.Ke=k.jg;this.je=k.ga;this.Od=k.Rf;this.Je=k.ag;this.Se=k.Qf;this.Md.restore();this.Ud=this.Md.getTotalMatrix()}};this.rotate=function(k){if(isFinite(k)){var q=a.Matrix.rotated(-k);this.Rd.transform(q);this.Md.rotate(k/
Math.PI*180,0,0);this.Ud=this.Md.getTotalMatrix()}};this.save=function(){if(this.be.te){var k=this.be.te();this.Ce.push(k)}else k=this.be;if(this.he.te){var q=this.he.te();this.Ce.push(q)}else q=this.he;this.mf.push({Kf:this.Ud.slice(),$f:this.ve.slice(),ng:this.Le,mg:q,fs:k,kg:this.xe,lg:this.ye,sb:this.we,jg:this.Ke,ga:this.je,ag:this.Je,Rf:this.Od,bg:this.Pd.copy(),Qf:this.Se});this.Md.save()};this.scale=function(k,q){if(e(arguments)){var z=a.Matrix.scaled(1/k,1/q);this.Rd.transform(z);this.Md.scale(k,
q);this.Ud=this.Md.getTotalMatrix()}};this.setLineDash=function(k){for(var q=0;q<k.length;q++)if(!isFinite(k[q])||0>k[q])return;1===k.length%2&&Array.prototype.push.apply(k,k);this.ve=k};this.setTransform=function(k,q,z,C,F,I){e(arguments)&&(this.resetTransform(),this.transform(k,q,z,C,F,I))};this.se=function(){var k=a.Matrix.invert(this.Ud);this.Md.concat(k);this.Md.concat(a.Matrix.translated(this.xe,this.ye));this.Md.concat(this.Ud)};this.ze=function(k){var q=a.multiplyByAlpha(this.Ke,this.je);
if(!a.getColorComponents(q)[3]||!(this.we||this.ye||this.xe))return null;k=k.copy();k.setColor(q);var z=a.MaskFilter.MakeBlur(a.BlurStyle.Normal,this.we/2,!1);k.setMaskFilter(z);k.dispose=function(){z.delete();this.delete()};return k};this.df=function(){var k=this.Pd.copy();k.setStyle(a.PaintStyle.Stroke);if(f(this.he)){var q=a.multiplyByAlpha(this.he,this.je);k.setColor(q)}else q=this.he.ue(this.Ud),k.setColor(a.Color(0,0,0,this.je)),k.setShader(q);k.setStrokeWidth(this.Le);if(this.ve.length){var z=
a.PathEffect.MakeDash(this.ve,this.Je);k.setPathEffect(z)}k.dispose=function(){z&&z.delete();this.delete()};return k};this.stroke=function(k){k=k?k.Vd:this.Rd;var q=this.df(),z=this.ze(q);z&&(this.Md.save(),this.se(),this.Md.drawPath(k,z),this.Md.restore(),z.dispose());this.Md.drawPath(k,q);q.dispose()};this.strokeRect=function(k,q,z,C){var F=this.df(),I=this.ze(F);I&&(this.Md.save(),this.se(),this.Md.drawRect(a.XYWHRect(k,q,z,C),I),this.Md.restore(),I.dispose());this.Md.drawRect(a.XYWHRect(k,q,z,
C),F);F.dispose()};this.strokeText=function(k,q,z){var C=this.df();k=a.TextBlob.MakeFromText(k,this.ne);var F=this.ze(C);F&&(this.Md.save(),this.se(),this.Md.drawTextBlob(k,q,z,F),this.Md.restore(),F.dispose());this.Md.drawTextBlob(k,q,z,C);k.delete();C.dispose()};this.translate=function(k,q){if(e(arguments)){var z=a.Matrix.translated(-k,-q);this.Rd.transform(z);this.Md.translate(k,q);this.Ud=this.Md.getTotalMatrix()}};this.transform=function(k,q,z,C,F,I){k=[k,z,F,q,C,I,0,0,1];q=a.Matrix.invert(k);
this.Rd.transform(q);this.Md.concat(k);this.Ud=this.Md.getTotalMatrix()};this.addHitRegion=function(){};this.clearHitRegions=function(){};this.drawFocusIfNeeded=function(){};this.removeHitRegion=function(){};this.scrollPathIntoView=function(){};Object.defineProperty(this,"canvas",{value:null,writable:!1})}function x(G){this.ef=G;this.Ld=new t(G.getCanvas());this.Te=[];this.decodeImage=function(k){k=a.MakeImageFromEncoded(k);if(!k)throw"Invalid input";this.Te.push(k);return new B(k)};this.loadFont=
function(k,q){k=a.Typeface.MakeFreeTypeFaceFromData(k);if(!k)return null;this.Te.push(k);var z=(q.style||"normal")+"|"+(q.variant||"normal")+"|"+(q.weight||"normal");q=q.family;na[q]||(na[q]={"*":k});na[q][z]=k};this.makePath2D=function(k){k=new U(k);this.Te.push(k.Vd);return k};this.getContext=function(k){return"2d"===k?this.Ld:null};this.toDataURL=function(k,q){this.ef.flush();var z=this.ef.makeImageSnapshot();if(z){k=k||"image/png";var C=a.ImageFormat.PNG;"image/jpeg"===k&&(C=a.ImageFormat.JPEG);
if(q=z.encodeToBytes(C,q||.92)){z.delete();k="data:"+k+";base64,";if("undefined"!==typeof Buffer)q=Buffer.from(q).toString("base64");else{z=0;C=q.length;for(var F="",I;z<C;)I=q.slice(z,Math.min(z+32768,C)),F+=String.fromCharCode.apply(null,I),z+=32768;q=btoa(F)}return k+q}}};this.dispose=function(){this.Ld.me();this.Te.forEach(function(k){k.delete()});this.ef.dispose()}}function B(G){this.width=G.width();this.height=G.height();this.naturalWidth=this.width;this.naturalHeight=this.height;this.uf=function(){return G}}
function J(G,k,q){if(!k||0===q)throw"invalid dimensions, width and height must be non-zero";if(G.length%4)throw"arr must be a multiple of 4";q=q||G.length/(4*k);Object.defineProperty(this,"data",{value:G,writable:!1});Object.defineProperty(this,"height",{value:q,writable:!1});Object.defineProperty(this,"width",{value:k,writable:!1})}function P(G,k,q,z){this.Xd=null;this.de=[];this.$d=[];this.addColorStop=function(C,F){if(0>C||1<C||!isFinite(C))throw"offset must be between 0 and 1 inclusively";F=g(F);
var I=this.$d.indexOf(C);if(-1!==I)this.de[I]=F;else{for(I=0;I<this.$d.length&&!(this.$d[I]>C);I++);this.$d.splice(I,0,C);this.de.splice(I,0,F)}};this.te=function(){var C=new P(G,k,q,z);C.de=this.de.slice();C.$d=this.$d.slice();return C};this.me=function(){this.Xd&&(this.Xd.delete(),this.Xd=null)};this.ue=function(C){var F=[G,k,q,z];a.Matrix.mapPoints(C,F);C=F[0];var I=F[1],M=F[2];F=F[3];this.me();return this.Xd=a.Shader.MakeLinearGradient([C,I],[M,F],this.de,this.$d,a.TileMode.Clamp)}}function O(G,
k,q,z,C,F){if(e([k,q,z,C,F])){if(0>F)throw"radii cannot be negative";G.isEmpty()&&G.moveTo(k,q);G.arcToTangent(k,q,z,C,F)}}function Y(G){if(!G.isEmpty()){var k=G.getBounds();(k[3]-k[1]||k[2]-k[0])&&G.close()}}function W(G,k,q,z,C,F,I){I=(I-F)/Math.PI*180;F=F/Math.PI*180;k=a.LTRBRect(k-z,q-C,k+z,q+C);1E-5>Math.abs(Math.abs(I)-360)?(q=I/2,G.arcToOval(k,F,q,!1),G.arcToOval(k,F+q,q,!1)):G.arcToOval(k,F,I,!1)}function r(G,k,q,z,C,F,I,M,ea){if(e([k,q,z,C,F,I,M])){if(0>z||0>C)throw"radii cannot be negative";
var fa=2*Math.PI,Ja=I%fa;0>Ja&&(Ja+=fa);var bb=Ja-I;I=Ja;M+=bb;!ea&&M-I>=fa?M=I+fa:ea&&I-M>=fa?M=I-fa:!ea&&I>M?M=I+(fa-(I-M)%fa):ea&&I<M&&(M=I-(fa-(M-I)%fa));F?(ea=a.Matrix.rotated(F,k,q),F=a.Matrix.rotated(-F,k,q),G.transform(F),W(G,k,q,z,C,I,M),G.transform(ea)):W(G,k,q,z,C,I,M)}}function D(G,k,q){e([k,q])&&(G.isEmpty()&&G.moveTo(k,q),G.lineTo(k,q))}function U(G){this.Vd=null;this.Vd="string"===typeof G?a.Path.MakeFromSVGString(G):G&&G.bf?G.Vd.copy():new a.Path;this.bf=function(){return this.Vd};
this.addPath=function(k,q){q||(q={a:1,c:0,e:0,b:0,d:1,f:0});this.Vd.addPath(k.Vd,[q.a,q.c,q.e,q.b,q.d,q.f])};this.arc=function(k,q,z,C,F,I){r(this.Vd,k,q,z,z,0,C,F,I)};this.arcTo=function(k,q,z,C,F){O(this.Vd,k,q,z,C,F)};this.bezierCurveTo=function(k,q,z,C,F,I){var M=this.Vd;e([k,q,z,C,F,I])&&(M.isEmpty()&&M.moveTo(k,q),M.cubicTo(k,q,z,C,F,I))};this.closePath=function(){Y(this.Vd)};this.ellipse=function(k,q,z,C,F,I,M,ea){r(this.Vd,k,q,z,C,F,I,M,ea)};this.lineTo=function(k,q){D(this.Vd,k,q)};this.moveTo=
function(k,q){var z=this.Vd;e([k,q])&&z.moveTo(k,q)};this.quadraticCurveTo=function(k,q,z,C){var F=this.Vd;e([k,q,z,C])&&(F.isEmpty()&&F.moveTo(k,q),F.quadTo(k,q,z,C))};this.rect=function(k,q,z,C){var F=this.Vd;k=a.XYWHRect(k,q,z,C);e(k)&&F.addRect(k)}}function da(G,k){this.Xd=null;G instanceof B&&(G=G.uf());this.Ff=G;this._transform=a.Matrix.identity();""===k&&(k="repeat");switch(k){case "repeat-x":this.Ae=a.TileMode.Repeat;this.Be=a.TileMode.Decal;break;case "repeat-y":this.Ae=a.TileMode.Decal;
this.Be=a.TileMode.Repeat;break;case "repeat":this.Be=this.Ae=a.TileMode.Repeat;break;case "no-repeat":this.Be=this.Ae=a.TileMode.Decal;break;default:throw"invalid repetition mode "+k;}this.setTransform=function(q){q=[q.a,q.c,q.e,q.b,q.d,q.f,0,0,1];e(q)&&(this._transform=q)};this.te=function(){var q=new da;q.Ae=this.Ae;q.Be=this.Be;return q};this.me=function(){this.Xd&&(this.Xd.delete(),this.Xd=null)};this.ue=function(){this.me();return this.Xd=this.Ff.makeShaderCubic(this.Ae,this.Be,1/3,1/3,this._transform)}}
function ka(G,k,q,z,C,F){this.Xd=null;this.de=[];this.$d=[];this.addColorStop=function(I,M){if(0>I||1<I||!isFinite(I))throw"offset must be between 0 and 1 inclusively";M=g(M);var ea=this.$d.indexOf(I);if(-1!==ea)this.de[ea]=M;else{for(ea=0;ea<this.$d.length&&!(this.$d[ea]>I);ea++);this.$d.splice(ea,0,I);this.de.splice(ea,0,M)}};this.te=function(){var I=new ka(G,k,q,z,C,F);I.de=this.de.slice();I.$d=this.$d.slice();return I};this.me=function(){this.Xd&&(this.Xd.delete(),this.Xd=null)};this.ue=function(I){var M=
[G,k,z,C];a.Matrix.mapPoints(I,M);var ea=M[0],fa=M[1],Ja=M[2];M=M[3];var bb=(Math.abs(I[0])+Math.abs(I[4]))/2;I=q*bb;bb*=F;this.me();return this.Xd=a.Shader.MakeTwoPointConicalGradient([ea,fa],I,[Ja,M],bb,this.de,this.$d,a.TileMode.Clamp)}}a._testing={};var wa={aliceblue:Float32Array.of(.941,.973,1,1),antiquewhite:Float32Array.of(.98,.922,.843,1),aqua:Float32Array.of(0,1,1,1),aquamarine:Float32Array.of(.498,1,.831,1),azure:Float32Array.of(.941,1,1,1),beige:Float32Array.of(.961,.961,.863,1),bisque:Float32Array.of(1,
.894,.769,1),black:Float32Array.of(0,0,0,1),blanchedalmond:Float32Array.of(1,.922,.804,1),blue:Float32Array.of(0,0,1,1),blueviolet:Float32Array.of(.541,.169,.886,1),brown:Float32Array.of(.647,.165,.165,1),burlywood:Float32Array.of(.871,.722,.529,1),cadetblue:Float32Array.of(.373,.62,.627,1),chartreuse:Float32Array.of(.498,1,0,1),chocolate:Float32Array.of(.824,.412,.118,1),coral:Float32Array.of(1,.498,.314,1),cornflowerblue:Float32Array.of(.392,.584,.929,1),cornsilk:Float32Array.of(1,.973,.863,1),
crimson:Float32Array.of(.863,.078,.235,1),cyan:Float32Array.of(0,1,1,1),darkblue:Float32Array.of(0,0,.545,1),darkcyan:Float32Array.of(0,.545,.545,1),darkgoldenrod:Float32Array.of(.722,.525,.043,1),darkgray:Float32Array.of(.663,.663,.663,1),darkgreen:Float32Array.of(0,.392,0,1),darkgrey:Float32Array.of(.663,.663,.663,1),darkkhaki:Float32Array.of(.741,.718,.42,1),darkmagenta:Float32Array.of(.545,0,.545,1),darkolivegreen:Float32Array.of(.333,.42,.184,1),darkorange:Float32Array.of(1,.549,0,1),darkorchid:Float32Array.of(.6,
.196,.8,1),darkred:Float32Array.of(.545,0,0,1),darksalmon:Float32Array.of(.914,.588,.478,1),darkseagreen:Float32Array.of(.561,.737,.561,1),darkslateblue:Float32Array.of(.282,.239,.545,1),darkslategray:Float32Array.of(.184,.31,.31,1),darkslategrey:Float32Array.of(.184,.31,.31,1),darkturquoise:Float32Array.of(0,.808,.82,1),darkviolet:Float32Array.of(.58,0,.827,1),deeppink:Float32Array.of(1,.078,.576,1),deepskyblue:Float32Array.of(0,.749,1,1),dimgray:Float32Array.of(.412,.412,.412,1),dimgrey:Float32Array.of(.412,
.412,.412,1),dodgerblue:Float32Array.of(.118,.565,1,1),firebrick:Float32Array.of(.698,.133,.133,1),floralwhite:Float32Array.of(1,.98,.941,1),forestgreen:Float32Array.of(.133,.545,.133,1),fuchsia:Float32Array.of(1,0,1,1),gainsboro:Float32Array.of(.863,.863,.863,1),ghostwhite:Float32Array.of(.973,.973,1,1),gold:Float32Array.of(1,.843,0,1),goldenrod:Float32Array.of(.855,.647,.125,1),gray:Float32Array.of(.502,.502,.502,1),green:Float32Array.of(0,.502,0,1),greenyellow:Float32Array.of(.678,1,.184,1),grey:Float32Array.of(.502,
.502,.502,1),honeydew:Float32Array.of(.941,1,.941,1),hotpink:Float32Array.of(1,.412,.706,1),indianred:Float32Array.of(.804,.361,.361,1),indigo:Float32Array.of(.294,0,.51,1),ivory:Float32Array.of(1,1,.941,1),khaki:Float32Array.of(.941,.902,.549,1),lavender:Float32Array.of(.902,.902,.98,1),lavenderblush:Float32Array.of(1,.941,.961,1),lawngreen:Float32Array.of(.486,.988,0,1),lemonchiffon:Float32Array.of(1,.98,.804,1),lightblue:Float32Array.of(.678,.847,.902,1),lightcoral:Float32Array.of(.941,.502,.502,
1),lightcyan:Float32Array.of(.878,1,1,1),lightgoldenrodyellow:Float32Array.of(.98,.98,.824,1),lightgray:Float32Array.of(.827,.827,.827,1),lightgreen:Float32Array.of(.565,.933,.565,1),lightgrey:Float32Array.of(.827,.827,.827,1),lightpink:Float32Array.of(1,.714,.757,1),lightsalmon:Float32Array.of(1,.627,.478,1),lightseagreen:Float32Array.of(.125,.698,.667,1),lightskyblue:Float32Array.of(.529,.808,.98,1),lightslategray:Float32Array.of(.467,.533,.6,1),lightslategrey:Float32Array.of(.467,.533,.6,1),lightsteelblue:Float32Array.of(.69,
.769,.871,1),lightyellow:Float32Array.of(1,1,.878,1),lime:Float32Array.of(0,1,0,1),limegreen:Float32Array.of(.196,.804,.196,1),linen:Float32Array.of(.98,.941,.902,1),magenta:Float32Array.of(1,0,1,1),maroon:Float32Array.of(.502,0,0,1),mediumaquamarine:Float32Array.of(.4,.804,.667,1),mediumblue:Float32Array.of(0,0,.804,1),mediumorchid:Float32Array.of(.729,.333,.827,1),mediumpurple:Float32Array.of(.576,.439,.859,1),mediumseagreen:Float32Array.of(.235,.702,.443,1),mediumslateblue:Float32Array.of(.482,
.408,.933,1),mediumspringgreen:Float32Array.of(0,.98,.604,1),mediumturquoise:Float32Array.of(.282,.82,.8,1),mediumvioletred:Float32Array.of(.78,.082,.522,1),midnightblue:Float32Array.of(.098,.098,.439,1),mintcream:Float32Array.of(.961,1,.98,1),mistyrose:Float32Array.of(1,.894,.882,1),moccasin:Float32Array.of(1,.894,.71,1),navajowhite:Float32Array.of(1,.871,.678,1),navy:Float32Array.of(0,0,.502,1),oldlace:Float32Array.of(.992,.961,.902,1),olive:Float32Array.of(.502,.502,0,1),olivedrab:Float32Array.of(.42,
.557,.137,1),orange:Float32Array.of(1,.647,0,1),orangered:Float32Array.of(1,.271,0,1),orchid:Float32Array.of(.855,.439,.839,1),palegoldenrod:Float32Array.of(.933,.91,.667,1),palegreen:Float32Array.of(.596,.984,.596,1),paleturquoise:Float32Array.of(.686,.933,.933,1),palevioletred:Float32Array.of(.859,.439,.576,1),papayawhip:Float32Array.of(1,.937,.835,1),peachpuff:Float32Array.of(1,.855,.725,1),peru:Float32Array.of(.804,.522,.247,1),pink:Float32Array.of(1,.753,.796,1),plum:Float32Array.of(.867,.627,
.867,1),powderblue:Float32Array.of(.69,.878,.902,1),purple:Float32Array.of(.502,0,.502,1),rebeccapurple:Float32Array.of(.4,.2,.6,1),red:Float32Array.of(1,0,0,1),rosybrown:Float32Array.of(.737,.561,.561,1),royalblue:Float32Array.of(.255,.412,.882,1),saddlebrown:Float32Array.of(.545,.271,.075,1),salmon:Float32Array.of(.98,.502,.447,1),sandybrown:Float32Array.of(.957,.643,.376,1),seagreen:Float32Array.of(.18,.545,.341,1),seashell:Float32Array.of(1,.961,.933,1),sienna:Float32Array.of(.627,.322,.176,1),
silver:Float32Array.of(.753,.753,.753,1),skyblue:Float32Array.of(.529,.808,.922,1),slateblue:Float32Array.of(.416,.353,.804,1),slategray:Float32Array.of(.439,.502,.565,1),slategrey:Float32Array.of(.439,.502,.565,1),snow:Float32Array.of(1,.98,.98,1),springgreen:Float32Array.of(0,1,.498,1),steelblue:Float32Array.of(.275,.51,.706,1),tan:Float32Array.of(.824,.706,.549,1),teal:Float32Array.of(0,.502,.502,1),thistle:Float32Array.of(.847,.749,.847,1),tomato:Float32Array.of(1,.388,.278,1),transparent:Float32Array.of(0,
0,0,0),turquoise:Float32Array.of(.251,.878,.816,1),violet:Float32Array.of(.933,.51,.933,1),wheat:Float32Array.of(.961,.871,.702,1),white:Float32Array.of(1,1,1,1),whitesmoke:Float32Array.of(.961,.961,.961,1),yellow:Float32Array.of(1,1,0,1),yellowgreen:Float32Array.of(.604,.804,.196,1)};a._testing.parseColor=g;a._testing.colorToString=c;var xa=RegExp("(italic|oblique|normal|)\\s*(small-caps|normal|)\\s*(bold|bolder|lighter|[1-9]00|normal|)\\s*([\\d\\.]+)(px|pt|pc|in|cm|mm|%|em|ex|ch|rem|q)(.+)"),na=
{"Noto Mono":{"*":null},monospace:{"*":null}};a._testing.parseFontString=l;a.MakeCanvas=function(G,k){return(G=a.MakeSurface(G,k))?new x(G):null};a.ImageData=function(){if(2===arguments.length){var G=arguments[0],k=arguments[1];return new J(new Uint8ClampedArray(4*G*k),G,k)}if(3===arguments.length){var q=arguments[0];if(q.prototype.constructor!==Uint8ClampedArray)throw"bytes must be given as a Uint8ClampedArray";G=arguments[1];k=arguments[2];if(q%4)throw"bytes must be given in a multiple of 4";if(q%
G)throw"bytes must divide evenly by width";if(k&&k!==q/(4*G))throw"invalid height given";return new J(q,G,q/(4*G))}throw"invalid number of arguments - takes 2 or 3, saw "+arguments.length;}})()})(w);var ra=Object.assign({},w),ua="./this.program",va=(a,b)=>{throw b;},ya="object"==typeof window,za="function"==typeof importScripts,Aa="object"==typeof process&&"object"==typeof process.versions&&"string"==typeof process.versions.node,Ba="",Ca,Da,Ea;
if(Aa){var fs=require("fs"),Fa=require("path");Ba=za?Fa.dirname(Ba)+"/":__dirname+"/";Ca=(a,b)=>{a=a.startsWith("file://")?new URL(a):Fa.normalize(a);return fs.readFileSync(a,b?void 0:"utf8")};Ea=a=>{a=Ca(a,!0);a.buffer||(a=new Uint8Array(a));return a};Da=(a,b,d,f=!0)=>{a=a.startsWith("file://")?new URL(a):Fa.normalize(a);fs.readFile(a,f?void 0:"utf8",(h,m)=>{h?d(h):b(f?m.buffer:m)})};!w.thisProgram&&1<process.argv.length&&(ua=process.argv[1].replace(/\\/g,"/"));process.argv.slice(2);va=(a,b)=>{process.exitCode=
a;throw b;};w.inspect=()=>"[Emscripten Module object]"}else if(ya||za)za?Ba=self.location.href:"undefined"!=typeof document&&document.currentScript&&(Ba=document.currentScript.src),_scriptDir&&(Ba=_scriptDir),0!==Ba.indexOf("blob:")?Ba=Ba.substr(0,Ba.replace(/[?#].*/,"").lastIndexOf("/")+1):Ba="",Ca=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.send(null);return b.responseText},za&&(Ea=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.responseType="arraybuffer";b.send(null);return new Uint8Array(b.response)}),
Da=(a,b,d)=>{var f=new XMLHttpRequest;f.open("GET",a,!0);f.responseType="arraybuffer";f.onload=()=>{200==f.status||0==f.status&&f.response?b(f.response):d()};f.onerror=d;f.send(null)};var Ga=w.print||console.log.bind(console),Ha=w.printErr||console.error.bind(console);Object.assign(w,ra);ra=null;w.thisProgram&&(ua=w.thisProgram);w.quit&&(va=w.quit);var Ka;w.wasmBinary&&(Ka=w.wasmBinary);var noExitRuntime=w.noExitRuntime||!0;"object"!=typeof WebAssembly&&La("no native wasm support detected");
var Ma,Q,Pa=!1,Qa,K,Ra,Sa,S,Ta,X,Ua;function Va(){var a=Ma.buffer;w.HEAP8=Qa=new Int8Array(a);w.HEAP16=Ra=new Int16Array(a);w.HEAP32=S=new Int32Array(a);w.HEAPU8=K=new Uint8Array(a);w.HEAPU16=Sa=new Uint16Array(a);w.HEAPU32=Ta=new Uint32Array(a);w.HEAPF32=X=new Float32Array(a);w.HEAPF64=Ua=new Float64Array(a)}var Wa,Xa=[],Ya=[],Za=[];function cb(){var a=w.preRun.shift();Xa.unshift(a)}var db=0,eb=null,fb=null;
function La(a){if(w.onAbort)w.onAbort(a);a="Aborted("+a+")";Ha(a);Pa=!0;a=new WebAssembly.RuntimeError(a+". Build with -sASSERTIONS for more info.");ba(a);throw a;}function gb(a){return a.startsWith("data:application/octet-stream;base64,")}var lb;lb="canvaskit.wasm";if(!gb(lb)){var mb=lb;lb=w.locateFile?w.locateFile(mb,Ba):Ba+mb}function nb(a){if(a==lb&&Ka)return new Uint8Array(Ka);if(Ea)return Ea(a);throw"both async and sync fetching of the wasm failed";}
function ob(a){if(!Ka&&(ya||za)){if("function"==typeof fetch&&!a.startsWith("file://"))return fetch(a,{credentials:"same-origin"}).then(b=>{if(!b.ok)throw"failed to load wasm binary file at '"+a+"'";return b.arrayBuffer()}).catch(()=>nb(a));if(Da)return new Promise((b,d)=>{Da(a,f=>b(new Uint8Array(f)),d)})}return Promise.resolve().then(()=>nb(a))}function pb(a,b,d){return ob(a).then(f=>WebAssembly.instantiate(f,b)).then(f=>f).then(d,f=>{Ha("failed to asynchronously prepare wasm: "+f);La(f)})}
function qb(a,b){var d=lb;return Ka||"function"!=typeof WebAssembly.instantiateStreaming||gb(d)||d.startsWith("file://")||Aa||"function"!=typeof fetch?pb(d,a,b):fetch(d,{credentials:"same-origin"}).then(f=>WebAssembly.instantiateStreaming(f,a).then(b,function(h){Ha("wasm streaming compile failed: "+h);Ha("falling back to ArrayBuffer instantiation");return pb(d,a,b)}))}function rb(a){this.name="ExitStatus";this.message=`Program terminated with exit(${a})`;this.status=a}
var sb=a=>{for(;0<a.length;)a.shift()(w)},tb="undefined"!=typeof TextDecoder?new TextDecoder("utf8"):void 0,ub=(a,b,d)=>{var f=b+d;for(d=b;a[d]&&!(d>=f);)++d;if(16<d-b&&a.buffer&&tb)return tb.decode(a.subarray(b,d));for(f="";b<d;){var h=a[b++];if(h&128){var m=a[b++]&63;if(192==(h&224))f+=String.fromCharCode((h&31)<<6|m);else{var u=a[b++]&63;h=224==(h&240)?(h&15)<<12|m<<6|u:(h&7)<<18|m<<12|u<<6|a[b++]&63;65536>h?f+=String.fromCharCode(h):(h-=65536,f+=String.fromCharCode(55296|h>>10,56320|h&1023))}}else f+=
String.fromCharCode(h)}return f},vb={};function wb(a){for(;a.length;){var b=a.pop();a.pop()(b)}}function xb(a){return this.fromWireType(S[a>>2])}var yb={},Ab={},Bb={},Cb=void 0;function Db(a){throw new Cb(a);}
function Eb(a,b,d){function f(n){n=d(n);n.length!==a.length&&Db("Mismatched type converter count");for(var p=0;p<a.length;++p)Fb(a[p],n[p])}a.forEach(function(n){Bb[n]=b});var h=Array(b.length),m=[],u=0;b.forEach((n,p)=>{Ab.hasOwnProperty(n)?h[p]=Ab[n]:(m.push(n),yb.hasOwnProperty(n)||(yb[n]=[]),yb[n].push(()=>{h[p]=Ab[n];++u;u===m.length&&f(h)}))});0===m.length&&f(h)}
function Gb(a){switch(a){case 1:return 0;case 2:return 1;case 4:return 2;case 8:return 3;default:throw new TypeError(`Unknown type size: ${a}`);}}var Hb=void 0;function Ib(a){for(var b="";K[a];)b+=Hb[K[a++]];return b}var Jb=void 0;function Kb(a){throw new Jb(a);}
function Lb(a,b,d={}){var f=b.name;a||Kb(`type "${f}" must have a positive integer typeid pointer`);if(Ab.hasOwnProperty(a)){if(d.Xf)return;Kb(`Cannot register type '${f}' twice`)}Ab[a]=b;delete Bb[a];yb.hasOwnProperty(a)&&(b=yb[a],delete yb[a],b.forEach(h=>h()))}function Fb(a,b,d={}){if(!("argPackAdvance"in b))throw new TypeError("registerType registeredInstance requires argPackAdvance");Lb(a,b,d)}function Mb(a){Kb(a.Kd.Wd.Qd.name+" instance already deleted")}var Nb=!1;function Ob(){}
function Pb(a){--a.count.value;0===a.count.value&&(a.Zd?a.fe.le(a.Zd):a.Wd.Qd.le(a.Sd))}function Qb(a,b,d){if(b===d)return a;if(void 0===d.ae)return null;a=Qb(a,b,d.ae);return null===a?null:d.Nf(a)}var Rb={},Sb=[];function Tb(){for(;Sb.length;){var a=Sb.pop();a.Kd.Fe=!1;a["delete"]()}}var Ub=void 0,Vb={};function bc(a,b){for(void 0===b&&Kb("ptr should not be undefined");a.ae;)b=a.Pe(b),a=a.ae;return Vb[b]}
function cc(a,b){b.Wd&&b.Sd||Db("makeClassHandle requires ptr and ptrType");!!b.fe!==!!b.Zd&&Db("Both smartPtrType and smartPtr must be specified");b.count={value:1};return dc(Object.create(a,{Kd:{value:b}}))}function dc(a){if("undefined"===typeof FinalizationRegistry)return dc=b=>b,a;Nb=new FinalizationRegistry(b=>{Pb(b.Kd)});dc=b=>{var d=b.Kd;d.Zd&&Nb.register(b,{Kd:d},b);return b};Ob=b=>{Nb.unregister(b)};return dc(a)}function ec(){}
function fc(a){if(void 0===a)return"_unknown";a=a.replace(/[^a-zA-Z0-9_]/g,"$");var b=a.charCodeAt(0);return 48<=b&&57>=b?`_${a}`:a}function gc(a,b){a=fc(a);return{[a]:function(){return b.apply(this,arguments)}}[a]}
function hc(a,b,d){if(void 0===a[b].Yd){var f=a[b];a[b]=function(){a[b].Yd.hasOwnProperty(arguments.length)||Kb(`Function '${d}' called with an invalid number of arguments (${arguments.length}) - expects one of (${a[b].Yd})!`);return a[b].Yd[arguments.length].apply(this,arguments)};a[b].Yd=[];a[b].Yd[f.De]=f}}
function ic(a,b,d){w.hasOwnProperty(a)?((void 0===d||void 0!==w[a].Yd&&void 0!==w[a].Yd[d])&&Kb(`Cannot register public name '${a}' twice`),hc(w,a,a),w.hasOwnProperty(d)&&Kb(`Cannot register multiple overloads of a function with the same number of arguments (${d})!`),w[a].Yd[d]=b):(w[a]=b,void 0!==d&&(w[a].xg=d))}function jc(a,b,d,f,h,m,u,n){this.name=a;this.constructor=b;this.Ge=d;this.le=f;this.ae=h;this.Sf=m;this.Pe=u;this.Nf=n;this.dg=[]}
function kc(a,b,d){for(;b!==d;)b.Pe||Kb(`Expected null or instance of ${d.name}, got an instance of ${b.name}`),a=b.Pe(a),b=b.ae;return a}function lc(a,b){if(null===b)return this.hf&&Kb(`null is not a valid ${this.name}`),0;b.Kd||Kb(`Cannot pass "${mc(b)}" as a ${this.name}`);b.Kd.Sd||Kb(`Cannot pass deleted object as a pointer of type ${this.name}`);return kc(b.Kd.Sd,b.Kd.Wd.Qd,this.Qd)}
function nc(a,b){if(null===b){this.hf&&Kb(`null is not a valid ${this.name}`);if(this.We){var d=this.jf();null!==a&&a.push(this.le,d);return d}return 0}b.Kd||Kb(`Cannot pass "${mc(b)}" as a ${this.name}`);b.Kd.Sd||Kb(`Cannot pass deleted object as a pointer of type ${this.name}`);!this.Ve&&b.Kd.Wd.Ve&&Kb(`Cannot convert argument of type ${b.Kd.fe?b.Kd.fe.name:b.Kd.Wd.name} to parameter type ${this.name}`);d=kc(b.Kd.Sd,b.Kd.Wd.Qd,this.Qd);if(this.We)switch(void 0===b.Kd.Zd&&Kb("Passing raw pointer to smart pointer is illegal"),
this.ig){case 0:b.Kd.fe===this?d=b.Kd.Zd:Kb(`Cannot convert argument of type ${b.Kd.fe?b.Kd.fe.name:b.Kd.Wd.name} to parameter type ${this.name}`);break;case 1:d=b.Kd.Zd;break;case 2:if(b.Kd.fe===this)d=b.Kd.Zd;else{var f=b.clone();d=this.eg(d,oc(function(){f["delete"]()}));null!==a&&a.push(this.le,d)}break;default:Kb("Unsupporting sharing policy")}return d}
function pc(a,b){if(null===b)return this.hf&&Kb(`null is not a valid ${this.name}`),0;b.Kd||Kb(`Cannot pass "${mc(b)}" as a ${this.name}`);b.Kd.Sd||Kb(`Cannot pass deleted object as a pointer of type ${this.name}`);b.Kd.Wd.Ve&&Kb(`Cannot convert argument of type ${b.Kd.Wd.name} to parameter type ${this.name}`);return kc(b.Kd.Sd,b.Kd.Wd.Qd,this.Qd)}
function qc(a,b,d,f,h,m,u,n,p,v,E){this.name=a;this.Qd=b;this.hf=d;this.Ve=f;this.We=h;this.cg=m;this.ig=u;this.wf=n;this.jf=p;this.eg=v;this.le=E;h||void 0!==b.ae?this.toWireType=nc:(this.toWireType=f?lc:pc,this.ee=null)}function rc(a,b,d){w.hasOwnProperty(a)||Db("Replacing nonexistant public symbol");void 0!==w[a].Yd&&void 0!==d?w[a].Yd[d]=b:(w[a]=b,w[a].De=d)}
var sc=(a,b)=>{var d=[];return function(){d.length=0;Object.assign(d,arguments);if(a.includes("j")){var f=w["dynCall_"+a];f=d&&d.length?f.apply(null,[b].concat(d)):f.call(null,b)}else f=Wa.get(b).apply(null,d);return f}};function tc(a,b){a=Ib(a);var d=a.includes("j")?sc(a,b):Wa.get(b);"function"!=typeof d&&Kb(`unknown function pointer with signature ${a}: ${b}`);return d}var uc=void 0;function vc(a){a=wc(a);var b=Ib(a);xc(a);return b}
function Dc(a,b){function d(m){h[m]||Ab[m]||(Bb[m]?Bb[m].forEach(d):(f.push(m),h[m]=!0))}var f=[],h={};b.forEach(d);throw new uc(`${a}: `+f.map(vc).join([", "]));}
function Ec(a,b,d,f,h){var m=b.length;2>m&&Kb("argTypes array size mismatch! Must at least get return value and 'this' types!");var u=null!==b[1]&&null!==d,n=!1;for(d=1;d<b.length;++d)if(null!==b[d]&&void 0===b[d].ee){n=!0;break}var p="void"!==b[0].name,v=m-2,E=Array(v),H=[],L=[];return function(){arguments.length!==v&&Kb(`function ${a} called with ${arguments.length} arguments, expected ${v} args!`);L.length=0;H.length=u?2:1;H[0]=h;if(u){var y=b[1].toWireType(L,this);H[1]=y}for(var N=0;N<v;++N)E[N]=
b[N+2].toWireType(L,arguments[N]),H.push(E[N]);N=f.apply(null,H);if(n)wb(L);else for(var T=u?1:2;T<b.length;T++){var R=1===T?y:E[T-2];null!==b[T].ee&&b[T].ee(R)}y=p?b[0].fromWireType(N):void 0;return y}}function Fc(a,b){for(var d=[],f=0;f<a;f++)d.push(Ta[b+4*f>>2]);return d}function Gc(){this.ke=[void 0];this.tf=[]}var Hc=new Gc;function Ic(a){a>=Hc.He&&0===--Hc.get(a).xf&&Hc.Cf(a)}
var Jc=a=>{a||Kb("Cannot use deleted val. handle = "+a);return Hc.get(a).value},oc=a=>{switch(a){case void 0:return 1;case null:return 2;case !0:return 3;case !1:return 4;default:return Hc.Bf({xf:1,value:a})}};function Kc(a,b,d){switch(b){case 0:return function(f){return this.fromWireType((d?Qa:K)[f])};case 1:return function(f){return this.fromWireType((d?Ra:Sa)[f>>1])};case 2:return function(f){return this.fromWireType((d?S:Ta)[f>>2])};default:throw new TypeError("Unknown integer type: "+a);}}
function Lc(a,b){var d=Ab[a];void 0===d&&Kb(b+" has unknown type "+vc(a));return d}function mc(a){if(null===a)return"null";var b=typeof a;return"object"===b||"array"===b||"function"===b?a.toString():""+a}function Mc(a,b){switch(b){case 2:return function(d){return this.fromWireType(X[d>>2])};case 3:return function(d){return this.fromWireType(Ua[d>>3])};default:throw new TypeError("Unknown float type: "+a);}}
function Nc(a,b,d){switch(b){case 0:return d?function(f){return Qa[f]}:function(f){return K[f]};case 1:return d?function(f){return Ra[f>>1]}:function(f){return Sa[f>>1]};case 2:return d?function(f){return S[f>>2]}:function(f){return Ta[f>>2]};default:throw new TypeError("Unknown integer type: "+a);}}
var qa=(a,b,d,f)=>{if(!(0<f))return 0;var h=d;f=d+f-1;for(var m=0;m<a.length;++m){var u=a.charCodeAt(m);if(55296<=u&&57343>=u){var n=a.charCodeAt(++m);u=65536+((u&1023)<<10)|n&1023}if(127>=u){if(d>=f)break;b[d++]=u}else{if(2047>=u){if(d+1>=f)break;b[d++]=192|u>>6}else{if(65535>=u){if(d+2>=f)break;b[d++]=224|u>>12}else{if(d+3>=f)break;b[d++]=240|u>>18;b[d++]=128|u>>12&63}b[d++]=128|u>>6&63}b[d++]=128|u&63}}b[d]=0;return d-h},pa=a=>{for(var b=0,d=0;d<a.length;++d){var f=a.charCodeAt(d);127>=f?b++:2047>=
f?b+=2:55296<=f&&57343>=f?(b+=4,++d):b+=3}return b},Oc="undefined"!=typeof TextDecoder?new TextDecoder("utf-16le"):void 0,Pc=(a,b)=>{var d=a>>1;for(var f=d+b/2;!(d>=f)&&Sa[d];)++d;d<<=1;if(32<d-a&&Oc)return Oc.decode(K.subarray(a,d));d="";for(f=0;!(f>=b/2);++f){var h=Ra[a+2*f>>1];if(0==h)break;d+=String.fromCharCode(h)}return d},Qc=(a,b,d)=>{void 0===d&&(d=2147483647);if(2>d)return 0;d-=2;var f=b;d=d<2*a.length?d/2:a.length;for(var h=0;h<d;++h)Ra[b>>1]=a.charCodeAt(h),b+=2;Ra[b>>1]=0;return b-f},
Rc=a=>2*a.length,Sc=(a,b)=>{for(var d=0,f="";!(d>=b/4);){var h=S[a+4*d>>2];if(0==h)break;++d;65536<=h?(h-=65536,f+=String.fromCharCode(55296|h>>10,56320|h&1023)):f+=String.fromCharCode(h)}return f},Tc=(a,b,d)=>{void 0===d&&(d=2147483647);if(4>d)return 0;var f=b;d=f+d-4;for(var h=0;h<a.length;++h){var m=a.charCodeAt(h);if(55296<=m&&57343>=m){var u=a.charCodeAt(++h);m=65536+((m&1023)<<10)|u&1023}S[b>>2]=m;b+=4;if(b+4>d)break}S[b>>2]=0;return b-f},Uc=a=>{for(var b=0,d=0;d<a.length;++d){var f=a.charCodeAt(d);
55296<=f&&57343>=f&&++d;b+=4}return b},Vc={};function Wc(a){var b=Vc[a];return void 0===b?Ib(a):b}var Xc=[];
function Yc(){function a(b){b.$$$embind_global$$$=b;var d="object"==typeof $$$embind_global$$$&&b.$$$embind_global$$$==b;d||delete b.$$$embind_global$$$;return d}if("object"==typeof globalThis)return globalThis;if("object"==typeof $$$embind_global$$$)return $$$embind_global$$$;"object"==typeof global&&a(global)?$$$embind_global$$$=global:"object"==typeof self&&a(self)&&($$$embind_global$$$=self);if("object"==typeof $$$embind_global$$$)return $$$embind_global$$$;throw Error("unable to get global object.");
}function Zc(a){var b=Xc.length;Xc.push(a);return b}function $c(a,b){for(var d=Array(a),f=0;f<a;++f)d[f]=Lc(Ta[b+4*f>>2],"parameter "+f);return d}var ad=[];function bd(a){var b=Array(a+1);return function(d,f,h){b[0]=d;for(var m=0;m<a;++m){var u=Lc(Ta[f+4*m>>2],"parameter "+m);b[m+1]=u.readValueFromPointer(h);h+=u.argPackAdvance}d=new (d.bind.apply(d,b));return oc(d)}}var cd={};
function dd(a){var b=a.getExtension("ANGLE_instanced_arrays");b&&(a.vertexAttribDivisor=function(d,f){b.vertexAttribDivisorANGLE(d,f)},a.drawArraysInstanced=function(d,f,h,m){b.drawArraysInstancedANGLE(d,f,h,m)},a.drawElementsInstanced=function(d,f,h,m,u){b.drawElementsInstancedANGLE(d,f,h,m,u)})}
function ed(a){var b=a.getExtension("OES_vertex_array_object");b&&(a.createVertexArray=function(){return b.createVertexArrayOES()},a.deleteVertexArray=function(d){b.deleteVertexArrayOES(d)},a.bindVertexArray=function(d){b.bindVertexArrayOES(d)},a.isVertexArray=function(d){return b.isVertexArrayOES(d)})}function fd(a){var b=a.getExtension("WEBGL_draw_buffers");b&&(a.drawBuffers=function(d,f){b.drawBuffersWEBGL(d,f)})}
var gd=1,hd=[],jd=[],kd=[],ld=[],ia=[],md=[],nd=[],oa=[],od=[],pd=[],qd={},rd={},sd=4;function td(a){ud||(ud=a)}function ha(a){for(var b=gd++,d=a.length;d<b;d++)a[d]=null;return b}function ja(a,b){a.He||(a.He=a.getContext,a.getContext=function(f,h){h=a.He(f,h);return"webgl"==f==h instanceof WebGLRenderingContext?h:null});var d=1<b.majorVersion?a.getContext("webgl2",b):a.getContext("webgl",b);return d?vd(d,b):0}
function vd(a,b){var d=ha(oa),f={handle:d,attributes:b,version:b.majorVersion,ge:a};a.canvas&&(a.canvas.Af=f);oa[d]=f;("undefined"==typeof b.Of||b.Of)&&yd(f);return d}function la(a){A=oa[a];w.vg=Z=A&&A.ge;return!(a&&!Z)}
function yd(a){a||(a=A);if(!a.Yf){a.Yf=!0;var b=a.ge;dd(b);ed(b);fd(b);b.qf=b.getExtension("WEBGL_draw_instanced_base_vertex_base_instance");b.vf=b.getExtension("WEBGL_multi_draw_instanced_base_vertex_base_instance");2<=a.version&&(b.rf=b.getExtension("EXT_disjoint_timer_query_webgl2"));if(2>a.version||!b.rf)b.rf=b.getExtension("EXT_disjoint_timer_query");b.wg=b.getExtension("WEBGL_multi_draw");(b.getSupportedExtensions()||[]).forEach(function(d){d.includes("lose_context")||d.includes("debug")||b.getExtension(d)})}}
var A,ud,zd={},Bd=()=>{if(!Ad){var a={USER:"web_user",LOGNAME:"web_user",PATH:"/",PWD:"/",HOME:"/home/web_user",LANG:("object"==typeof navigator&&navigator.languages&&navigator.languages[0]||"C").replace("-","_")+".UTF-8",_:ua||"./this.program"},b;for(b in zd)void 0===zd[b]?delete a[b]:a[b]=zd[b];var d=[];for(b in a)d.push(`${b}=${a[b]}`);Ad=d}return Ad},Ad,Cd=[null,[],[]];function Dd(a){Z.bindVertexArray(nd[a])}
function Ed(a,b){for(var d=0;d<a;d++){var f=S[b+4*d>>2];Z.deleteVertexArray(nd[f]);nd[f]=null}}var Fd=[];function Gd(a,b,d,f){Z.drawElements(a,b,d,f)}function Hd(a,b,d,f){for(var h=0;h<a;h++){var m=Z[d](),u=m&&ha(f);m?(m.name=u,f[u]=m):td(1282);S[b+4*h>>2]=u}}function Id(a,b){Hd(a,b,"createVertexArray",nd)}
function Jd(a,b,d){if(b){var f=void 0;switch(a){case 36346:f=1;break;case 36344:0!=d&&1!=d&&td(1280);return;case 34814:case 36345:f=0;break;case 34466:var h=Z.getParameter(34467);f=h?h.length:0;break;case 33309:if(2>A.version){td(1282);return}f=2*(Z.getSupportedExtensions()||[]).length;break;case 33307:case 33308:if(2>A.version){td(1280);return}f=33307==a?3:0}if(void 0===f)switch(h=Z.getParameter(a),typeof h){case "number":f=h;break;case "boolean":f=h?1:0;break;case "string":td(1280);return;case "object":if(null===
h)switch(a){case 34964:case 35725:case 34965:case 36006:case 36007:case 32873:case 34229:case 36662:case 36663:case 35053:case 35055:case 36010:case 35097:case 35869:case 32874:case 36389:case 35983:case 35368:case 34068:f=0;break;default:td(1280);return}else{if(h instanceof Float32Array||h instanceof Uint32Array||h instanceof Int32Array||h instanceof Array){for(a=0;a<h.length;++a)switch(d){case 0:S[b+4*a>>2]=h[a];break;case 2:X[b+4*a>>2]=h[a];break;case 4:Qa[b+a>>0]=h[a]?1:0}return}try{f=h.name|
0}catch(m){td(1280);Ha("GL_INVALID_ENUM in glGet"+d+"v: Unknown object returned from WebGL getParameter("+a+")! (error: "+m+")");return}}break;default:td(1280);Ha("GL_INVALID_ENUM in glGet"+d+"v: Native code calling glGet"+d+"v("+a+") and it returns "+h+" of type "+typeof h+"!");return}switch(d){case 1:d=f;Ta[b>>2]=d;Ta[b+4>>2]=(d-Ta[b>>2])/4294967296;break;case 0:S[b>>2]=f;break;case 2:X[b>>2]=f;break;case 4:Qa[b>>0]=f?1:0}}else td(1281)}var Ld=a=>{var b=pa(a)+1,d=Kd(b);d&&qa(a,K,d,b);return d};
function Md(a){return"]"==a.slice(-1)&&a.lastIndexOf("[")}function Nd(a){a-=5120;return 0==a?Qa:1==a?K:2==a?Ra:4==a?S:6==a?X:5==a||28922==a||28520==a||30779==a||30782==a?Ta:Sa}function Od(a,b,d,f,h){a=Nd(a);var m=31-Math.clz32(a.BYTES_PER_ELEMENT),u=sd;return a.subarray(h>>m,h+f*(d*({5:3,6:4,8:2,29502:3,29504:4,26917:2,26918:2,29846:3,29847:4}[b-6402]||1)*(1<<m)+u-1&-u)>>m)}
function Pd(a){var b=Z.Lf;if(b){var d=b.Oe[a];"number"==typeof d&&(b.Oe[a]=d=Z.getUniformLocation(b,b.yf[a]+(0<d?"["+d+"]":"")));return d}td(1282)}var Qd=[],Rd=[],Sd=a=>0===a%4&&(0!==a%100||0===a%400),Td=[31,29,31,30,31,30,31,31,30,31,30,31],Ud=[31,28,31,30,31,30,31,31,30,31,30,31];function Vd(a){var b=Array(pa(a)+1);qa(a,b,0,b.length);return b}
var Wd=(a,b,d,f)=>{function h(y,N,T){for(y="number"==typeof y?y.toString():y||"";y.length<N;)y=T[0]+y;return y}function m(y,N){return h(y,N,"0")}function u(y,N){function T(ta){return 0>ta?-1:0<ta?1:0}var R;0===(R=T(y.getFullYear()-N.getFullYear()))&&0===(R=T(y.getMonth()-N.getMonth()))&&(R=T(y.getDate()-N.getDate()));return R}function n(y){switch(y.getDay()){case 0:return new Date(y.getFullYear()-1,11,29);case 1:return y;case 2:return new Date(y.getFullYear(),0,3);case 3:return new Date(y.getFullYear(),
0,2);case 4:return new Date(y.getFullYear(),0,1);case 5:return new Date(y.getFullYear()-1,11,31);case 6:return new Date(y.getFullYear()-1,11,30)}}function p(y){var N=y.pe;for(y=new Date((new Date(y.qe+1900,0,1)).getTime());0<N;){var T=y.getMonth(),R=(Sd(y.getFullYear())?Td:Ud)[T];if(N>R-y.getDate())N-=R-y.getDate()+1,y.setDate(1),11>T?y.setMonth(T+1):(y.setMonth(0),y.setFullYear(y.getFullYear()+1));else{y.setDate(y.getDate()+N);break}}T=new Date(y.getFullYear()+1,0,4);N=n(new Date(y.getFullYear(),
0,4));T=n(T);return 0>=u(N,y)?0>=u(T,y)?y.getFullYear()+1:y.getFullYear():y.getFullYear()-1}var v=S[f+40>>2];f={qg:S[f>>2],pg:S[f+4>>2],$e:S[f+8>>2],kf:S[f+12>>2],af:S[f+16>>2],qe:S[f+20>>2],ie:S[f+24>>2],pe:S[f+28>>2],zg:S[f+32>>2],og:S[f+36>>2],rg:v?v?ub(K,v):"":""};d=d?ub(K,d):"";v={"%c":"%a %b %d %H:%M:%S %Y","%D":"%m/%d/%y","%F":"%Y-%m-%d","%h":"%b","%r":"%I:%M:%S %p","%R":"%H:%M","%T":"%H:%M:%S","%x":"%m/%d/%y","%X":"%H:%M:%S","%Ec":"%c","%EC":"%C","%Ex":"%m/%d/%y","%EX":"%H:%M:%S","%Ey":"%y",
"%EY":"%Y","%Od":"%d","%Oe":"%e","%OH":"%H","%OI":"%I","%Om":"%m","%OM":"%M","%OS":"%S","%Ou":"%u","%OU":"%U","%OV":"%V","%Ow":"%w","%OW":"%W","%Oy":"%y"};for(var E in v)d=d.replace(new RegExp(E,"g"),v[E]);var H="Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),L="January February March April May June July August September October November December".split(" ");v={"%a":y=>H[y.ie].substring(0,3),"%A":y=>H[y.ie],"%b":y=>L[y.af].substring(0,3),"%B":y=>L[y.af],"%C":y=>m((y.qe+1900)/
100|0,2),"%d":y=>m(y.kf,2),"%e":y=>h(y.kf,2," "),"%g":y=>p(y).toString().substring(2),"%G":y=>p(y),"%H":y=>m(y.$e,2),"%I":y=>{y=y.$e;0==y?y=12:12<y&&(y-=12);return m(y,2)},"%j":y=>{for(var N=0,T=0;T<=y.af-1;N+=(Sd(y.qe+1900)?Td:Ud)[T++]);return m(y.kf+N,3)},"%m":y=>m(y.af+1,2),"%M":y=>m(y.pg,2),"%n":()=>"\n","%p":y=>0<=y.$e&&12>y.$e?"AM":"PM","%S":y=>m(y.qg,2),"%t":()=>"\t","%u":y=>y.ie||7,"%U":y=>m(Math.floor((y.pe+7-y.ie)/7),2),"%V":y=>{var N=Math.floor((y.pe+7-(y.ie+6)%7)/7);2>=(y.ie+371-y.pe-
2)%7&&N++;if(N)53==N&&(T=(y.ie+371-y.pe)%7,4==T||3==T&&Sd(y.qe)||(N=1));else{N=52;var T=(y.ie+7-y.pe-1)%7;(4==T||5==T&&Sd(y.qe%400-1))&&N++}return m(N,2)},"%w":y=>y.ie,"%W":y=>m(Math.floor((y.pe+7-(y.ie+6)%7)/7),2),"%y":y=>(y.qe+1900).toString().substring(2),"%Y":y=>y.qe+1900,"%z":y=>{y=y.og;var N=0<=y;y=Math.abs(y)/60;return(N?"+":"-")+String("0000"+(y/60*100+y%60)).slice(-4)},"%Z":y=>y.rg,"%%":()=>"%"};d=d.replace(/%%/g,"\x00\x00");for(E in v)d.includes(E)&&(d=d.replace(new RegExp(E,"g"),v[E](f)));
d=d.replace(/\0\0/g,"%");E=Vd(d);if(E.length>b)return 0;Qa.set(E,a);return E.length-1};Cb=w.InternalError=class extends Error{constructor(a){super(a);this.name="InternalError"}};for(var Xd=Array(256),Yd=0;256>Yd;++Yd)Xd[Yd]=String.fromCharCode(Yd);Hb=Xd;Jb=w.BindingError=class extends Error{constructor(a){super(a);this.name="BindingError"}};
ec.prototype.isAliasOf=function(a){if(!(this instanceof ec&&a instanceof ec))return!1;var b=this.Kd.Wd.Qd,d=this.Kd.Sd,f=a.Kd.Wd.Qd;for(a=a.Kd.Sd;b.ae;)d=b.Pe(d),b=b.ae;for(;f.ae;)a=f.Pe(a),f=f.ae;return b===f&&d===a};
ec.prototype.clone=function(){this.Kd.Sd||Mb(this);if(this.Kd.Ne)return this.Kd.count.value+=1,this;var a=dc,b=Object,d=b.create,f=Object.getPrototypeOf(this),h=this.Kd;a=a(d.call(b,f,{Kd:{value:{count:h.count,Fe:h.Fe,Ne:h.Ne,Sd:h.Sd,Wd:h.Wd,Zd:h.Zd,fe:h.fe}}}));a.Kd.count.value+=1;a.Kd.Fe=!1;return a};ec.prototype["delete"]=function(){this.Kd.Sd||Mb(this);this.Kd.Fe&&!this.Kd.Ne&&Kb("Object already scheduled for deletion");Ob(this);Pb(this.Kd);this.Kd.Ne||(this.Kd.Zd=void 0,this.Kd.Sd=void 0)};
ec.prototype.isDeleted=function(){return!this.Kd.Sd};ec.prototype.deleteLater=function(){this.Kd.Sd||Mb(this);this.Kd.Fe&&!this.Kd.Ne&&Kb("Object already scheduled for deletion");Sb.push(this);1===Sb.length&&Ub&&Ub(Tb);this.Kd.Fe=!0;return this};w.getInheritedInstanceCount=function(){return Object.keys(Vb).length};w.getLiveInheritedInstances=function(){var a=[],b;for(b in Vb)Vb.hasOwnProperty(b)&&a.push(Vb[b]);return a};w.flushPendingDeletes=Tb;w.setDelayFunction=function(a){Ub=a;Sb.length&&Ub&&Ub(Tb)};
qc.prototype.Tf=function(a){this.wf&&(a=this.wf(a));return a};qc.prototype.pf=function(a){this.le&&this.le(a)};qc.prototype.argPackAdvance=8;qc.prototype.readValueFromPointer=xb;qc.prototype.deleteObject=function(a){if(null!==a)a["delete"]()};
qc.prototype.fromWireType=function(a){function b(){return this.We?cc(this.Qd.Ge,{Wd:this.cg,Sd:d,fe:this,Zd:a}):cc(this.Qd.Ge,{Wd:this,Sd:a})}var d=this.Tf(a);if(!d)return this.pf(a),null;var f=bc(this.Qd,d);if(void 0!==f){if(0===f.Kd.count.value)return f.Kd.Sd=d,f.Kd.Zd=a,f.clone();f=f.clone();this.pf(a);return f}f=this.Qd.Sf(d);f=Rb[f];if(!f)return b.call(this);f=this.Ve?f.Jf:f.pointerType;var h=Qb(d,this.Qd,f.Qd);return null===h?b.call(this):this.We?cc(f.Qd.Ge,{Wd:f,Sd:h,fe:this,Zd:a}):cc(f.Qd.Ge,
{Wd:f,Sd:h})};uc=w.UnboundTypeError=function(a,b){var d=gc(b,function(f){this.name=b;this.message=f;f=Error(f).stack;void 0!==f&&(this.stack=this.toString()+"\n"+f.replace(/^Error(:[^\n]*)?\n/,""))});d.prototype=Object.create(a.prototype);d.prototype.constructor=d;d.prototype.toString=function(){return void 0===this.message?this.name:`${this.name}: ${this.message}`};return d}(Error,"UnboundTypeError");
Object.assign(Gc.prototype,{get(a){return this.ke[a]},has(a){return void 0!==this.ke[a]},Bf(a){var b=this.tf.pop()||this.ke.length;this.ke[b]=a;return b},Cf(a){this.ke[a]=void 0;this.tf.push(a)}});Hc.ke.push({value:void 0},{value:null},{value:!0},{value:!1});Hc.He=Hc.ke.length;w.count_emval_handles=function(){for(var a=0,b=Hc.He;b<Hc.ke.length;++b)void 0!==Hc.ke[b]&&++a;return a};for(var Z,Zd=0;32>Zd;++Zd)Fd.push(Array(Zd));var $d=new Float32Array(288);
for(Zd=0;288>Zd;++Zd)Qd[Zd]=$d.subarray(0,Zd+1);var ae=new Int32Array(288);for(Zd=0;288>Zd;++Zd)Rd[Zd]=ae.subarray(0,Zd+1);
var qe={U:function(){return 0},Bb:()=>{},Db:function(){return 0},yb:()=>{},zb:()=>{},V:function(){},Ab:()=>{},E:function(a){var b=vb[a];delete vb[a];var d=b.jf,f=b.le,h=b.sf,m=h.map(u=>u.Wf).concat(h.map(u=>u.gg));Eb([a],m,u=>{var n={};h.forEach((p,v)=>{var E=u[v],H=p.Uf,L=p.Vf,y=u[v+h.length],N=p.fg,T=p.hg;n[p.Pf]={read:R=>E.fromWireType(H(L,R)),write:(R,ta)=>{var ma=[];N(T,R,y.toWireType(ma,ta));wb(ma)}}});return[{name:b.name,fromWireType:function(p){var v={},E;for(E in n)v[E]=n[E].read(p);f(p);
return v},toWireType:function(p,v){for(var E in n)if(!(E in v))throw new TypeError(`Missing field: "${E}"`);var H=d();for(E in n)n[E].write(H,v[E]);null!==p&&p.push(f,H);return H},argPackAdvance:8,readValueFromPointer:xb,ee:f}]})},qb:function(){},Hb:function(a,b,d,f,h){var m=Gb(d);b=Ib(b);Fb(a,{name:b,fromWireType:function(u){return!!u},toWireType:function(u,n){return n?f:h},argPackAdvance:8,readValueFromPointer:function(u){if(1===d)var n=Qa;else if(2===d)n=Ra;else if(4===d)n=S;else throw new TypeError("Unknown boolean type size: "+
b);return this.fromWireType(n[u>>m])},ee:null})},p:function(a,b,d,f,h,m,u,n,p,v,E,H,L){E=Ib(E);m=tc(h,m);n&&(n=tc(u,n));v&&(v=tc(p,v));L=tc(H,L);var y=fc(E);ic(y,function(){Dc(`Cannot construct ${E} due to unbound types`,[f])});Eb([a,b,d],f?[f]:[],function(N){N=N[0];if(f){var T=N.Qd;var R=T.Ge}else R=ec.prototype;N=gc(y,function(){if(Object.getPrototypeOf(this)!==ta)throw new Jb("Use 'new' to construct "+E);if(void 0===ma.oe)throw new Jb(E+" has no accessible constructor");var ib=ma.oe[arguments.length];
if(void 0===ib)throw new Jb(`Tried to invoke ctor of ${E} with invalid number of parameters (${arguments.length}) - expected (${Object.keys(ma.oe).toString()}) parameters instead!`);return ib.apply(this,arguments)});var ta=Object.create(R,{constructor:{value:N}});N.prototype=ta;var ma=new jc(E,N,ta,L,T,m,n,v);ma.ae&&(void 0===ma.ae.Qe&&(ma.ae.Qe=[]),ma.ae.Qe.push(ma));T=new qc(E,ma,!0,!1,!1);R=new qc(E+"*",ma,!1,!1,!1);var hb=new qc(E+" const*",ma,!1,!0,!1);Rb[a]={pointerType:R,Jf:hb};rc(y,N);return[T,
R,hb]})},i:function(a,b,d,f,h,m,u){var n=Fc(d,f);b=Ib(b);m=tc(h,m);Eb([],[a],function(p){function v(){Dc(`Cannot call ${E} due to unbound types`,n)}p=p[0];var E=`${p.name}.${b}`;b.startsWith("@@")&&(b=Symbol[b.substring(2)]);var H=p.Qd.constructor;void 0===H[b]?(v.De=d-1,H[b]=v):(hc(H,b,E),H[b].Yd[d-1]=v);Eb([],n,function(L){L=[L[0],null].concat(L.slice(1));L=Ec(E,L,null,m,u);void 0===H[b].Yd?(L.De=d-1,H[b]=L):H[b].Yd[d-1]=L;if(p.Qd.Qe)for(const y of p.Qd.Qe)y.constructor.hasOwnProperty(b)||(y.constructor[b]=
L);return[]});return[]})},G:function(a,b,d,f,h,m){var u=Fc(b,d);h=tc(f,h);Eb([],[a],function(n){n=n[0];var p=`constructor ${n.name}`;void 0===n.Qd.oe&&(n.Qd.oe=[]);if(void 0!==n.Qd.oe[b-1])throw new Jb(`Cannot register multiple constructors with identical number of parameters (${b-1}) for class '${n.name}'! Overload resolution is currently only performed using the parameter count, not actual type info!`);n.Qd.oe[b-1]=()=>{Dc(`Cannot construct ${n.name} due to unbound types`,u)};Eb([],u,function(v){v.splice(1,
0,null);n.Qd.oe[b-1]=Ec(p,v,null,h,m);return[]});return[]})},b:function(a,b,d,f,h,m,u,n){var p=Fc(d,f);b=Ib(b);m=tc(h,m);Eb([],[a],function(v){function E(){Dc(`Cannot call ${H} due to unbound types`,p)}v=v[0];var H=`${v.name}.${b}`;b.startsWith("@@")&&(b=Symbol[b.substring(2)]);n&&v.Qd.dg.push(b);var L=v.Qd.Ge,y=L[b];void 0===y||void 0===y.Yd&&y.className!==v.name&&y.De===d-2?(E.De=d-2,E.className=v.name,L[b]=E):(hc(L,b,H),L[b].Yd[d-2]=E);Eb([],p,function(N){N=Ec(H,N,v,m,u);void 0===L[b].Yd?(N.De=
d-2,L[b]=N):L[b].Yd[d-2]=N;return[]});return[]})},v:function(a,b,d){a=Ib(a);Eb([],[b],function(f){f=f[0];w[a]=f.fromWireType(d);return[]})},Gb:function(a,b){b=Ib(b);Fb(a,{name:b,fromWireType:function(d){var f=Jc(d);Ic(d);return f},toWireType:function(d,f){return oc(f)},argPackAdvance:8,readValueFromPointer:xb,ee:null})},l:function(a,b,d,f){function h(){}d=Gb(d);b=Ib(b);h.values={};Fb(a,{name:b,constructor:h,fromWireType:function(m){return this.constructor.values[m]},toWireType:function(m,u){return u.value},
argPackAdvance:8,readValueFromPointer:Kc(b,d,f),ee:null});ic(b,h)},d:function(a,b,d){var f=Lc(a,"enum");b=Ib(b);a=f.constructor;f=Object.create(f.constructor.prototype,{value:{value:d},constructor:{value:gc(`${f.name}_${b}`,function(){})}});a.values[d]=f;a[b]=f},X:function(a,b,d){d=Gb(d);b=Ib(b);Fb(a,{name:b,fromWireType:function(f){return f},toWireType:function(f,h){return h},argPackAdvance:8,readValueFromPointer:Mc(b,d),ee:null})},z:function(a,b,d,f,h,m){var u=Fc(b,d);a=Ib(a);h=tc(f,h);ic(a,function(){Dc(`Cannot call ${a} due to unbound types`,
u)},b-1);Eb([],u,function(n){n=[n[0],null].concat(n.slice(1));rc(a,Ec(a,n,null,h,m),b-1);return[]})},I:function(a,b,d,f,h){b=Ib(b);-1===h&&(h=4294967295);h=Gb(d);var m=n=>n;if(0===f){var u=32-8*d;m=n=>n<<u>>>u}d=b.includes("unsigned")?function(n,p){return p>>>0}:function(n,p){return p};Fb(a,{name:b,fromWireType:m,toWireType:d,argPackAdvance:8,readValueFromPointer:Nc(b,h,0!==f),ee:null})},w:function(a,b,d){function f(m){m>>=2;var u=Ta;return new h(u.buffer,u[m+1],u[m])}var h=[Int8Array,Uint8Array,
Int16Array,Uint16Array,Int32Array,Uint32Array,Float32Array,Float64Array][b];d=Ib(d);Fb(a,{name:d,fromWireType:f,argPackAdvance:8,readValueFromPointer:f},{Xf:!0})},s:function(a,b,d,f,h,m,u,n,p,v,E,H){d=Ib(d);m=tc(h,m);n=tc(u,n);v=tc(p,v);H=tc(E,H);Eb([a],[b],function(L){L=L[0];return[new qc(d,L.Qd,!1,!1,!0,L,f,m,n,v,H)]})},W:function(a,b){b=Ib(b);var d="std::string"===b;Fb(a,{name:b,fromWireType:function(f){var h=Ta[f>>2],m=f+4;if(d)for(var u=m,n=0;n<=h;++n){var p=m+n;if(n==h||0==K[p]){u=u?ub(K,u,
p-u):"";if(void 0===v)var v=u;else v+=String.fromCharCode(0),v+=u;u=p+1}}else{v=Array(h);for(n=0;n<h;++n)v[n]=String.fromCharCode(K[m+n]);v=v.join("")}xc(f);return v},toWireType:function(f,h){h instanceof ArrayBuffer&&(h=new Uint8Array(h));var m="string"==typeof h;m||h instanceof Uint8Array||h instanceof Uint8ClampedArray||h instanceof Int8Array||Kb("Cannot pass non-string to std::string");var u=d&&m?pa(h):h.length;var n=Kd(4+u+1),p=n+4;Ta[n>>2]=u;if(d&&m)qa(h,K,p,u+1);else if(m)for(m=0;m<u;++m){var v=
h.charCodeAt(m);255<v&&(xc(p),Kb("String has UTF-16 code units that do not fit in 8 bits"));K[p+m]=v}else for(m=0;m<u;++m)K[p+m]=h[m];null!==f&&f.push(xc,n);return n},argPackAdvance:8,readValueFromPointer:xb,ee:function(f){xc(f)}})},O:function(a,b,d){d=Ib(d);if(2===b){var f=Pc;var h=Qc;var m=Rc;var u=()=>Sa;var n=1}else 4===b&&(f=Sc,h=Tc,m=Uc,u=()=>Ta,n=2);Fb(a,{name:d,fromWireType:function(p){for(var v=Ta[p>>2],E=u(),H,L=p+4,y=0;y<=v;++y){var N=p+4+y*b;if(y==v||0==E[N>>n])L=f(L,N-L),void 0===H?H=
L:(H+=String.fromCharCode(0),H+=L),L=N+b}xc(p);return H},toWireType:function(p,v){"string"!=typeof v&&Kb(`Cannot pass non-string to C++ string type ${d}`);var E=m(v),H=Kd(4+E+b);Ta[H>>2]=E>>n;h(v,H+4,E+b);null!==p&&p.push(xc,H);return H},argPackAdvance:8,readValueFromPointer:xb,ee:function(p){xc(p)}})},F:function(a,b,d,f,h,m){vb[a]={name:Ib(b),jf:tc(d,f),le:tc(h,m),sf:[]}},f:function(a,b,d,f,h,m,u,n,p,v){vb[a].sf.push({Pf:Ib(b),Wf:d,Uf:tc(f,h),Vf:m,gg:u,fg:tc(n,p),hg:v})},Ib:function(a,b){b=Ib(b);
Fb(a,{Zf:!0,name:b,argPackAdvance:0,fromWireType:function(){},toWireType:function(){}})},Fb:()=>!0,ub:()=>{throw Infinity;},C:function(a,b,d){a=Jc(a);b=Lc(b,"emval::as");var f=[],h=oc(f);Ta[d>>2]=h;return b.toWireType(f,a)},K:function(a,b,d,f,h){a=Xc[a];b=Jc(b);d=Wc(d);var m=[];Ta[f>>2]=oc(m);return a(b,d,m,h)},r:function(a,b,d,f){a=Xc[a];b=Jc(b);d=Wc(d);a(b,d,null,f)},c:Ic,J:function(a){if(0===a)return oc(Yc());a=Wc(a);return oc(Yc()[a])},q:function(a,b){var d=$c(a,b),f=d[0];b=f.name+"_$"+d.slice(1).map(function(u){return u.name}).join("_")+
"$";var h=ad[b];if(void 0!==h)return h;var m=Array(a-1);h=Zc((u,n,p,v)=>{for(var E=0,H=0;H<a-1;++H)m[H]=d[H+1].readValueFromPointer(v+E),E+=d[H+1].argPackAdvance;u=u[n].apply(u,m);for(H=0;H<a-1;++H)d[H+1].Mf&&d[H+1].Mf(m[H]);if(!f.Zf)return f.toWireType(p,u)});return ad[b]=h},B:function(a,b){a=Jc(a);b=Jc(b);return oc(a[b])},n:function(a){4<a&&(Hc.get(a).xf+=1)},L:function(a,b,d,f){a=Jc(a);var h=cd[b];h||(h=bd(b),cd[b]=h);return h(a,d,f)},D:function(){return oc([])},e:function(a){return oc(Wc(a))},
x:function(){return oc({})},kb:function(a){a=Jc(a);return!a},A:function(a){var b=Jc(a);wb(b);Ic(a)},g:function(a,b,d){a=Jc(a);b=Jc(b);d=Jc(d);a[b]=d},h:function(a,b){a=Lc(a,"_emval_take_value");a=a.readValueFromPointer(b);return oc(a)},nb:function(){return-52},ob:function(){},a:()=>{La("")},Eb:()=>performance.now(),vb:a=>{var b=K.length;a>>>=0;if(2147483648<a)return!1;for(var d=1;4>=d;d*=2){var f=b*(1+.2/d);f=Math.min(f,a+100663296);var h=Math;f=Math.max(a,f);a:{h=h.min.call(h,2147483648,f+(65536-
f%65536)%65536)-Ma.buffer.byteLength+65535>>>16;try{Ma.grow(h);Va();var m=1;break a}catch(u){}m=void 0}if(m)return!0}return!1},lb:function(){return A?A.handle:0},wb:(a,b)=>{var d=0;Bd().forEach(function(f,h){var m=b+d;h=Ta[a+4*h>>2]=m;for(m=0;m<f.length;++m)Qa[h++>>0]=f.charCodeAt(m);Qa[h>>0]=0;d+=f.length+1});return 0},xb:(a,b)=>{var d=Bd();Ta[a>>2]=d.length;var f=0;d.forEach(function(h){f+=h.length+1});Ta[b>>2]=f;return 0},Jb:a=>{if(!noExitRuntime){if(w.onExit)w.onExit(a);Pa=!0}va(a,new rb(a))},
N:()=>52,mb:function(){return 52},Cb:()=>52,pb:function(){return 70},T:(a,b,d,f)=>{for(var h=0,m=0;m<d;m++){var u=Ta[b>>2],n=Ta[b+4>>2];b+=8;for(var p=0;p<n;p++){var v=K[u+p],E=Cd[a];0===v||10===v?((1===a?Ga:Ha)(ub(E,0)),E.length=0):E.push(v)}h+=n}Ta[f>>2]=h;return 0},aa:function(a){Z.activeTexture(a)},ba:function(a,b){Z.attachShader(jd[a],md[b])},ca:function(a,b,d){Z.bindAttribLocation(jd[a],b,d?ub(K,d):"")},da:function(a,b){35051==a?Z.ff=b:35052==a&&(Z.Ee=b);Z.bindBuffer(a,hd[b])},$:function(a,
b){Z.bindFramebuffer(a,kd[b])},fc:function(a,b){Z.bindRenderbuffer(a,ld[b])},Rb:function(a,b){Z.bindSampler(a,od[b])},ea:function(a,b){Z.bindTexture(a,ia[b])},zc:Dd,Cc:Dd,fa:function(a,b,d,f){Z.blendColor(a,b,d,f)},ga:function(a){Z.blendEquation(a)},ha:function(a,b){Z.blendFunc(a,b)},$b:function(a,b,d,f,h,m,u,n,p,v){Z.blitFramebuffer(a,b,d,f,h,m,u,n,p,v)},ia:function(a,b,d,f){2<=A.version?d&&b?Z.bufferData(a,K,f,d,b):Z.bufferData(a,b,f):Z.bufferData(a,d?K.subarray(d,d+b):b,f)},ja:function(a,b,d,f){2<=
A.version?d&&Z.bufferSubData(a,b,K,f,d):Z.bufferSubData(a,b,K.subarray(f,f+d))},gc:function(a){return Z.checkFramebufferStatus(a)},R:function(a){Z.clear(a)},_:function(a,b,d,f){Z.clearColor(a,b,d,f)},S:function(a){Z.clearStencil(a)},sb:function(a,b,d,f){return Z.clientWaitSync(pd[a],b,(d>>>0)+4294967296*f)},ka:function(a,b,d,f){Z.colorMask(!!a,!!b,!!d,!!f)},la:function(a){Z.compileShader(md[a])},ma:function(a,b,d,f,h,m,u,n){2<=A.version?Z.Ee||!u?Z.compressedTexImage2D(a,b,d,f,h,m,u,n):Z.compressedTexImage2D(a,
b,d,f,h,m,K,n,u):Z.compressedTexImage2D(a,b,d,f,h,m,n?K.subarray(n,n+u):null)},na:function(a,b,d,f,h,m,u,n,p){2<=A.version?Z.Ee||!n?Z.compressedTexSubImage2D(a,b,d,f,h,m,u,n,p):Z.compressedTexSubImage2D(a,b,d,f,h,m,u,K,p,n):Z.compressedTexSubImage2D(a,b,d,f,h,m,u,p?K.subarray(p,p+n):null)},Zb:function(a,b,d,f,h){Z.copyBufferSubData(a,b,d,f,h)},oa:function(a,b,d,f,h,m,u,n){Z.copyTexSubImage2D(a,b,d,f,h,m,u,n)},pa:function(){var a=ha(jd),b=Z.createProgram();b.name=a;b.Ze=b.Xe=b.Ye=0;b.lf=1;jd[a]=b;
return a},qa:function(a){var b=ha(md);md[b]=Z.createShader(a);return b},ra:function(a){Z.cullFace(a)},sa:function(a,b){for(var d=0;d<a;d++){var f=S[b+4*d>>2],h=hd[f];h&&(Z.deleteBuffer(h),h.name=0,hd[f]=null,f==Z.ff&&(Z.ff=0),f==Z.Ee&&(Z.Ee=0))}},hc:function(a,b){for(var d=0;d<a;++d){var f=S[b+4*d>>2],h=kd[f];h&&(Z.deleteFramebuffer(h),h.name=0,kd[f]=null)}},ta:function(a){if(a){var b=jd[a];b?(Z.deleteProgram(b),b.name=0,jd[a]=null):td(1281)}},ic:function(a,b){for(var d=0;d<a;d++){var f=S[b+4*d>>
2],h=ld[f];h&&(Z.deleteRenderbuffer(h),h.name=0,ld[f]=null)}},Sb:function(a,b){for(var d=0;d<a;d++){var f=S[b+4*d>>2],h=od[f];h&&(Z.deleteSampler(h),h.name=0,od[f]=null)}},ua:function(a){if(a){var b=md[a];b?(Z.deleteShader(b),md[a]=null):td(1281)}},_b:function(a){if(a){var b=pd[a];b?(Z.deleteSync(b),b.name=0,pd[a]=null):td(1281)}},va:function(a,b){for(var d=0;d<a;d++){var f=S[b+4*d>>2],h=ia[f];h&&(Z.deleteTexture(h),h.name=0,ia[f]=null)}},Ac:Ed,Dc:Ed,wa:function(a){Z.depthMask(!!a)},xa:function(a){Z.disable(a)},
ya:function(a){Z.disableVertexAttribArray(a)},za:function(a,b,d){Z.drawArrays(a,b,d)},xc:function(a,b,d,f){Z.drawArraysInstanced(a,b,d,f)},vc:function(a,b,d,f,h){Z.qf.drawArraysInstancedBaseInstanceWEBGL(a,b,d,f,h)},tc:function(a,b){for(var d=Fd[a],f=0;f<a;f++)d[f]=S[b+4*f>>2];Z.drawBuffers(d)},Aa:Gd,yc:function(a,b,d,f,h){Z.drawElementsInstanced(a,b,d,f,h)},wc:function(a,b,d,f,h,m,u){Z.qf.drawElementsInstancedBaseVertexBaseInstanceWEBGL(a,b,d,f,h,m,u)},nc:function(a,b,d,f,h,m){Gd(a,f,h,m)},Ba:function(a){Z.enable(a)},
Ca:function(a){Z.enableVertexAttribArray(a)},Xb:function(a,b){return(a=Z.fenceSync(a,b))?(b=ha(pd),a.name=b,pd[b]=a,b):0},Da:function(){Z.finish()},Ea:function(){Z.flush()},jc:function(a,b,d,f){Z.framebufferRenderbuffer(a,b,d,ld[f])},kc:function(a,b,d,f,h){Z.framebufferTexture2D(a,b,d,ia[f],h)},Fa:function(a){Z.frontFace(a)},Ga:function(a,b){Hd(a,b,"createBuffer",hd)},lc:function(a,b){Hd(a,b,"createFramebuffer",kd)},mc:function(a,b){Hd(a,b,"createRenderbuffer",ld)},Tb:function(a,b){Hd(a,b,"createSampler",
od)},Ha:function(a,b){Hd(a,b,"createTexture",ia)},Bc:Id,Ec:Id,bc:function(a){Z.generateMipmap(a)},Ia:function(a,b,d){d?S[d>>2]=Z.getBufferParameter(a,b):td(1281)},Ja:function(){var a=Z.getError()||ud;ud=0;return a},Ka:function(a,b){Jd(a,b,2)},cc:function(a,b,d,f){a=Z.getFramebufferAttachmentParameter(a,b,d);if(a instanceof WebGLRenderbuffer||a instanceof WebGLTexture)a=a.name|0;S[f>>2]=a},M:function(a,b){Jd(a,b,0)},La:function(a,b,d,f){a=Z.getProgramInfoLog(jd[a]);null===a&&(a="(unknown error)");
b=0<b&&f?qa(a,K,f,b):0;d&&(S[d>>2]=b)},Ma:function(a,b,d){if(d)if(a>=gd)td(1281);else if(a=jd[a],35716==b)a=Z.getProgramInfoLog(a),null===a&&(a="(unknown error)"),S[d>>2]=a.length+1;else if(35719==b){if(!a.Ze)for(b=0;b<Z.getProgramParameter(a,35718);++b)a.Ze=Math.max(a.Ze,Z.getActiveUniform(a,b).name.length+1);S[d>>2]=a.Ze}else if(35722==b){if(!a.Xe)for(b=0;b<Z.getProgramParameter(a,35721);++b)a.Xe=Math.max(a.Xe,Z.getActiveAttrib(a,b).name.length+1);S[d>>2]=a.Xe}else if(35381==b){if(!a.Ye)for(b=0;b<
Z.getProgramParameter(a,35382);++b)a.Ye=Math.max(a.Ye,Z.getActiveUniformBlockName(a,b).length+1);S[d>>2]=a.Ye}else S[d>>2]=Z.getProgramParameter(a,b);else td(1281)},dc:function(a,b,d){d?S[d>>2]=Z.getRenderbufferParameter(a,b):td(1281)},Na:function(a,b,d,f){a=Z.getShaderInfoLog(md[a]);null===a&&(a="(unknown error)");b=0<b&&f?qa(a,K,f,b):0;d&&(S[d>>2]=b)},Ob:function(a,b,d,f){a=Z.getShaderPrecisionFormat(a,b);S[d>>2]=a.rangeMin;S[d+4>>2]=a.rangeMax;S[f>>2]=a.precision},Oa:function(a,b,d){d?35716==b?
(a=Z.getShaderInfoLog(md[a]),null===a&&(a="(unknown error)"),S[d>>2]=a?a.length+1:0):35720==b?(a=Z.getShaderSource(md[a]),S[d>>2]=a?a.length+1:0):S[d>>2]=Z.getShaderParameter(md[a],b):td(1281)},Q:function(a){var b=qd[a];if(!b){switch(a){case 7939:b=Z.getSupportedExtensions()||[];b=b.concat(b.map(function(f){return"GL_"+f}));b=Ld(b.join(" "));break;case 7936:case 7937:case 37445:case 37446:(b=Z.getParameter(a))||td(1280);b=b&&Ld(b);break;case 7938:b=Z.getParameter(7938);b=2<=A.version?"OpenGL ES 3.0 ("+
b+")":"OpenGL ES 2.0 ("+b+")";b=Ld(b);break;case 35724:b=Z.getParameter(35724);var d=b.match(/^WebGL GLSL ES ([0-9]\.[0-9][0-9]?)(?:$| .*)/);null!==d&&(3==d[1].length&&(d[1]+="0"),b="OpenGL ES GLSL ES "+d[1]+" ("+b+")");b=Ld(b);break;default:td(1280)}qd[a]=b}return b},jb:function(a,b){if(2>A.version)return td(1282),0;var d=rd[a];if(d)return 0>b||b>=d.length?(td(1281),0):d[b];switch(a){case 7939:return d=Z.getSupportedExtensions()||[],d=d.concat(d.map(function(f){return"GL_"+f})),d=d.map(function(f){return Ld(f)}),
d=rd[a]=d,0>b||b>=d.length?(td(1281),0):d[b];default:return td(1280),0}},Pa:function(a,b){b=b?ub(K,b):"";if(a=jd[a]){var d=a,f=d.Oe,h=d.zf,m;if(!f)for(d.Oe=f={},d.yf={},m=0;m<Z.getProgramParameter(d,35718);++m){var u=Z.getActiveUniform(d,m);var n=u.name;u=u.size;var p=Md(n);p=0<p?n.slice(0,p):n;var v=d.lf;d.lf+=u;h[p]=[u,v];for(n=0;n<u;++n)f[v]=n,d.yf[v++]=p}d=a.Oe;f=0;h=b;m=Md(b);0<m&&(f=parseInt(b.slice(m+1))>>>0,h=b.slice(0,m));if((h=a.zf[h])&&f<h[0]&&(f+=h[1],d[f]=d[f]||Z.getUniformLocation(a,
b)))return f}else td(1281);return-1},Pb:function(a,b,d){for(var f=Fd[b],h=0;h<b;h++)f[h]=S[d+4*h>>2];Z.invalidateFramebuffer(a,f)},Qb:function(a,b,d,f,h,m,u){for(var n=Fd[b],p=0;p<b;p++)n[p]=S[d+4*p>>2];Z.invalidateSubFramebuffer(a,n,f,h,m,u)},Yb:function(a){return Z.isSync(pd[a])},Qa:function(a){return(a=ia[a])?Z.isTexture(a):0},Ra:function(a){Z.lineWidth(a)},Sa:function(a){a=jd[a];Z.linkProgram(a);a.Oe=0;a.zf={}},rc:function(a,b,d,f,h,m){Z.vf.multiDrawArraysInstancedBaseInstanceWEBGL(a,S,b>>2,S,
d>>2,S,f>>2,Ta,h>>2,m)},sc:function(a,b,d,f,h,m,u,n){Z.vf.multiDrawElementsInstancedBaseVertexBaseInstanceWEBGL(a,S,b>>2,d,S,f>>2,S,h>>2,S,m>>2,Ta,u>>2,n)},Ta:function(a,b){3317==a&&(sd=b);Z.pixelStorei(a,b)},uc:function(a){Z.readBuffer(a)},Ua:function(a,b,d,f,h,m,u){if(2<=A.version)if(Z.ff)Z.readPixels(a,b,d,f,h,m,u);else{var n=Nd(m);Z.readPixels(a,b,d,f,h,m,n,u>>31-Math.clz32(n.BYTES_PER_ELEMENT))}else(u=Od(m,h,d,f,u))?Z.readPixels(a,b,d,f,h,m,u):td(1280)},ec:function(a,b,d,f){Z.renderbufferStorage(a,
b,d,f)},ac:function(a,b,d,f,h){Z.renderbufferStorageMultisample(a,b,d,f,h)},Ub:function(a,b,d){Z.samplerParameterf(od[a],b,d)},Vb:function(a,b,d){Z.samplerParameteri(od[a],b,d)},Wb:function(a,b,d){Z.samplerParameteri(od[a],b,S[d>>2])},Va:function(a,b,d,f){Z.scissor(a,b,d,f)},Wa:function(a,b,d,f){for(var h="",m=0;m<b;++m){var u=f?S[f+4*m>>2]:-1,n=S[d+4*m>>2];u=n?ub(K,n,0>u?void 0:u):"";h+=u}Z.shaderSource(md[a],h)},Xa:function(a,b,d){Z.stencilFunc(a,b,d)},Ya:function(a,b,d,f){Z.stencilFuncSeparate(a,
b,d,f)},Za:function(a){Z.stencilMask(a)},_a:function(a,b){Z.stencilMaskSeparate(a,b)},$a:function(a,b,d){Z.stencilOp(a,b,d)},ab:function(a,b,d,f){Z.stencilOpSeparate(a,b,d,f)},bb:function(a,b,d,f,h,m,u,n,p){if(2<=A.version)if(Z.Ee)Z.texImage2D(a,b,d,f,h,m,u,n,p);else if(p){var v=Nd(n);Z.texImage2D(a,b,d,f,h,m,u,n,v,p>>31-Math.clz32(v.BYTES_PER_ELEMENT))}else Z.texImage2D(a,b,d,f,h,m,u,n,null);else Z.texImage2D(a,b,d,f,h,m,u,n,p?Od(n,u,f,h,p):null)},cb:function(a,b,d){Z.texParameterf(a,b,d)},db:function(a,
b,d){Z.texParameterf(a,b,X[d>>2])},eb:function(a,b,d){Z.texParameteri(a,b,d)},fb:function(a,b,d){Z.texParameteri(a,b,S[d>>2])},oc:function(a,b,d,f,h){Z.texStorage2D(a,b,d,f,h)},gb:function(a,b,d,f,h,m,u,n,p){if(2<=A.version)if(Z.Ee)Z.texSubImage2D(a,b,d,f,h,m,u,n,p);else if(p){var v=Nd(n);Z.texSubImage2D(a,b,d,f,h,m,u,n,v,p>>31-Math.clz32(v.BYTES_PER_ELEMENT))}else Z.texSubImage2D(a,b,d,f,h,m,u,n,null);else v=null,p&&(v=Od(n,u,h,m,p)),Z.texSubImage2D(a,b,d,f,h,m,u,n,v)},hb:function(a,b){Z.uniform1f(Pd(a),
b)},ib:function(a,b,d){if(2<=A.version)b&&Z.uniform1fv(Pd(a),X,d>>2,b);else{if(288>=b)for(var f=Qd[b-1],h=0;h<b;++h)f[h]=X[d+4*h>>2];else f=X.subarray(d>>2,d+4*b>>2);Z.uniform1fv(Pd(a),f)}},Zc:function(a,b){Z.uniform1i(Pd(a),b)},_c:function(a,b,d){if(2<=A.version)b&&Z.uniform1iv(Pd(a),S,d>>2,b);else{if(288>=b)for(var f=Rd[b-1],h=0;h<b;++h)f[h]=S[d+4*h>>2];else f=S.subarray(d>>2,d+4*b>>2);Z.uniform1iv(Pd(a),f)}},$c:function(a,b,d){Z.uniform2f(Pd(a),b,d)},ad:function(a,b,d){if(2<=A.version)b&&Z.uniform2fv(Pd(a),
X,d>>2,2*b);else{if(144>=b)for(var f=Qd[2*b-1],h=0;h<2*b;h+=2)f[h]=X[d+4*h>>2],f[h+1]=X[d+(4*h+4)>>2];else f=X.subarray(d>>2,d+8*b>>2);Z.uniform2fv(Pd(a),f)}},Yc:function(a,b,d){Z.uniform2i(Pd(a),b,d)},Xc:function(a,b,d){if(2<=A.version)b&&Z.uniform2iv(Pd(a),S,d>>2,2*b);else{if(144>=b)for(var f=Rd[2*b-1],h=0;h<2*b;h+=2)f[h]=S[d+4*h>>2],f[h+1]=S[d+(4*h+4)>>2];else f=S.subarray(d>>2,d+8*b>>2);Z.uniform2iv(Pd(a),f)}},Wc:function(a,b,d,f){Z.uniform3f(Pd(a),b,d,f)},Vc:function(a,b,d){if(2<=A.version)b&&
Z.uniform3fv(Pd(a),X,d>>2,3*b);else{if(96>=b)for(var f=Qd[3*b-1],h=0;h<3*b;h+=3)f[h]=X[d+4*h>>2],f[h+1]=X[d+(4*h+4)>>2],f[h+2]=X[d+(4*h+8)>>2];else f=X.subarray(d>>2,d+12*b>>2);Z.uniform3fv(Pd(a),f)}},Uc:function(a,b,d,f){Z.uniform3i(Pd(a),b,d,f)},Tc:function(a,b,d){if(2<=A.version)b&&Z.uniform3iv(Pd(a),S,d>>2,3*b);else{if(96>=b)for(var f=Rd[3*b-1],h=0;h<3*b;h+=3)f[h]=S[d+4*h>>2],f[h+1]=S[d+(4*h+4)>>2],f[h+2]=S[d+(4*h+8)>>2];else f=S.subarray(d>>2,d+12*b>>2);Z.uniform3iv(Pd(a),f)}},Sc:function(a,
b,d,f,h){Z.uniform4f(Pd(a),b,d,f,h)},Rc:function(a,b,d){if(2<=A.version)b&&Z.uniform4fv(Pd(a),X,d>>2,4*b);else{if(72>=b){var f=Qd[4*b-1],h=X;d>>=2;for(var m=0;m<4*b;m+=4){var u=d+m;f[m]=h[u];f[m+1]=h[u+1];f[m+2]=h[u+2];f[m+3]=h[u+3]}}else f=X.subarray(d>>2,d+16*b>>2);Z.uniform4fv(Pd(a),f)}},Fc:function(a,b,d,f,h){Z.uniform4i(Pd(a),b,d,f,h)},Gc:function(a,b,d){if(2<=A.version)b&&Z.uniform4iv(Pd(a),S,d>>2,4*b);else{if(72>=b)for(var f=Rd[4*b-1],h=0;h<4*b;h+=4)f[h]=S[d+4*h>>2],f[h+1]=S[d+(4*h+4)>>2],
f[h+2]=S[d+(4*h+8)>>2],f[h+3]=S[d+(4*h+12)>>2];else f=S.subarray(d>>2,d+16*b>>2);Z.uniform4iv(Pd(a),f)}},Hc:function(a,b,d,f){if(2<=A.version)b&&Z.uniformMatrix2fv(Pd(a),!!d,X,f>>2,4*b);else{if(72>=b)for(var h=Qd[4*b-1],m=0;m<4*b;m+=4)h[m]=X[f+4*m>>2],h[m+1]=X[f+(4*m+4)>>2],h[m+2]=X[f+(4*m+8)>>2],h[m+3]=X[f+(4*m+12)>>2];else h=X.subarray(f>>2,f+16*b>>2);Z.uniformMatrix2fv(Pd(a),!!d,h)}},Ic:function(a,b,d,f){if(2<=A.version)b&&Z.uniformMatrix3fv(Pd(a),!!d,X,f>>2,9*b);else{if(32>=b)for(var h=Qd[9*b-
1],m=0;m<9*b;m+=9)h[m]=X[f+4*m>>2],h[m+1]=X[f+(4*m+4)>>2],h[m+2]=X[f+(4*m+8)>>2],h[m+3]=X[f+(4*m+12)>>2],h[m+4]=X[f+(4*m+16)>>2],h[m+5]=X[f+(4*m+20)>>2],h[m+6]=X[f+(4*m+24)>>2],h[m+7]=X[f+(4*m+28)>>2],h[m+8]=X[f+(4*m+32)>>2];else h=X.subarray(f>>2,f+36*b>>2);Z.uniformMatrix3fv(Pd(a),!!d,h)}},Jc:function(a,b,d,f){if(2<=A.version)b&&Z.uniformMatrix4fv(Pd(a),!!d,X,f>>2,16*b);else{if(18>=b){var h=Qd[16*b-1],m=X;f>>=2;for(var u=0;u<16*b;u+=16){var n=f+u;h[u]=m[n];h[u+1]=m[n+1];h[u+2]=m[n+2];h[u+3]=m[n+
3];h[u+4]=m[n+4];h[u+5]=m[n+5];h[u+6]=m[n+6];h[u+7]=m[n+7];h[u+8]=m[n+8];h[u+9]=m[n+9];h[u+10]=m[n+10];h[u+11]=m[n+11];h[u+12]=m[n+12];h[u+13]=m[n+13];h[u+14]=m[n+14];h[u+15]=m[n+15]}}else h=X.subarray(f>>2,f+64*b>>2);Z.uniformMatrix4fv(Pd(a),!!d,h)}},Kc:function(a){a=jd[a];Z.useProgram(a);Z.Lf=a},Lc:function(a,b){Z.vertexAttrib1f(a,b)},Mc:function(a,b){Z.vertexAttrib2f(a,X[b>>2],X[b+4>>2])},Nc:function(a,b){Z.vertexAttrib3f(a,X[b>>2],X[b+4>>2],X[b+8>>2])},Oc:function(a,b){Z.vertexAttrib4f(a,X[b>>
2],X[b+4>>2],X[b+8>>2],X[b+12>>2])},pc:function(a,b){Z.vertexAttribDivisor(a,b)},qc:function(a,b,d,f,h){Z.vertexAttribIPointer(a,b,d,f,h)},Pc:function(a,b,d,f,h,m){Z.vertexAttribPointer(a,b,d,!!f,h,m)},Qc:function(a,b,d,f){Z.viewport(a,b,d,f)},rb:function(a,b,d,f){Z.waitSync(pd[a],b,(d>>>0)+4294967296*f)},j:be,m:ce,k:de,H:ee,Lb:fe,Z:ge,Y:he,P:ie,o:je,y:ke,u:le,t:me,Kb:ne,Mb:oe,Nb:pe,tb:(a,b,d,f)=>Wd(a,b,d,f)};
(function(){function a(d){Q=d=d.exports;Ma=Q.bd;Va();Wa=Q.ed;Ya.unshift(Q.cd);db--;w.monitorRunDependencies&&w.monitorRunDependencies(db);if(0==db&&(null!==eb&&(clearInterval(eb),eb=null),fb)){var f=fb;fb=null;f()}return d}var b={a:qe};db++;w.monitorRunDependencies&&w.monitorRunDependencies(db);if(w.instantiateWasm)try{return w.instantiateWasm(b,a)}catch(d){Ha("Module.instantiateWasm callback failed with error: "+d),ba(d)}qb(b,function(d){a(d.instance)}).catch(ba);return{}})();
var Kd=w._malloc=a=>(Kd=w._malloc=Q.dd)(a),xc=w._free=a=>(xc=w._free=Q.fd)(a),wc=a=>(wc=Q.gd)(a);w.__embind_initialize_bindings=()=>(w.__embind_initialize_bindings=Q.hd)();var re=(a,b)=>(re=Q.id)(a,b),se=()=>(se=Q.jd)(),te=a=>(te=Q.kd)(a);w.dynCall_viji=(a,b,d,f,h)=>(w.dynCall_viji=Q.ld)(a,b,d,f,h);w.dynCall_vijiii=(a,b,d,f,h,m,u)=>(w.dynCall_vijiii=Q.md)(a,b,d,f,h,m,u);w.dynCall_viiiiij=(a,b,d,f,h,m,u,n)=>(w.dynCall_viiiiij=Q.nd)(a,b,d,f,h,m,u,n);
w.dynCall_jiiiijiiiii=(a,b,d,f,h,m,u,n,p,v,E,H)=>(w.dynCall_jiiiijiiiii=Q.od)(a,b,d,f,h,m,u,n,p,v,E,H);w.dynCall_viiij=(a,b,d,f,h,m)=>(w.dynCall_viiij=Q.pd)(a,b,d,f,h,m);w.dynCall_jii=(a,b,d)=>(w.dynCall_jii=Q.qd)(a,b,d);w.dynCall_vij=(a,b,d,f)=>(w.dynCall_vij=Q.rd)(a,b,d,f);w.dynCall_iiij=(a,b,d,f,h)=>(w.dynCall_iiij=Q.sd)(a,b,d,f,h);w.dynCall_iiiij=(a,b,d,f,h,m)=>(w.dynCall_iiiij=Q.td)(a,b,d,f,h,m);w.dynCall_viij=(a,b,d,f,h)=>(w.dynCall_viij=Q.ud)(a,b,d,f,h);
w.dynCall_ji=(a,b)=>(w.dynCall_ji=Q.vd)(a,b);w.dynCall_iij=(a,b,d,f)=>(w.dynCall_iij=Q.wd)(a,b,d,f);w.dynCall_jiiiii=(a,b,d,f,h,m)=>(w.dynCall_jiiiii=Q.xd)(a,b,d,f,h,m);w.dynCall_jiiiiii=(a,b,d,f,h,m,u)=>(w.dynCall_jiiiiii=Q.yd)(a,b,d,f,h,m,u);w.dynCall_jiiiiji=(a,b,d,f,h,m,u,n)=>(w.dynCall_jiiiiji=Q.zd)(a,b,d,f,h,m,u,n);w.dynCall_iijj=(a,b,d,f,h,m)=>(w.dynCall_iijj=Q.Ad)(a,b,d,f,h,m);w.dynCall_iiiji=(a,b,d,f,h,m)=>(w.dynCall_iiiji=Q.Bd)(a,b,d,f,h,m);
w.dynCall_iiji=(a,b,d,f,h)=>(w.dynCall_iiji=Q.Cd)(a,b,d,f,h);w.dynCall_iijjiii=(a,b,d,f,h,m,u,n,p)=>(w.dynCall_iijjiii=Q.Dd)(a,b,d,f,h,m,u,n,p);w.dynCall_vijjjii=(a,b,d,f,h,m,u,n,p,v)=>(w.dynCall_vijjjii=Q.Ed)(a,b,d,f,h,m,u,n,p,v);w.dynCall_jiji=(a,b,d,f,h)=>(w.dynCall_jiji=Q.Fd)(a,b,d,f,h);w.dynCall_viijii=(a,b,d,f,h,m,u)=>(w.dynCall_viijii=Q.Gd)(a,b,d,f,h,m,u);w.dynCall_iiiiij=(a,b,d,f,h,m,u)=>(w.dynCall_iiiiij=Q.Hd)(a,b,d,f,h,m,u);
w.dynCall_iiiiijj=(a,b,d,f,h,m,u,n,p)=>(w.dynCall_iiiiijj=Q.Id)(a,b,d,f,h,m,u,n,p);w.dynCall_iiiiiijj=(a,b,d,f,h,m,u,n,p,v)=>(w.dynCall_iiiiiijj=Q.Jd)(a,b,d,f,h,m,u,n,p,v);function ce(a,b,d){var f=se();try{return Wa.get(a)(b,d)}catch(h){te(f);if(h!==h+0)throw h;re(1,0)}}function me(a,b,d,f,h){var m=se();try{Wa.get(a)(b,d,f,h)}catch(u){te(m);if(u!==u+0)throw u;re(1,0)}}function ee(a,b,d,f,h){var m=se();try{return Wa.get(a)(b,d,f,h)}catch(u){te(m);if(u!==u+0)throw u;re(1,0)}}
function de(a,b,d,f){var h=se();try{return Wa.get(a)(b,d,f)}catch(m){te(h);if(m!==m+0)throw m;re(1,0)}}function be(a,b){var d=se();try{return Wa.get(a)(b)}catch(f){te(d);if(f!==f+0)throw f;re(1,0)}}function je(a,b){var d=se();try{Wa.get(a)(b)}catch(f){te(d);if(f!==f+0)throw f;re(1,0)}}function le(a,b,d,f){var h=se();try{Wa.get(a)(b,d,f)}catch(m){te(h);if(m!==m+0)throw m;re(1,0)}}function ke(a,b,d){var f=se();try{Wa.get(a)(b,d)}catch(h){te(f);if(h!==h+0)throw h;re(1,0)}}
function pe(a,b,d,f,h,m,u,n,p,v){var E=se();try{Wa.get(a)(b,d,f,h,m,u,n,p,v)}catch(H){te(E);if(H!==H+0)throw H;re(1,0)}}function ie(a){var b=se();try{Wa.get(a)()}catch(d){te(b);if(d!==d+0)throw d;re(1,0)}}function oe(a,b,d,f,h,m,u){var n=se();try{Wa.get(a)(b,d,f,h,m,u)}catch(p){te(n);if(p!==p+0)throw p;re(1,0)}}function fe(a,b,d,f,h,m){var u=se();try{return Wa.get(a)(b,d,f,h,m)}catch(n){te(u);if(n!==n+0)throw n;re(1,0)}}
function ge(a,b,d,f,h,m,u){var n=se();try{return Wa.get(a)(b,d,f,h,m,u)}catch(p){te(n);if(p!==p+0)throw p;re(1,0)}}function ne(a,b,d,f,h,m){var u=se();try{Wa.get(a)(b,d,f,h,m)}catch(n){te(u);if(n!==n+0)throw n;re(1,0)}}function he(a,b,d,f,h,m,u,n,p,v){var E=se();try{return Wa.get(a)(b,d,f,h,m,u,n,p,v)}catch(H){te(E);if(H!==H+0)throw H;re(1,0)}}var ue;fb=function ve(){ue||we();ue||(fb=ve)};
function we(){function a(){if(!ue&&(ue=!0,w.calledRun=!0,!Pa)){sb(Ya);aa(w);if(w.onRuntimeInitialized)w.onRuntimeInitialized();if(w.postRun)for("function"==typeof w.postRun&&(w.postRun=[w.postRun]);w.postRun.length;){var b=w.postRun.shift();Za.unshift(b)}sb(Za)}}if(!(0<db)){if(w.preRun)for("function"==typeof w.preRun&&(w.preRun=[w.preRun]);w.preRun.length;)cb();sb(Xa);0<db||(w.setStatus?(w.setStatus("Running..."),setTimeout(function(){setTimeout(function(){w.setStatus("")},1);a()},1)):a())}}
if(w.preInit)for("function"==typeof w.preInit&&(w.preInit=[w.preInit]);0<w.preInit.length;)w.preInit.pop()();we();


  return moduleArg.ready
}

);
})();
if (typeof exports === 'object' && typeof module === 'object')
  module.exports = CanvasKitInit;
else if (typeof define === 'function' && define['amd'])
  define([], () => CanvasKitInit);
