import { Dimensions, LayoutChangeEvent } from "react-native";
import {
  Easing,
  FadeIn,
  FadeInRight,
  FadeInUp,
  FadeOut,
  FadeOutDown,
  runOnJS,
  useAnimatedStyle,
  withTiming,
} from "react-native-reanimated";
import { Pressable } from "../generic/Pressable";
import { Box } from "../generic/Box";
import { Text } from "../generic/Text";
import * as Haptics from "expo-haptics";
import { memo, useCallback, useState } from "react";

interface ILetterProps {
  onPress?: () => void;
  letter?: string;
  x: number;
  isInitial?: boolean;
  isAdded: boolean;
  width: number;
}

export const SPACE_BETWEEN_LETTERS_TOP = 0.035;

const LETTER_HITSLOP = { top: 10, bottom: 10, left: 5, right: 5 };

export const Letter = memo(
  ({ letter, isAdded, isInitial, x, width, onPress }: ILetterProps) => {
    const [inTransit, setInTransit] = useState<boolean>(false);

    if (!letter) return <Box flex={1} />;

    const onPressed = useCallback(() => {
      if (!onPress) return;
      setInTransit(true);

      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy);
      onPress?.();
    }, [onPress]);

    const [letterHeight, setLetterHeight] = useState<number>(0);
    const onLayout = useCallback((e: LayoutChangeEvent) => {
      setLetterHeight(e.nativeEvent.layout.height);
    }, []);

    // @ts-ignore
    const style = useAnimatedStyle(() => {
      return {
        position: "absolute",
        width: width,
        height: "90%",
        top: "115%",
        transform: [
          {
            translateX: withTiming(x, { easing: Easing.out(Easing.ease) }),
          },
          {
            translateY: withTiming(
              isAdded ? -letterHeight * 1.225 : 0,
              { easing: Easing.out(Easing.ease) },
              () => {
                runOnJS(setInTransit)(false);
              }
            ),
          },
        ],
      };
    });

    return (
      <Pressable
        flex={1}
        style={style}
        isAnimated={true}
        onLayout={onLayout}
        pressedOpacity={0.9}
        enableAnimation={true}
        pointerEvents={inTransit ? "none" : "box-none"}
        onPress={onPressed}
        hitSlop={LETTER_HITSLOP}
      >
        <Box
          entering={isInitial ? undefined : FadeInUp.duration(300)}
          p={2}
          h="100%"
        >
          <Box
            br={10}
            flex={1}
            h="100%"
            w="100%"
            bg={`#c77b56`}
            borderTopLeftRadius={20}
            borderTopRightRadius={20}
          >
            <Box
              mb={4}
              br={10}
              flex={1}
              h="100%"
              bg={`#fdca8b`}
              alignItems="center"
              justifyContent="center"
              borderBottomLeftRadius={8}
              borderBottomRightRadius={8}
            >
              <Text fontWeight={"realbold"} size={22}>
                {letter}
              </Text>
            </Box>
          </Box>
        </Box>
      </Pressable>
    );
  }
);
