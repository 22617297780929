import { ViewStyle } from "react-native";
import { IBoxProps } from "../components/generic/Box";

export const getStyleProps = (props: IBoxProps): ViewStyle => {
  const style = {
    marginLeft: props.mh ?? props.ml ?? undefined,
    marginRight: props.mh ?? props.mr ?? undefined,
    marginTop: props.mv ?? props.mt ?? undefined,
    marginBottom: props.mv ?? props.mb ?? undefined,
    overflow: props.overflow ?? undefined,
    padding: props.p ?? undefined,
    gap: props.gap ?? undefined,
    opacity: props.opacity ?? undefined,
    paddingTop: props.pt ?? props.py ?? undefined,
    flexWrap: props.flexWrap ?? undefined,
    paddingBottom: props.pb ?? props.py ?? undefined,
    minWidth: props.minWidth ?? undefined,
    cursor: props.cursor ?? undefined,
    borderBottomWidth: props.borderBottomWidth ?? undefined,
    paddingLeft: props.pl ?? props.px ?? undefined,
    maxWidth: props.maxWidth ?? undefined,
    maxHeight: props.maxHeight ?? undefined,
    minHeight: props.minHeight ?? undefined,
    paddingRight: props.pr ?? props.px ?? undefined,
    backgroundColor: props.bg ?? undefined,
    alignItems: props.center ? "center" : props.alignItems ?? undefined,
    justifyContent: props.center ? "center" : props.justifyContent ?? undefined,
    zIndex: props.zIndex ?? undefined,
    flexDirection: props.flexDirection ?? undefined,
    height: props.h ?? undefined,
    borderRadius: props.br ?? undefined,
    width: props.w ?? undefined,
    elevation: props.elevation ?? undefined,
    position: props.position ?? undefined,
    flex: props.flex ?? undefined,
    aspectRatio: props.aspectRatio ?? undefined,
    left: props.left ?? undefined,
    top: props.top ?? undefined,
    bottom: props.bottom ?? undefined,
    right: props.right ?? undefined,
    borderWidth: props.borderWidth ?? undefined,
    borderColor: props.borderColor ?? undefined,
    borderRightWidth: props.borderRightWidth ?? undefined,

    borderTopLeftRadius: props.borderTopLeftRadius ?? undefined,
    borderTopRightRadius: props.borderTopRightRadius ?? undefined,
    borderBottomLeftRadius: props.borderBottomLeftRadius ?? undefined,
    borderBottomRightRadius: props.borderBottomRightRadius ?? undefined,
    ...(props.style ?? {}),
  };

  return Object.fromEntries(
    Object.entries(style).filter(([key, value]) => {
      return value !== undefined;
    })
  );
};
