import { memo } from "react";
import { Box } from "../components/generic/Box";
import { GradientBackground } from "../components/GradientBackground";
import { Image } from "../components/generic/Image";
import { useWindowDimensions } from "react-native";
import { Text } from "../components/generic/Text";
import { COLORS } from "../constants/colors";
import { Button } from "../components/generic/Button";
import { GameButton } from "../components/game/GameButton";
import { useNavigation } from "@react-navigation/native";

const w = 0.5;
export const CreditsScreen = memo(() => {
  const { width } = useWindowDimensions();
  const { goBack } = useNavigation();

  return (
    <>
      <GradientBackground />

      <Box
        flex={1}
        justifyContent="center"
        // bg="red"
        alignItems="center"
        w="100%"
      >
        {/* <Image
          source={require("./credits/sophia.png")}
          w={width * w}
          h={width * w * (900 / 700)}
          resizeMode="contain"
          br={10}
        /> */}
        <Box px={20} alignItems="center" justifyContent="center" pt={40}>
          <Box>
            <Text
              size={24}
              mt={20}
              align="center"
              color={"#f5d19c"}
              fontWeight="bold"
            >
              21 Words
            </Text>

            <Text size={18} color={"#f5d19c"} mt={10} align="center">
              Well done Sophia for being the first winner of 21 Words!
            </Text>

            <Box flexDirection="row" mt={30}>
              <GameButton
                color={COLORS.primary3}
                label="Go back"
                onPress={() => {
                  goBack();
                }}
                br={800}
                px={80}
                textSize={18}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
});
