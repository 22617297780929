import React, { ReactNode, memo } from "react";
import { Box } from "./generic/Box";
import { COLORS } from "../constants/colors";
import { Text } from "./generic/Text";
import { IPressableProps } from "./generic/Pressable";

interface IProfileStat extends IPressableProps {
  label?: string;
  value?: string | number | ReactNode;
  icon?: ReactNode;
  isLoading?: boolean;
  textColor?: string;
  onPress?: () => void;
  bg?: string;
  color?: string;
}

export const Stat = memo(
  ({
    isLoading,
    onPress,
    icon,
    textColor,
    color = COLORS.primary,
    label,
    bg = `${COLORS.primary2}11`,
    value,
    ...props
  }: IProfileStat) => {
    return (
      <Box
        pr={label ? (!icon ? 15 : 10) : 0}
        pl={value === undefined ? 15 : 0}
        mr={10}
        mb={10}
        // flex={1}
        ml={0}
        flex={1}
        justifyContent="space-between"
        py={8}
        br={5}
        bg={bg}
        flexDirection="row"
        center={true}
        {...props}
      >
        {value !== undefined ? (
          <Box flexDirection="row" alignItems="center" px={10}>
            <Text
              isLoading={isLoading}
              renderTextWhenLoading={true}
              color={color}
              size={18}
              w={40}
              align="left"
              fontWeight="bold"
            >
              {value ?? 0}
            </Text>
          </Box>
        ) : null}
        {label ? (
          <Box flexDirection="row" center={true}>
            <Text
              isLoading={isLoading}
              color={textColor ?? COLORS.text[700]}
              renderTextWhenLoading={true}
              size={14}
              align="center"
            >
              {label}
            </Text>
          </Box>
        ) : null}

        {icon}
      </Box>
    );
  }
);
