import LottieView from "lottie-react-native";
import React, { memo, useMemo } from "react";

import { IBoxProps } from "./Box";
import { getStyleProps } from "../../util/style";

interface ILoadingProps extends IBoxProps {
  isInverted?: boolean;
  type?: string;
}

const LOADING_INVERTED = require("../../../assets/animations/loading-invert.json");
const LOADING_PRIMARY = require("../../../assets/animations/loading-primary.json");
const LOADING = require("../../../assets/animations/loading-normal.json");

export const Loading = memo(({ isInverted, type, ...props }: ILoadingProps) => {
  const style = useMemo(() => {
    return getStyleProps(props);
  }, [props]);

  const typeStyle = useMemo(() => {
    return LOADING_INVERTED;
    // if (type === "primary") {
    //   return LOADING_PRIMARY;
    // }

    // return isInverted ? LOADING_INVERTED : LOADING;
  }, [type, isInverted]);

  return (
    <>
      <LottieView
        speed={0.8}
        style={style}
        source={typeStyle}
        autoPlay={true}
        loop={true}
      />
    </>
  );
});
