import { memo, useCallback, useEffect, useState } from "react";
import { getFormattedTimeDuration } from "../../util/time";

interface ITimeCounterProps {
  time?: string;
  getText?: (time: string) => string;
}

export const TimeCounter = memo(
  ({ time = "", getText = getFormattedTimeDuration }: ITimeCounterProps) => {
    const [timeString, setTimeString] = useState<string>(getText(time));
    const updateTime = useCallback(() => {
      setTimeString(getText(time));
    }, [time]);

    useEffect(() => {
      const interval = setInterval(() => {
        updateTime();
      }, 1000);

      return () => clearInterval(interval);
    }, [updateTime]);

    return <>{timeString}</>;
  }
);
