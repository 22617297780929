import React, { useMemo, useEffect } from "react";
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withRepeat,
  withTiming,
  Easing,
} from "react-native-reanimated";

import { Box, IBoxProps } from "./Box";
import { getStyleProps } from "../../util/style";
import { COLORS } from "../../constants/colors";

interface ISkeletonProps extends IBoxProps {
  children?: JSX.Element;
  isLoading?: boolean;
}

const startColor = `${COLORS.primary}`;
const endColor = `rgba(255,255,255,.05)`;

export const Skeleton = ({
  children,
  br,
  isLoading,
  style,
  ...props
}: ISkeletonProps) => {
  // Setting blink Animation
  const blinkAnim = useSharedValue(0);

  const boxStyles = useMemo(() => {
    const propStyles = getStyleProps(props);
    return {
      opacity: 0.5,
      ...propStyles,
      ...style,
    };
  }, [props]);

  useEffect(() => {
    blinkAnim.value = withRepeat(
      withTiming(blinkAnim.value ? 0 : 0.1, {
        duration: 400,
        easing: Easing.ease,
      }),
      -1,
      true
    );
  }, []);

  const skeletonStyle = useAnimatedStyle(() => {
    return {
      height: "100%",
      width: "100%",
      borderRadius: br ?? 0,
      backgroundColor: startColor,
      opacity: blinkAnim.value,
    };
  });

  return (
    <>
      {/* @ts-ignore */}
      <Box bg={endColor} br={br} {...boxStyles}>
        <Animated.View style={skeletonStyle} />
        {!isLoading ? children : null}
      </Box>
    </>
  );
};
