import { Feather } from "@expo/vector-icons";
import {
  Canvas,
  RadialGradient,
  RoundedRect,
  vec,
} from "@shopify/react-native-skia";
import * as StoreReview from "expo-store-review";
import React, { memo, useCallback, useState } from "react";
import {
  Dimensions,
  GestureResponderEvent,
  Linking,
  Platform,
} from "react-native";
import {
  FadeIn,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";

import { Box } from "../generic/Box";
import { Button } from "../generic/Button";
import { Text } from "../generic/Text";
import { Pressable } from "../generic/Pressable";
import { COLORS } from "../../constants/colors";
import { isWeb } from "../../util/platform";
import { useScreenDimensions } from "../../hooks/useScreenDimensions";

interface IEditProfileModalProps {
  onClose?: () => void;
}

export const RateModal = memo(({ onClose }: IEditProfileModalProps) => {
  const transformX = useSharedValue(0);
  const scale = useSharedValue(1);
  const transformY = useSharedValue(0);
  const [stage, setStage] = useState<number>(0);

  const { width, height } = useScreenDimensions();

  const handleSubmit = useCallback(
    async (event: GestureResponderEvent) => {
      // transformY.value = event.nativeEvent.locationY;
      if (stage === 0) {
        setStage(1);
        transformX.value = event.nativeEvent.pageX < width / 2 ? 40 : 80;
        transformY.value = 70;
        // scale.value = 0.7;
      } else if (stage === 1) {
        scale.value = 0.3;
        transformY.value = 200;
        transformX.value = -100;

        setTimeout(() => {
          setStage(3);
          scale.value = 1;
        }, 1000);

        setTimeout(() => {
          if (onClose) {
            onClose();
          }
        }, 3000);
      }
    },
    [stage]
  );

  // @ts-ignore
  const buttonStyles = useAnimatedStyle(() => {
    const ANIMATION_TIME = 300;
    return {
      transform: [
        {
          translateX: withTiming(transformX.value, {
            duration: ANIMATION_TIME,
          }),
        },
        {
          translateY: withTiming(transformY.value, {
            duration: ANIMATION_TIME,
          }),
        },
        {
          scale: withTiming(scale.value, { duration: ANIMATION_TIME }),
        },
      ],
    };
  });

  const handleRate = useCallback(async () => {
    if (Platform.OS === "ios") {
      Linking.openURL(
        `https://apps.apple.com/app/apple-store/id6504304763?action=write-review`
      );
    } else {
      const openAndroid = () =>
        Linking.openURL(
          `market://details?id=io.manolis.words69&showAllReviews=true`
        );

      openAndroid();
    }
  }, []);

  return (
    <Box entering={FadeIn.duration(500)}>
      <Box
        position={isWeb ? "fixed" : "absolute"}
        left={0}
        opacity={1}
        cursor="default"
        bg="rgba(0,0,0,.95)"
        top={0}
        w={width}
        h={height}
      >
        <Canvas style={{ width, height }} pointerEvents="none">
          <RoundedRect r={0} x={0} y={0} width={width} height={height}>
            <RadialGradient
              c={vec(70, 300 + 100)}
              r={600}
              colors={[`${COLORS.primary2}33`, "transparent"]}
            />
          </RoundedRect>

          <RoundedRect r={0} x={0} y={0} width={width} height={height}>
            <RadialGradient
              c={vec(width, 0)}
              r={600}
              colors={[`${COLORS.primary}33`, "transparent"]}
            />
          </RoundedRect>
        </Canvas>
      </Box>
      <Box
        minHeight={isWeb ? undefined : height / 1.5}
        alignItems="center"
        justifyContent="center"
        pointerEvents="box-none"
        px={20}
        w="100%"
        flexDirection="row"
      >
        <Box
          w="100%"
          p={10}
          flex={1}
          bg="#00222e"
          br={10}
          pointerEvents="box-none"
          center={true}
        >
          <Pressable
            w="100%"
            p={20}
            enableAnimation={true}
            py={20}
            br={10}
            alignItems="center"
            onPress={handleRate}
            justifyContent="space-between"
            bg="#003548"
            flexDirection="row"
          >
            <Box w="80%">
              <Box flexDirection="row">
                <Text
                  color={COLORS.primary2}
                  size={20}
                  mb={2}
                  fontWeight="bold"
                  align="left"
                >
                  Rate 21 Words
                </Text>
              </Box>
              <Text color={COLORS.text[700]} size={14} fontWeight="regular">
                Please help grow the game by leaving a review 🙏
              </Text>
            </Box>
            <Box>
              <Feather name="chevron-right" color={COLORS.primary2} size={30} />
            </Box>
          </Pressable>

          <Box
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            w="100%"
          >
            <Box
              justifyContent="center"
              flexDirection="column"
              minHeight={50}
              py={5}
              pt={20}
              alignItems="center"
            >
              <Box style={buttonStyles} isAnimated={true}>
                <Button
                  onPressIn={handleSubmit}
                  w={200}
                  style="whiteOutline"
                  px={0}
                  py={5}
                  borderWidth={0}
                  isLoading={false}
                  br={100}
                  enableAnimation={false}
                  label={stage === 3 ? "Just joking 😉" : "No thanks"}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});
