import { Alert } from "react-native";
import { COLORS } from "../constants/colors";
import { Box } from "./generic/Box";
import { Button } from "./generic/Button";
import { Pressable } from "./generic/Pressable";
import { Text } from "./generic/Text";
import { useCallback, useState } from "react";
import { StyleSheet } from "react-native";
import { registerForPushNotificationsAsync } from "../util/notifications";
import LottieView from "lottie-react-native";
import { useSettingUpdate } from "../hooks/useSettingsUpdate";
import { isWeb } from "../util/platform";

interface INotificationModal {
  onClose?: () => void;
}

export const NotificationModal = ({ onClose }: INotificationModal) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { mutate: updateSettings } = useSettingUpdate("enableNotifications");
  const { mutate: updateHasBeenAsked } = useSettingUpdate(
    "hasBeenAskedForNotifications"
  );

  const handleSubmit = useCallback(async () => {
    try {
      setIsLoading(true);

      const pushToken = await registerForPushNotificationsAsync();
      if (pushToken) {
        updateSettings(pushToken);
        onClose?.();
        // await registerPush(
        //   {
        //     data: { pushToken },
        //   },
        //   {
        //     onSuccess: () => {
        //       updateSettings(true);

        //       if (onClose) onClose();
        //     },
        //   }
        // );
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    } finally {
    }
  }, []);

  const onIdiotUser = () => {
    Alert.alert(
      "No worries!",
      "You can always enable notifications later in settings!",
      [
        {
          isPreferred: true,
          text: "Cancel",
          onPress: () => {},
          style: "cancel",
        },
        {
          text: "Continue",

          onPress: () => {
            updateHasBeenAsked(true);

            if (onClose) onClose();
          },
        },
      ]
    );
  };

  return (
    <Box
      //   w="60%"
      p={20}
      flex={1}
      //   bg={`${COLORS.primary2}22`}
      br={10}
      center={true}
    >
      {!isWeb ? (
        <Box pb={15} pt={15}>
          <LottieView
            speed={0.8}
            style={styles.lottie}
            source={require("../../assets/animations/notification.json")}
            autoPlay={true}
            loop={true}
          />
        </Box>
      ) : null}
      <Box justifyContent="center" alignItems="center" mb={30} mt={5}>
        <Box flexDirection="row">
          <Text
            color={COLORS.primary3}
            size={20}
            mb={10}
            fontWeight="bold"
            align="center"
          >
            Let's do this daily!
          </Text>
        </Box>
        <Text
          color={COLORS.text[800]}
          size={14}
          px={"10%"}
          fontWeight="medium"
          align="center"
        >
          Want to get reminded when it's time to play your daily challenge?
        </Text>
      </Box>
      <Box
        justifyContent="center"
        w="100%"
        px={20}
        flexDirection="column"
        mt={isWeb ? 10 : 0}
        mb={20}
        alignItems="center"
      >
        <Button
          w="100%"
          onPress={handleSubmit}
          borderWidth={0}
          bg={`${COLORS.primary3}22`}
          style="outline"
          textColor={`${COLORS.primary3}`}
          py={20}
          isLoading={isLoading}
          br={100}
          label="Enable notifications"
        />
        <Pressable onPress={onIdiotUser} enableAnimation={true}>
          <Text color={COLORS.text[600]} mt={15}>
            Not now
          </Text>
        </Pressable>
      </Box>
    </Box>
  );
};

const styles = StyleSheet.create({
  lottie: {
    height: 250,
    width: 250,
    marginTop: -20,
    marginBottom: -40,
  },
});
