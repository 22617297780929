import { EvilIcons } from "@expo/vector-icons";
import React, { ReactNode, memo, useEffect } from "react";
import { Dimensions } from "react-native";
import { FadeIn, FadeOut } from "react-native-reanimated";

import { Box, IBoxProps } from "./Box";
import { Pressable } from "./Pressable";
import { COLORS } from "../../constants/colors";
import { useBackHandler } from "../../hooks/useBackHandler/useBackHandler";

interface IModalProps extends IBoxProps {
  children: ReactNode;
  isOpen?: boolean;
  isClosable?: boolean;
  autoNavbar?: boolean;
  hasClose?: boolean;
  center?: boolean;
  onClose?: () => void;
}

const { width, height } = { width: "100%", height: "100%" };
export const Modal = memo(
  ({
    children,
    center = true,
    isClosable = true,
    isOpen,
    autoNavbar = true,
    hasClose,
    bg = "rgba(0,0,0,.98)",
    onClose,
    ...props
  }: IModalProps) => {
    useBackHandler(() => {
      if (isOpen) {
        if (onClose) onClose();
        return true;
      }

      return false;
    });

    if (!isOpen) return null;
    return (
      <Box
        position="absolute"
        entering={FadeIn.duration(150)}
        exiting={FadeOut.duration(150)}
        w={width}
        h={height}
        {...props}
      >
        <Pressable
          w={width}
          h={height}
          position="absolute"
          alignItems="center"
          bg={bg}
          justifyContent="center"
          onPress={() => {
            if (!isClosable) return;

            if (onClose) onClose();
          }}
        >
          <Box w="100%" h="100%" center={center}>
            <Pressable w="100%" pointerEvents="box-none" center={center}>
              {children}
            </Pressable>
          </Box>
          {hasClose ? (
            <Pressable
              position="absolute"
              right={20}
              top={40}
              onPress={onClose}
              hitSlop={20}
            >
              <EvilIcons name="close" size={20} color={COLORS.text[700]} />
            </Pressable>
          ) : null}
        </Pressable>
      </Box>
    );
  }
);
