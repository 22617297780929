import AsyncStorage from "@react-native-async-storage/async-storage";
import { useQuery } from "react-query";

interface IGameStreak {
  streak: {
    streak: number;
    lastDate: string;
  };
}

export const useGameStreak = () =>
  useQuery<IGameStreak>("game-streak", async () => {
    try {
      const jsonValue = await AsyncStorage.getItem("game-streak");
      return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (e) {
      console.error("Error fetching game streak", e);
      return null;
    }
  });
