import { WORD_LIST } from "../constants/word-list";
import { WORD_LIST_PICKABLE } from "../constants/word-list-pickable";
import { getRandomElementsFromArray } from "./random";

export const shuffleWord = (word: string) => {
  if (word.length <= 1 || new Set(word).size === 1) {
    return word;
  }

  const characters = [...word];
  let shuffled;

  do {
    shuffled = characters
      .map((char) => ({ char, sortKey: Math.random() }))
      .sort((a, b) => a.sortKey - b.sortKey)
      .map(({ char }) => char)
      .join("");
  } while (shuffled === word);

  return shuffled;
};

function selectWordsByLength({
  wordSet,
  length,
  count,
  seed,
}: {
  wordSet: Set<string>;
  length: number;
  count: number;
  seed: number;
}): string[] {
  const words = Array.from(wordSet).filter((word) => word.length === length);
  return getRandomElementsFromArray({ array: words, count, seed });
}

function selectWordsWithSeed({
  wordSet,
  seed,
}: {
  wordSet: Set<string>;
  seed: Date;
}): string[] {
  const numericSeed = seed.getTime();

  const selectedWords: string[] = [
    ...selectWordsByLength({ wordSet, length: 4, count: 5, seed: numericSeed }),
    ...selectWordsByLength({
      wordSet,
      length: 5,
      count: 7,
      seed: numericSeed + 5,
    }),
    ...selectWordsByLength({
      wordSet,
      length: 6,
      count: 6,
      seed: numericSeed + 12,
    }),
    ...selectWordsByLength({
      wordSet,
      length: 7,
      count: 3,
      seed: numericSeed + 19,
    }),
  ];
  return selectedWords;
}

export const getWordsForDate = (date: Date) => {
  return selectWordsWithSeed({ wordSet: WORD_LIST_PICKABLE, seed: date });
};
