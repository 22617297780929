import AsyncStorage from "@react-native-async-storage/async-storage";
import { useMutation, useQueryClient } from "react-query";
import { useCurrentGame } from "./useCurrentGame";

export interface IGameState {
  date: string;
  correctWords: string[];
  failingWord?: string;
  isGameComplete: boolean;
}

export const useSetCurrentGame = () => {
  const queryClient = useQueryClient();
  const { data: gameState } = useCurrentGame();
  return useMutation(
    async (newGameState: Partial<IGameState>) => {
      await AsyncStorage.setItem(
        "game-state",
        JSON.stringify({ ...gameState, ...newGameState })
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("game-state");
      },
    }
  );
};
