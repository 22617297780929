import { ReactNode, useCallback } from "react";
import { Button, IButtonProps } from "../generic/Button";
import { COLORS } from "../../constants/colors";
import * as Haptics from "expo-haptics";

interface IGameButtonProps extends IButtonProps {
  label: string | ReactNode;
  icon?: ReactNode;
  color?: string;
}

export const GameButton = ({
  color = COLORS.primary2,
  label,
  icon,
  onPress,
  ...props
}: IGameButtonProps) => {
  const onRealPress = useCallback(() => {
    if (!onPress) return;

    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
    onPress();
  }, [onPress]);

  return (
    <Button
      flex={1}
      label={label}
      onPress={onRealPress}
      textColor={color}
      px={10}
      py={20}
      icon={icon}
      bg={`${color}35`}
      borderWidth={0}
      {...props}
    />
  );
};
