import React, { ReactNode, useEffect } from "react";
import {
  useSharedValue,
  useAnimatedStyle,
  withRepeat,
  withTiming,
  Easing,
} from "react-native-reanimated";

import { Box } from "../generic/Box";
import { ViewStyle } from "react-native";

interface IShakeAnimationProps {
  children: ReactNode;
  amount?: number;
  style?: ViewStyle;
}

export const ShakeAnimation = ({
  children,
  amount = 20,
  style,
}: IShakeAnimationProps) => {
  // Shared value to control the animation progress
  const rotateX = useSharedValue(`-${amount}deg`);

  useEffect(() => {
    rotateX.value = withRepeat(
      withTiming(`${amount}deg`, {
        duration: 800,
        easing: Easing.linear,
      }),
      -1,
      true
    );
  }, []);

  // Animated style that applies the shake animation based on the progress value
  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ rotateZ: rotateX.value }],
      ...style,
    };
  }, []);

  return (
    <Box style={animatedStyle} isAnimated={true}>
      {children}
    </Box>
  );
};
