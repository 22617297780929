import React, { memo, useMemo, useState } from "react";
import {
  GestureResponderEvent,
  LayoutChangeEvent,
  Pressable as RnPressable,
} from "react-native";
import Animated, {
  useAnimatedStyle,
  withTiming,
} from "react-native-reanimated";
import { getStyleProps } from "../../util/style";
import { IBoxProps, Box } from "./Box";

export interface IPressableProps extends IBoxProps {
  onPress?: (event: GestureResponderEvent) => void;
  onPressIn?: (event: GestureResponderEvent) => void;
  onPressOut?: (event: GestureResponderEvent) => void;
  flex?: number;
  hitSlop?:
    | number
    | { top?: number; left?: number; bottom?: number; right?: number };
  showHitslop?: boolean;
  isDisabled?: boolean;
  onLongPress?: () => void;
  enableAnimation?: boolean;
  pressedOpacity?: number;
  onLayout?: (event: LayoutChangeEvent) => void;
  translationHeight?: number;
  translationScale?: number;
}

const AnimatedPressable = Animated.createAnimatedComponent(RnPressable);

export const Pressable = memo(
  ({
    onPress,
    showHitslop,
    onPressIn,
    isDisabled,
    onLongPress,
    onLayout,
    enableAnimation,
    pressedOpacity = 0.7,
    onPressOut,
    minHeight,
    entering,
    exiting,
    translationHeight = 2,
    translationScale = 0.98,
    hitSlop,
    ...props
  }: IPressableProps) => {
    const [isPressedIn, setIsPressedIn] = useState<boolean>(false);

    const style = useMemo(() => {
      return getStyleProps(props);
    }, [props]);

    // @ts-ignore
    const animatedStyle = useAnimatedStyle(() => {
      return {
        opacity: withTiming(isPressedIn ? pressedOpacity : 1, {
          duration: 100,
        }),
        transform: [
          {
            scale: withTiming(isPressedIn ? translationScale : 1, {
              duration: 100,
            }),
          },
          {
            translateY: withTiming(isPressedIn ? translationHeight : 0, {
              duration: 100,
            }),
          },
        ],
      };
    });

    return (
      <>
        <AnimatedPressable
          onPress={onPress}
          hitSlop={hitSlop}
          onLongPress={onLongPress}
          disabled={isDisabled}
          style={[style, enableAnimation ? animatedStyle : {}]}
          onPressIn={(event) => {
            if (onPressIn) onPressIn(event);
            setIsPressedIn(true);
          }}
          onPressOut={(event) => {
            if (onPressOut) onPressOut(event);
            setIsPressedIn(false);
          }}
          onLayout={onLayout}
          {...props}
        >
          {props.children}
        </AnimatedPressable>
      </>
    );
  }
);
