import { useEffect } from "react";

export const BannerAdvert = () => {
  useEffect(() => {
    try {
      // @ts-expect-error - google ads
      (adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-6348351120125646"
        data-ad-slot="1670999571"
        data-ad-format="horizontal"
        data-full-width-responsive="true"
        data-adtest="on"
      ></ins>
    </>
  );
};
