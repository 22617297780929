import { useMutation, useQueryClient } from "react-query";

import { useSettings } from "./useSettings";
import { writeQuery } from "../util/persistentStore";

export function useSettingUpdate(key: string) {
  const { data: settings } = useSettings();

  const queryClient = useQueryClient();

  return useMutation(async (value?: any) => {
    const newSettings: { [x: string]: any } = { ...settings, [key]: value };
    if (value === undefined && newSettings.hasOwnProperty(key)) {
      delete newSettings[key];
    }

    await writeQuery("settings", newSettings);
    // await queryClient.invalidateQueries("settings");
    await queryClient.setQueryData("settings", newSettings);
  });
}
