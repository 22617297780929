import AsyncStorage from "@react-native-async-storage/async-storage";
import { useQuery } from "react-query";
import { IGameHistory } from "./useSetGameHistory";

export const useGameHistory = () =>
  useQuery<IGameHistory>("game-history", async () => {
    try {
      const jsonValue = await AsyncStorage.getItem("game-history");
      return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (e) {
      console.error("Error fetching game history", e);
      return null;
    }
  });
