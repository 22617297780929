import { useEffect } from "react";
import { isWeb } from "../util/platform";

export const useKeyboardEvents = (callback: (e: KeyboardEvent) => void) => {
  if (!isWeb) return null;

  useEffect(() => {
    document.addEventListener("keydown", callback);

    return () => {
      document.removeEventListener("keydown", callback);
    };
  }, [callback]);
};
