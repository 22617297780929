import React, { ReactNode, memo, useCallback, useRef } from "react";

import {
  NavigationContainer,
  useNavigationContainerRef,
} from "@react-navigation/native";

import { StatusBar } from "expo-status-bar";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { QueryClient, QueryClientProvider } from "react-query";
import { COLORS } from "./constants/colors";
import { trackEvent } from "./util/analytics";

const NAVIGATION_THEME = {
  dark: true,
  colors: {
    primary: COLORS.primary,
    background: COLORS.background,
    card: "rgb(18, 18, 18)",
    text: "rgb(229, 229, 231)",
    border: "rgb(39, 39, 41)",
    notification: "rgb(255, 69, 58)",
  },
};

interface IProvidersProps {
  children: ReactNode;
}

const queryClient = new QueryClient();

export const Providers = memo(({ children }: IProvidersProps) => {
  const routeNameRef = useRef<string>();
  const navigationRef = useNavigationContainerRef();

  const onNavigationReady = useCallback(() => {
    routeNameRef.current = navigationRef?.getCurrentRoute()?.name;
  }, []);

  const onNavigationStateChange = () => {
    const previousRouteName = routeNameRef.current;
    const currentRouteName = navigationRef?.getCurrentRoute()?.name;

    if (previousRouteName !== currentRouteName) {
      routeNameRef.current = currentRouteName;

      trackEvent({
        eventName: "screen_view",
        params: {
          screen_name: currentRouteName,
        },
      });
    }
  };

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <QueryClientProvider client={queryClient}>
        <NavigationContainer
          theme={NAVIGATION_THEME}
          onReady={onNavigationReady}
          ref={navigationRef}
          onStateChange={onNavigationStateChange}
        >
          <StatusBar style="light" translucent={true} />
          {children}
        </NavigationContainer>
      </QueryClientProvider>
    </GestureHandlerRootView>
  );
});
