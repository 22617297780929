import { useQuery, UseQueryResult } from "react-query";
import { readQuery } from "../util/persistentStore";

export interface ISettings {
  appOpens: number;
  enableNotifications?: boolean;
  hasBeenAskedForNotifications?: boolean;
  hasSeenRateModal?: boolean;
  [setting: string]: any;
}

export const DEFAULT_SETTINGS = {
  enableNotifications: undefined,
  appOpens: 0,
  summaryTime: undefined,
  lastSeenActiveChallenge: "",
  hasSeenWordleHelp: false,
  hasBeenAskedForNotifications: false,
};

export function useSettings(): UseQueryResult<ISettings> {
  return useQuery<ISettings>(
    "settings",
    async (): Promise<ISettings> => {
      const settings = (await readQuery("settings")) as ISettings;
      if (!settings) {
        return DEFAULT_SETTINGS;
      }

      return settings;
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );
}
