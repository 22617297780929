import React, { ReactNode, memo, useMemo } from "react";
import { LayoutChangeEvent, Platform, View, ViewStyle } from "react-native";
import Animated, { BaseAnimationBuilder } from "react-native-reanimated";
import { getStyleProps } from "../../util/style";

export interface IBoxProps {
  p?: number | string;
  ml?: number;
  mr?: number;
  mt?: number | string;
  mb?: number | string;
  mh?: number;
  mv?: number;
  pt?: number | string;
  pb?: number;
  pl?: number;
  pr?: number;
  cursor?: "pointer" | "default";
  px?: number | string;
  center?: boolean;
  maxWidth?: number | string;
  maxHeight?: number | string;
  py?: number | string;
  bg?: string;
  br?: number;
  top?: number | string;
  gap?: number;
  flex?: number;
  left?: number | string;
  right?: number;
  bottom?: number;
  zIndex?: number;
  opacity?: number;
  style?: ViewStyle;
  minWidth?: number | string;
  elevation?: number;
  minHeight?: number;
  transform?: ViewStyle["transform"];
  h?: number | string;
  w?: number | string;
  overflow?: "hidden";
  aspectRatio?: number;
  children?: ReactNode;

  // Border
  borderWidth?: number;
  borderBottomWidth?: number;
  borderRightWidth?: number;

  borderColor?: string;
  flexWrap?: "wrap" | "nowrap";
  flexDirection?: "row" | "column";
  position?: "absolute" | "relative" | "fixed";
  alignItems?: "center" | "flex-end" | "flex-start";
  justifyContent?:
    | "center"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "flex-start";

  // Border radius
  borderTopLeftRadius?: number;
  borderTopRightRadius?: number;
  borderBottomLeftRadius?: number;
  borderBottomRightRadius?: number;

  pointerEvents?: "none" | "auto" | "box-none";
  // Animated
  entering?: BaseAnimationBuilder;
  exiting?: BaseAnimationBuilder;

  isAnimated?: boolean;

  onLayout?: (event: LayoutChangeEvent) => void;

  // Web and mobile only
  webOnly?: boolean;
  mobileOnly?: boolean;
}

const AnimatedView = Animated.createAnimatedComponent(View);
export const Box = memo(
  ({
    entering,
    onLayout,
    isAnimated,
    exiting,
    webOnly,
    mobileOnly,
    ...props
  }: IBoxProps) => {
    const style = useMemo(() => {
      return getStyleProps(props);
    }, [props]);

    const hasAnimation = isAnimated || onLayout || entering || exiting;
    const Component = hasAnimation ? AnimatedView : View;
    const content = useMemo(() => props.children, [props.children]);

    if (mobileOnly && Platform.OS === "web") return null;
    if (webOnly && Platform.OS !== "web") return null;

    return (
      <Component
        {...props}
        onLayout={onLayout}
        entering={entering}
        exiting={exiting}
        style={style}
      >
        {content}
      </Component>
    );
  }
);
