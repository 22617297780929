import { createStackNavigator } from "@react-navigation/stack";

import { memo } from "react";
import { HomeScreen } from "../screens/HomeScreen";
import { CreditsScreen } from "../screens/CreditsScreen";

const Stack = createStackNavigator();

export const AppNavigator = memo(() => {
  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName="Home"
    >
      <Stack.Screen
        name="Home"
        component={HomeScreen}
        options={{ title: "21 Words - Daily Word Puzzle Challenge" }}
      />
      <Stack.Screen
        name="Credits"
        component={CreditsScreen}
        options={{ title: "21 Words - Credits" }}
      />
    </Stack.Navigator>
  );
});
